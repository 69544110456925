import React, { Component } from 'react';
import {
  Form,
  Typography,
  message,
  Button,
  Row,
  Col,
  Input,
  Select,
  Upload,
} from 'antd';
import reqwest from 'reqwest';

import './topic.css';

const { TextArea } = Input;
const { Title } = Typography;

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}

function beforeUpload(file) {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!');
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error('Image must smaller than 2MB!');
  }
  return isJpgOrPng && isLt2M;
}

class Topic extends Component {
  state = {
    detail: {},
    topics: [],
    bannerDestop: null,
    bannerMobile: null,
    loading: false,
  };

  componentDidMount = async () => {
    document.title = 'Topic';

    this.setState({ loading: true });
    await this.fetchTopics();

    var topic = this.state.topics[0];

    await this.fetchData(topic.id);
    this.setState({ loading: false });
  };

  fetchTopics = async () => {
    var { results } = await reqwest({
      url: process.env.REACT_APP_API + 'article_category',
      method: 'get',
      type: 'json',
    });

    this.setState({ topics: results });
  };

  fetchData = async (topic_id) => {
    var { results } = await reqwest({
      url: `${process.env.REACT_APP_API}article_category/${topic_id}`,
      method: 'get',
      type: 'json',
    });

    var { image, imageMobile } = results;

    this.setState({
      detail: results,
      bannerDestop: image,
      bannerMobile: imageMobile,
    });
    this.props.form.setFieldsValue(results);
  };

  onSave = (e) => {
    e.preventDefault();
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        this.props.pageLoading(true);
        await reqwest({
          url: `${process.env.REACT_APP_API}article_category/save`,
          method: 'post',
          data: values,
          type: 'json',
        });

        await this.fetchData(values.id);

        this.props.pageLoading(false);
      }
    });
  };

  handleImageChange = (info) => {
    if (info.file.status === 'uploading') {
      this.setState({ loading: true });
      return;
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (bannerDestop) => {
        this.props.form.setFieldsValue({
          image: bannerDestop,
        });
        this.setState({
          bannerDestop,
          loading: false,
        });
      });
    }
  };

  handleImageMobileChange = (info) => {
    if (info.file.status === 'uploading') {
      this.setState({ loading: true });
      return;
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (bannerMobile) => {
        this.props.form.setFieldsValue({
          imageMobile: bannerMobile,
        });
        this.setState({
          bannerMobile,
          loading: false,
        });
      });
    }
  };

  handleFormTopicChange = async (value) => {
    this.setState({ loading: true });
    await this.fetchData(value);
    this.setState({ loading: false });
  };

  render() {
    const { detail, topics, bannerDestop, bannerMobile } = this.state;
    const { getFieldDecorator } = this.props.form;

    return (
      <Form
        onSubmit={this.onSave}
        layout={'horizontal'}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
      >
        <div id='promotion-component'>
          <Row>
            <Col span={12}>
              <Title level={1}>Promotion</Title>
            </Col>
            <Col span={12} className='text-right'>
              <Button
                type='primary'
                htmlType='submit'
                style={{ minWidth: 140, marginTop: 13 }}
              >
                Save
              </Button>
            </Col>
          </Row>
          <div className='paper-panel'>
            <Row>
              <Col>
                <Form.Item label='Topic'>
                  {getFieldDecorator('id', {
                    validateTrigger: ['onChange', 'onBlur'],
                    initialValue: 1,
                  })(
                    <Col span={20}>
                      <Select
                        style={{ width: '100%', marginRight: 8 }}
                        defaultValue={1}
                        onChange={this.handleFormTopicChange}
                      >
                        {topics.map((value) => (
                          <Select.Option key={value.id} value={value.id}>
                            {value.name_en}
                          </Select.Option>
                        ))}
                      </Select>
                    </Col>,
                  )}
                </Form.Item>
                <Form.Item label='Banner Desktop'>
                  <Col span={20}>
                    <div className='preview-image-box'>
                      {bannerDestop ? (
                        <div className='image' />
                      ) : (
                        <div className='no-image'></div>
                      )}
                    </div>
                  </Col>
                  <Col span={4}>
                    <Upload
                      className='upload-button'
                      accept='.png, .jpg'
                      name='Upload'
                      listType='picture'
                      showUploadList={false}
                      action={process.env.REACT_APP_API + '/image/test/upload'}
                      beforeUpload={beforeUpload}
                      onChange={this.handleImageChange}
                    >
                      <Button>Upload</Button>
                    </Upload>
                    {getFieldDecorator('image')(<Input type='hidden' />)}
                  </Col>
                </Form.Item>
                <Form.Item label='Banner Mobile'>
                  <Col span={20}>
                    <div className='preview-image-box'>
                      {bannerMobile ? (
                        <div className='image-mobile' />
                      ) : (
                        <div className='no-image'></div>
                      )}
                    </div>
                  </Col>
                  <Col span={4}>
                    <Upload
                      className='upload-button'
                      accept='.png, .jpg'
                      name='Upload'
                      listType='picture'
                      showUploadList={false}
                      action={process.env.REACT_APP_API + '/image/test/upload'}
                      beforeUpload={beforeUpload}
                      onChange={this.handleImageMobileChange}
                    >
                      <Button>Upload</Button>
                    </Upload>
                    {getFieldDecorator('imageMobile')(<Input type='hidden' />)}
                  </Col>
                </Form.Item>
                <Form.Item label='Title on Banner'>
                  <Col span={20}>
                    {getFieldDecorator('title', {
                      initialValue: detail.title,
                      rules: [
                        {
                          required: true,
                          message: 'Please input your title on banner!',
                        },
                        {
                            max: 1000,
                            message: 'Max length of `Title on Banner` should not over than 1000!',
                          },
                      ],
                    })(<TextArea rows={2} placeholder='' />)}
                  </Col>
                </Form.Item>
                <Form.Item label='Sub Title on Banner'>
                  <Col span={20}>
                    {getFieldDecorator('sub_title', {
                      initialValue: detail.sub_title,
                      rules: [
                        {
                          max: 1000,
                          message: 'Max length of `Sub Title on Banner` should not over than 1000!',
                        },
                      ],
                    })(<TextArea rows={2} placeholder='' />)}
                  </Col>
                </Form.Item>
                <Form.Item label='Destop Top Topic'>
                  <Col span={20}>
                    {getFieldDecorator('desktop_topic', {
                      initialValue: detail.desktop_topic,
                      rules: [
                        {
                          required: true,
                          message: 'Please input your Destop Top Topic!',
                        },
                        {
                          max: 1000,
                          message: 'Max length of `Destop Top Topic` should not over than 1000!',
                        },
                      ],
                    })(<Input placeholder='' />)}
                  </Col>
                </Form.Item>
              </Col>
            </Row>
          </div>
        </div>
        <style jsx>{`
            .image{
                background-image: url('${bannerDestop}');
            }
            .image-mobile{
                background-image: url('${bannerMobile}');
            }
        `}</style>
      </Form>
    );
  }
}

export default Form.create({ name: 'topic_form' })(Topic);
