import React from "react";
import { Form, Upload, message, Button, Icon, Input } from "antd";
import "antd/dist/antd.css";
import "./symbiotic.css";
import reqwest from "reqwest";

const IMAGE_TYPES = {
    topic_main: { title: 'topic_main', position: 0 },
    immunity: { title: 'immunity', position: 1 },
    brain: { title: 'brain', position: 2 },
    baby: { title: 'baby', position: 3 },
    symbiotic: { title: 'symbiotic', position: 4 },
    symbiotic_group: { title: 'symbiotic_group', position: 5 },
    plus_brain: { title: 'plus_brain', position: 6 },
    symbiotic_formula_topic: { title: 'symbiotic_formula_topic', position: 7 },
    symbiotic_formula: { title: 'symbiotic_formula', position: 8 },
    symbiotic_read_more: { title: 'symbiotic_read_more', position: 9 }
};

// Input fields configuration
const TEXT_FIELDS = {
    topic_one: { title: 'topic_one', position: 1 },
    topic_two: { title: 'topic_two', position: 2 },
    topic_three: { title: 'topic_three', position: 3 },
    topic_four: { title: 'topic_four', position: 4 },
    topic_five: { title: 'topic_five', position: 5 }
};

function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
}

const getBase64Callback = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });
};

class Symbiotic extends React.Component {
    state = {
        images: {},
        imageList: [],
        textFields: {},
        loading: false,
        isEditing: false,
        uploadingTypes: {}, // To track which image type is currently uploading
        fileList: {} // To track files for each image type
    };

    componentDidMount() {
		document.title = "Symbiotic"
        this.fetchImages();
        this.fetchTextFields();
    }

    fetchImages = async () => {
        try {
            const response = await reqwest({
                url: `${process.env.REACT_APP_API}/symbiotic/symbiotic_images`,
                method: 'get',
                type: 'json'
            });

            if (response.success && response.results) {
                const images = response.results.reduce((acc, item) => ({
                    ...acc,
                    [item.type]: { url: item.cover_photo, id: item.id }
                }), {});

                this.setState({ images });
            } else {
                message.error("Unable to load images: " + (response.message || "Unknown error"));
            }
        } catch (error) {
            console.error("Error fetching images:", error);
            message.error("Unable to load images");
        }
    };

    fetchTextFields = async () => {
        try {
            const response = await reqwest({
                url: `${process.env.REACT_APP_API}/symbiotic/symbiotic_text_fields`,
                method: 'get',
                type: 'json'
            });

            if (response.success && response.results) {
                const textFields = response.results.reduce((acc, item) => ({
                    ...acc,
                    [item.field_key]: { value: item.value, id: item.id }
                }), {});

                this.setState({ textFields });

                // Set form values
                const { form } = this.props;
                Object.entries(textFields).forEach(([key, { value }]) => {
                    form.setFieldsValue({ [key]: value });
                });
            } else {
                message.error("Unable to load text fields: " + (response.message || "Unknown error"));
            }
        } catch (error) {
            console.error("Error fetching text fields:", error);
            message.error("Unable to load text fields");
        }
    };

    beforeUpload = (file) => {
        const isImage = ['image/jpeg', 'image/png', 'image/webp'].includes(file.type);
        const isSizeValid = file.size / 1024 / 1024 < 2;

        if (!isImage) message.error("Only upload JPG/PNG/WebP files!");
        if (!isSizeValid) message.error("Image size must be less than 2MB!");

        return isImage && isSizeValid;
    };

    handleImageChange = (type) => info => {
        if (info.file.status === "uploading") {
            this.setState(prevState => ({
                uploadingTypes: { ...prevState.uploadingTypes, [type]: true }
            }));
            return;
        }

        if (info.file.status === "done") {

            // Get this url from response in real world.
            getBase64(info.file.originFileObj, (image) => {
                this.setState(prevState => ({
                    images: {
                        ...prevState.images,
                        [type]: {
                            url: image,
                        }
                    },
                }));
            });
            // Add file to fileList
            const fileList = [...(this.state.fileList[type] || [])];
            fileList.push(info.file.originFileObj);

            this.setState(prevState => ({
                uploadingTypes: { ...prevState.uploadingTypes, [type]: false },
                fileList: {
                    ...prevState.fileList,
                    [type]: fileList
                }
            }));
        }
    };

    saveAllChanges = async () => {
        const { form } = this.props;
        form.validateFields(async (err, values) => {
            if (err) return message.error("Please fill in all required fields");

            try {
                // รวมข้อมูลข้อความ
                const textData = Object.keys(TEXT_FIELDS).reduce((acc, key) => {
                    acc[key] = values[key];
                    return acc;
                }, {});

                // รวมข้อมูลรูปภาพ (แปลงเป็น Base64)
                const imageData = await Promise.all(
                    Object.entries(this.state.fileList).map(async ([type, files]) => {
                        const base64Images = await Promise.all(
                            files.map(async (file) => ({
                                type,
                                image: await getBase64Callback(file)
                            }))
                        );
                        return base64Images;
                    })
                );

                // รวมข้อมูลทั้งหมดเป็น JSON
                const payload = {
                    textFields: textData,
                    images: imageData.flat() // รวมอาร์เรย์ของรูปภาพให้เป็นอาร์เรย์เดียว
                };

                // ส่งข้อมูลไปเซิร์ฟเวอร์
                const response = await reqwest({
                    url: `${process.env.REACT_APP_API}/symbiotic/save`,
                    method: 'post',
                    contentType: 'application/json',
                    data: JSON.stringify(payload),
                    type: 'json'
                });

                if (response.success) {
                    message.success("Data saved successfully");
                    this.setState({ isEditing: false, fileList: {} });
                    this.fetchImages();
                    this.fetchTextFields();
                } else {
                    message.error("Failed to save data");
                }
            } catch (error) {
                console.error("Error saving data:", error);
                message.error("An error occurred while saving data");
            }
        });
    };

    renderUploader = (type, customStyle = {}) => {
        const { isEditing, images, uploadingTypes } = this.state;
        const { url } = images[type] || {};
        const isUploading = uploadingTypes[type];
        return (
            <Form.Item>
                <Upload
                    name="cover_photo"
                    accept=".png,.jpg,.jpeg,.webp"
                    listType="picture-card"
                    className="symbiotic-photo-uploader"
                    showUploadList={false}
                    action={process.env.REACT_APP_API + "/image/test/upload"}
                    beforeUpload={this.beforeUpload}
                    onChange={this.handleImageChange(type)}
                    disabled={!isEditing}
                >
                    {url ? (
                        <img src={url} alt={IMAGE_TYPES[type]?.title || type} style={{ width: "100%", ...customStyle }} />
                    ) : (
                        <div>
                            <Icon type={isUploading ? "loading" : "plus"} />
                            <div className="ant-upload-text">upload</div>
                        </div>
                    )}
                </Upload>
            </Form.Item>
        );
    };

    renderInput = (fieldKey) => {
        const { isEditing } = this.state;
        const { getFieldDecorator } = this.props.form;
        const { title } = TEXT_FIELDS[fieldKey] || { title: fieldKey };

        return (
            <Form.Item label={title}>
                {getFieldDecorator(fieldKey, {
                    rules: [
                        { required: true, message: `Please fill in ${title}!` },
                    ],
                })(
                    <Input
                        placeholder={title}
                        maxLength={1000}
                        disabled={!isEditing}
                    />
                )}
            </Form.Item>
        );
    };

    render() {
        const { isEditing } = this.state;

        return (
            <div className="synbiotic">
                <div className="edit-controls">
                    <Button type="primary" onClick={() => this.setState({ isEditing: !isEditing })}>
                        {isEditing ? "Cancel Edit" : "Edit"}
                    </Button>
                    {isEditing && (
                        <Button type="primary" onClick={this.saveAllChanges} style={{ marginLeft: 10 }}>
                            Save
                        </Button>
                    )}
                </div>

                <Form layout="vertical">
                    {/* Main content */}
                    <div className="d-flex justify-center pt-2">
                        {this.renderUploader('topic_main')}
                    </div>

                    <div className="d-flex justify-center mt-5">
                        <div>
                            {this.renderUploader('immunity')}
                        </div>
                        <div>
                            {this.renderUploader('brain')}
                        </div>
                    </div>

                    <div className="d-flex justify-center mt-1">
                        {this.renderUploader('baby')}
                    </div>

                    <div className="d-flex justify-center mt-1">
                        {this.renderUploader('symbiotic')}
                    </div>

                    <div className="d-flex justify-center mt-1">
                        <div>
                            {this.renderUploader('symbiotic_group')}
                        </div>
                    </div>

                    <div className="d-flex justify-center mt-1">
                        <div>
                            {this.renderUploader('plus_brain')}
                        </div>
                    </div>

                    {/* <div className="d-flex justify-center mt-1">
                        <div>
                            {this.renderUploader('symbiotic_formula_topic')}
                        </div>
                    </div> */}
                    
                    {/* <div className="d-flex justify-center mt-1">
                        <div>
                            {this.renderUploader('symbiotic_read_more')}
                        </div>
                    </div> */}

                </Form>
            </div>
        );
    }
}

export default Form.create({ name: "symbiotic" })(Symbiotic);