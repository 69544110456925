import React, { Component } from "react";
import { Route, Link, withRouter, Redirect } from "react-router-dom";
import { Layout, Menu } from "antd";
import "./App.css";
import Login from "./login";
import CarelineMessage from "./careline_message";
import Thumbnails from "./thumbnails";
import HomeBanner from "./home_banner";
// import NavbarMenu from './navbar_menu';
// import TVCSection  from './tvc_section';
// import Infographic  from './infographic';
import Article from "./article";
import before_c_section from "./c_section_before.js";
import after_c_section from "./c_section_after.js";
import c_section_banner from "./c_section_banner.js";
import Product from "./product";
import ProductGetSample from "./product_get_sample";
import Events from "./event";
import EventSchedule from "./event_schedule";
import SystemUser from "./system_user";
import Promotion from "./promotion";
import PromotionBanner from "./promotion_banner";
import ProductBanner from "./product_banner";
import GetSampleBanner from "./get_sample_banner";
import Sampling from "./sampling";
import Register from "./register";
import Meta from "./meta";
import Topic from "./topic";
import SubTopic from "./sub_topic";
import c_section_landing_page from "./c_section_landing_page.js";
import c_section_article_notice from "./c_section_article_notice.js";
import home_article from "./home_article.js";
import home_related from "./home_related.js"
import symbiotic from "./symbiotic.js";
import cookie from "js-cookie";
import authen from "./authen.js";
import { ClipLoader } from "react-spinners";
import reqwest from "reqwest";

const { Header, Content, Footer, Sider } = Layout;

const App = withRouter((props) => <RouterApp {...props} />);

const PrivateRoute = ({
  component: Component,
  pageLoading: PageLoading,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) =>
      cookie.get("token") ? (
        <Component {...props} pageLoading={PageLoading} />
      ) : (
        <Redirect to="/" />
      )
    }
  />
);

class RouterApp extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    var token = cookie.get("token");

    if (token) {
      this.pageLoading(true);
      reqwest({
        url: process.env.REACT_APP_API + "/auth/",
        method: "get",
        data: {
          token: cookie.get("token"),
        },
        type: "json",
      }).then((data) => {
        authen.authenticate(() => {
          this.setState({
            menu: "careline_message",
            username: data.message,
            redirectToLogin: false,
            pageLoading: false,
          });

          if (this.props.location.pathname === "/") {
            this.props.history.push("/careline_message");
          }
        });
        this.pageLoading(false);
      });
    }
  }

  state = {
    menu: this.props.location.pathname.replace("/", ""),
    redirectToLogin: false,
    pageLoading: true,
    username: "",
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidMount() {
    this._isMounted = true;

    var defaultMenu = this.props.location.pathname.replace("/", "");
    defaultMenu =
      defaultMenu === "" || defaultMenu === "/"
        ? "careline_message"
        : defaultMenu;

    this.setState({ menu: defaultMenu });
  }

  pageLoading = (loading = false) => {
    if (this._isMounted) {
      this.setState({ pageLoading: loading });
    }
  };

  setUsername = (username = "") => {
    if (this._isMounted) {
      this.setState({ username: username });
    }
  };

  handleLogout() {
    cookie.set("token", "");
    authen.signout(() => {
      this.setState(() => ({
        menu: "careline_message",
        redirectToLogin: true,
        username: "",
      }));

      if (this.props.location.pathname !== "/") {
        this.props.history.push("/");
      }

      this.setState({
        redirectToLogin: false,
      });
    });
  }

  render() {
    const { menu, pageLoading, username } = this.state;

    //     ;

    if (this.state.redirectToLogin) {
      return (
        <Login pageLoading={this.pageLoading} setUsername={this.setUsername} />
      );
    }

    return authen.isAuthenticated === false ? (
      <Route
        path="/"
        render={(props) => (
          <Login
            {...props}
            pageLoading={this.pageLoading}
            setUsername={this.setUsername}
          />
        )}
      />
    ) : (
      <Layout style={{ minHeight: "100vh" }}>
        <div
          className={"loading-panel " + (pageLoading ? " show" : "")}
          style={{
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            position: "fixed",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 1005,
          }}
        >
          <ClipLoader
            css={{
              position: "fixed",
              zIndex: "9999",
              top: "50%",
              transform: "translateY(-50%)",
              left: 0,
              right: 0,
              margin: "auto",
            }}
            size={50}
            color={"#9B0000"}
            loading={pageLoading}
          />
        </div>
        <Header
          className="header"
          style={{ position: "fixed", zIndex: 1, width: "100%" }}
        >
          <div className="logo">
            <img src="../assets/images/logo.png" alt="" />
          </div>
          <script
            dangerouslySetInnerHTML={{ __html: "<!-- Have update v-1.1 ! -->" }}
          />
          <div className="text-right">
            <div id="top-menus">
              <div className="account-name item">{username}</div>
              <span
                className="item"
                style={{ cursor: "pointer" }}
                onClick={() => this.handleLogout()}
              >
                <i
                  className="fa fa-sign-out"
                  style={{
                    fontSize: 18,
                    verticalAlign: "middle",
                    lineHeight: 1,
                  }}
                ></i>{" "}
                Sign Out
              </span>
            </div>
          </div>
        </Header>

        <Layout style={{ marginTop: 64 }}>
          <Sider
            width={210}
            className="side-menu"
            style={{
              overflow: "auto",
              position: "fixed",
              left: 0,
              top: 64,
              bottom: 0,
              zIndex: 10,
            }}
          >
            <Menu id="side-menus" defaultSelectedKeys={[menu]} mode="inline">
              <Menu.Item key="careline_message">
                <div className="menu-item-icon">
                  <img src="./assets/images/mail.png" alt="" />
                </div>
                <span>Careline Message</span>
                <Link to="/careline_message" />
              </Menu.Item>
              {/* <Menu.Item key="testimonials">
								<div className="menu-item-icon">
									<img src="./assets/images/testimonial.png" alt="" />
								</div>
								<span>Testimonials</span>
								<Link to="/testimonials" />
							</Menu.Item> */}
              {/* <Menu.Item key="topic">
								<div className="menu-item-icon">
									<img src="./assets/images/article.png" alt="" />
								</div>
								<span className="menu-item-title">Topic</span>
								<Link to="/topic" />
							</Menu.Item> */}
              {/* <Menu.Item key="sub-topic">
								<div className="menu-item-icon">
									<img src="./assets/images/article.png" alt="" />
								</div>
								<span className="menu-item-title">Sub Topic</span>
								<Link to="/sub-topic" />
							</Menu.Item> */}
              <Menu.Item key="article">
                <div className="menu-item-icon">
                  <img src="./assets/images/article.png" alt="" />
                </div>
                <span className="menu-item-title">Article</span>
                <Link to="/article" />
              </Menu.Item>
              <Menu.Divider
                style={{
                  backgroundColor: "#DBDBDB",
                  marginTop: 10,
                  marginBottom: 10,
                }}
              ></Menu.Divider>
              <Menu.Item key="c-sec-banner">
                <div className="menu-item-icon">
                  <img src="./assets/images/home-banner.png" alt="" />
                </div>
                <span className="menu-item-title">C-section Banner</span>
                <Link to="/c-section-banner" />
              </Menu.Item>
              <Menu.Item key="read_more">
                <div className="menu-item-icon">
                  <img src="./assets/images/article.png" alt="" />
                </div>
                <span className="menu-item-title">C-section page</span>
                <Link to="/c-section-page" />
              </Menu.Item>
              <Menu.Item key="before-c-section">
                <div className="menu-item-icon">
                  <img src="./assets/images/article.png" alt="" />
                </div>
                <span className="menu-item-title">C-section Before</span>
                <Link to="/before-c-section" />
              </Menu.Item>
              <Menu.Item key="after-c-section">
                <div className="menu-item-icon">
                  <img src="./assets/images/article.png" alt="" />
                </div>
                <span className="menu-item-title">C-section After</span>
                <Link to="/after-c-section" />
              </Menu.Item>

              <Menu.Divider
                style={{
                  backgroundColor: "#DBDBDB",
                  marginTop: 10,
                  marginBottom: 10,
                }}
              ></Menu.Divider>
              <Menu.Item key="notice">
                <div className="menu-item-icon">
                  <img src="./assets/images/home-banner.png" alt="" />
                </div>
                <span className="menu-item-title">C-section Notice</span>
                <Link to="/c-section-notice" />
              </Menu.Item>
              <Menu.Divider
                style={{
                  backgroundColor: "#DBDBDB",
                  marginTop: 10,
                  marginBottom: 10,
                }}
              ></Menu.Divider>

              <Menu.Item key="product">
                <div className="menu-item-icon">
                  <img src="./assets/images/product.png" alt="" />
                </div>
                <span className="menu-item-title">Product</span>
                <Link to="/product" />
              </Menu.Item>
              <Menu.Item key="product_get_sample">
                <div className="menu-item-icon">
                  <img src="./assets/images/product.png" alt="" />
                </div>
                <span
                  className="menu-item-title"
                  style={{ fontSize: "0.8rem" }}
                >
                  ProductGetSample
                </span>
                <Link to="/product_get_sample" />
              </Menu.Item>
              <Menu.Item key="meta">
                <div className="menu-item-icon">
                  <img src="./assets/images/seo.png" alt="" />
                </div>
                <span className="menu-item-title">SEO Meta Data</span>
                <Link to="/meta" />
              </Menu.Item>

              <Menu.Divider
                style={{
                  backgroundColor: "#DBDBDB",
                  marginTop: 10,
                  marginBottom: 10,
                }}
              ></Menu.Divider>

              <Menu.Item key="events">
                <div className="menu-item-icon">
                  <img src="./assets/images/event.png" alt="" />
                </div>
                <span className="menu-item-title">Event</span>
                <Link to="/events" />
              </Menu.Item>
              <Menu.Item key="event_schedule">
                <div className="menu-item-icon">
                  <img src="./assets/images/event-schedule.png" alt="" />
                </div>
                <span className="menu-item-title">Event Schedule</span>
                <Link to="/event_schedule" />
              </Menu.Item>
              <Menu.Item key="promotion">
                <div className="menu-item-icon">
                  <img src="./assets/images/promotion.png" alt="" />
                </div>
                <span className="menu-item-title">Promotion</span>
                <Link to="/promotion" />
              </Menu.Item>

              <Menu.Divider
                style={{
                  backgroundColor: "#DBDBDB",
                  marginTop: 10,
                  marginBottom: 10,
                }}
              ></Menu.Divider>

              <Menu.Item key="sampling">
                <div className="menu-item-icon">
                  <img src="./assets/images/sampling.png" alt="" />
                </div>
                <span className="menu-item-title">Request Sampling</span>
                <Link to="/sampling" />
              </Menu.Item>
              <Menu.Item key="register">
                <div className="menu-item-icon">
                  <img src="./assets/images/register.png" alt="" />
                </div>
                <span className="menu-item-title">Club Register</span>
                <Link to="/register" />
              </Menu.Item>

              <Menu.Divider
                style={{
                  backgroundColor: "#DBDBDB",
                  marginTop: 10,
                  marginBottom: 10,
                }}
              ></Menu.Divider>
              {/* <Menu.Item key="navbar_menu">
								<div className="menu-item-icon">
									<img src="./assets/images/home-banner.png" alt="" />
								</div>
								<span className="menu-item-title">Navbar Menu</span>
								<Link to="/navbar_menu" />
							</Menu.Item> */}
              <Menu.Item key="home_banner">
                <div className="menu-item-icon">
                  <img src="./assets/images/home-banner.png" alt="" />
                </div>
                <span className="menu-item-title">Home Banner</span>
                <Link to="/home_banner" />
              </Menu.Item>
              {/* <Menu.Item key="tvc_section">
								<div className="menu-item-icon">
									<img src="./assets/images/home-banner.png" alt="" />
								</div>
								<span className="menu-item-title">TVC Section</span>
								<Link to="/tvc_section" />
							</Menu.Item>
							<Menu.Item key="infographic">
								<div className="menu-item-icon">
									<img src="./assets/images/home-banner.png" alt="" />
								</div>
								<span className="menu-item-title">Infographic</span>
								<Link to="/infographic" />
							</Menu.Item> */}
              <Menu.Item key="home_thumbnails">
                <div className="menu-item-icon">
                  <img src="./assets/images/home-banner.png" alt="" />
                </div>
                <span className="menu-item-title">Home Thumbnails</span>
                <Link to="/thumbnails" />
              </Menu.Item>

              <Menu.Item key="home_article">
                <div className="menu-item-icon">
                  <img src="./assets/images/home-banner.png" alt="" />
                </div>
                <span className="menu-item-title">Home Article</span>
                <Link to="/home-article" />
              </Menu.Item>

              <Menu.Item key="home_related">
                <div className="menu-item-icon">
                  <img src="./assets/images/home-banner.png" alt="" />
                </div>
                <span className="menu-item-title">Home Related</span>
                <Link to="/home-related" />
              </Menu.Item>

              <Menu.Item key="promotion_banner">
                <div className="menu-item-icon">
                  <img src="./assets/images/promotion-banner.png" alt="" />
                </div>
                <span className="menu-item-title">Promotion Banner</span>
                <Link to="/promotion_banner" />
              </Menu.Item>

              <Menu.Item key="product_banner">
                <div className="menu-item-icon">
                  <img src="./assets/images/product_banner.png" alt="" />
                </div>
                <span className="menu-item-title">Product Banner</span>
                <Link to="/product_banner" />
              </Menu.Item>

              <Menu.Item key="get_sample_banner">
                <div className="menu-item-icon">
                  <img src="./assets/images/product_banner.png" alt="" />
                </div>
                <span className="menu-item-title">GetSample Banner</span>
                <Link to="/get_sample_banner" />
              </Menu.Item>
              <Menu.Divider
                style={{
                  backgroundColor: "#DBDBDB",
                  marginTop: 10,
                  marginBottom: 10,
                }}
              ></Menu.Divider>

              <Menu.Item key="notice">
                <div className="menu-item-icon">
                  <img src="./assets/images/home-banner.png" alt="" />
                </div>
                <span className="menu-item-title">Symbiotic</span>
                <Link to="/symbiotic" />
              </Menu.Item>

              <Menu.Divider
                style={{
                  backgroundColor: "#DBDBDB",
                  marginTop: 10,
                  marginBottom: 10,
                }}
              ></Menu.Divider>

              <Menu.Item key="system_user">
                <div className="menu-item-icon">
                  <img src="./assets/images/system-user.png" alt="" />
                </div>
                <span className="menu-item-title">System User</span>
                <Link to="/system_user" />
              </Menu.Item>
            </Menu>

            <div id="app-version">Application Version 1.0</div>
          </Sider>

          <Layout style={{ marginLeft: 210, minWidth: 1177 }}>
            <Content
              style={{
                paddingTop: 30,
                paddingLeft: 58,
                paddingRight: 58,
                paddingBottom: 30,
              }}
            >
              <PrivateRoute
                path="/careline_message"
                component={CarelineMessage}
                pageLoading={this.pageLoading}
              />
              {/* <PrivateRoute path="/testimonials" component={Testimonials} pageLoading={this.pageLoading} /> */}
              <PrivateRoute
                path="/thumbnails"
                component={Thumbnails}
                pageLoading={this.pageLoading}
              />
              <PrivateRoute
                path="/home_banner"
                component={HomeBanner}
                pageLoading={this.pageLoading}
              />
              {/* <PrivateRoute path="/navbar_menu" component={NavbarMenu} pageLoading={this.pageLoading} /> */}
              {/* <PrivateRoute path="/tvc_section" component={TVCSection} pageLoading={this.pageLoading} /> */}
              {/* <PrivateRoute path="/infographic" component={Infographic} pageLoading={this.pageLoading} /> */}
              <PrivateRoute
                path="/article"
                component={Article}
                pageLoading={this.pageLoading}
              />
              <PrivateRoute
                path="/before-c-section"
                component={before_c_section}
                pageLoading={this.pageLoading}
              />
              <PrivateRoute
                path="/after-c-section"
                component={after_c_section}
                pageLoading={this.pageLoading}
              />
              <PrivateRoute
                path="/product"
                component={Product}
                pageLoading={this.pageLoading}
              />
              <PrivateRoute
                path="/product_get_sample"
                component={ProductGetSample}
                pageLoading={this.pageLoading}
              />
              <PrivateRoute
                path="/events"
                component={Events}
                pageLoading={this.pageLoading}
              />
              <PrivateRoute
                path="/event_schedule"
                component={EventSchedule}
                pageLoading={this.pageLoading}
              />
              <PrivateRoute
                path="/promotion"
                component={Promotion}
                pageLoading={this.pageLoading}
              />
              <PrivateRoute
                path="/promotion_banner"
                component={PromotionBanner}
                pageLoading={this.pageLoading}
              />
              <PrivateRoute
                path="/product_banner"
                component={ProductBanner}
                pageLoading={this.pageLoading}
              />
              <PrivateRoute
                path="/get_sample_banner"
                component={GetSampleBanner}
                pageLoading={this.pageLoading}
              />
              <PrivateRoute
                path="/system_user"
                component={SystemUser}
                pageLoading={this.pageLoading}
              />
              <PrivateRoute
                path="/register"
                component={Register}
                pageLoading={this.pageLoading}
              />
              <PrivateRoute
                path="/sampling"
                component={Sampling}
                pageLoading={this.pageLoading}
              />
              <PrivateRoute
                path="/meta"
                component={Meta}
                pageLoading={this.pageLoading}
              />
              <PrivateRoute
                path="/topic"
                component={Topic}
                pageLoading={this.pageLoading}
              />
              <PrivateRoute
                path="/sub-topic"
                component={SubTopic}
                pageLoading={this.pageLoading}
              />
              <PrivateRoute
                path="/c-section-page"
                component={c_section_landing_page}
                pageLoading={this.pageLoading}
              />
              <PrivateRoute
                path="/c-section-banner"
                component={c_section_banner}
                pageLoading={this.pageLoading}
              />
              <PrivateRoute
                path="/c-section-notice"
                component={c_section_article_notice}
                pageLoading={this.pageLoading}
              />
              <PrivateRoute
                path="/home-article"
                component={home_article}
                pageLoading={this.pageLoading}
              />
              <PrivateRoute
                path="/home-related"
                component={home_related}
                pageLoading={this.pageLoading}
              />
              <PrivateRoute
                path="/symbiotic"
                component={symbiotic}
                pageLoading={this.pageLoading}
              />
            </Content>

            <Footer style={{ display: "none" }}></Footer>
          </Layout>
        </Layout>
      </Layout>
    );
  }
}

export default App;
