import React from "react";
import {
  Modal,
  Drawer,
  Form,
  Upload,
  message,
  Radio,
  Icon,
  Typography,
  Table,
  Button,
  Row,
  Col,
  Input,
} from "antd";
import "antd/dist/antd.css";
import "./product_banner.css";
import reqwest from "reqwest";

import dragula from "dragula";
import "dragula/dist/dragula.css";
import moment from "moment";

const path = require("path");
const image2base64 = require("image-to-base64");
const { Search, TextArea } = Input;
const { Text, Title } = Typography;

var searchTimeoutId = false;

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
}

function beforeUpload(file) {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    message.error("You can only upload JPG/PNG file!");
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error("Image must smaller than 2MB!");
  }
  return isJpgOrPng && isLt2M;
}

class App extends React.Component {
  state = {
    data: [],
    key: false,
    loading: false,
    visible: false,
    imageUrl: false,
    imageMobileUrl: false,
    imageBase64: "",
    deleteModalVisible: false,
    deleteLoading: false,
    deleteButton: false,
    searchValue: "",
    detail: { title: ["", ""] },
  };

  constructor(props) {
    super(props);
    this.columns = [
      {
        title: "Sort",
        dataIndex: "sort",
        key: "display_index",
        width: 100,
        render: () => (
          <Icon
            type="menu"
            style={{
              fontSize: "13pt",
              fontWeight: "bolder",
              cursor: "pointer",
            }}
          />
        ),
      },
      {
        title: "",
        dataIndex: "image",
        key: "image",
        width: 300,
        render: (image) => (
          <img src={image} style={{ maxHeight: 150, maxWidth: 200 }} alt="" />
        ),
      },
      // {
      // 	title: 'Link to',
      // 	dataIndex: 'url',
      // 	key: 'url',
      // 	render: title => (
      // 		<div>
      // 			{title}
      // 		</div>
      // 	)
      // },
      // {
      // 	title: 'Duration',
      // 	dataIndex: 'duration',
      // 	key: 'duration',
      // 	render: title => (
      // 		<div>
      // 			{title}
      // 		</div>
      // 	)
      // },
      {
        title: "Title",
        dataIndex: "title",
        key: "title",
        render: (title) => (
          <div>
            <strong>{title[0]}</strong>
            <div>{title[1]}</div>
          </div>
        ),
      },
      {
        title: "Active",
        dataIndex: "status",
        key: "status",
        width: 100,
        align: "center",
        render: (status) =>
          status === "1" ? (
            <i className="fa fa-check" style={{ fontSize: 15 }}></i>
          ) : (
            <i className="fa fa-times" style={{ fontSize: 15 }}></i>
          ),
      },
      {
        title: "",
        dataIndex: "key",
        key: "key",
        width: 50,
        render: (key) => (
          <Button
            onClick={() => {
              this.showDrawer(key);
            }}
            className="icon-btn"
          >
            <Icon type="setting" />
          </Button>
        ),
      },
    ];
  }

  ChangeType = (el) => {
    this.setState({
      detail: {
        type: el.target.value,
      },
    });
  };

  componentDidMount() {
    document.title = "Product Banner";

    this.fetch();

    const container = document.querySelector(".ant-table-tbody");
    const drake = dragula([container], {
      moves: (el) => {
        this.start = this.getIndexInParent(el);
        return true;
      },
    });

    drake.on("drop", (el) => {
      this.end = this.getIndexInParent(el);
      this.handleReorder(this.start, this.end);
    });
  }

  getIndexInParent = (el) => {
    return Array.from(el.parentNode.children).indexOf(el);
  };

  showDrawer = async (key) => {
    var data = {};
    if (key === -1) {
      data = {
        title_kh: "",
        description_kh: "",
        url: "",
        type: "1",
        start_time: moment().format("YYYY/MM/DD"),
        end_time: moment().add("days", 7).format("YYYY/MM/DD"),
        duration: "",
        phone: "",
        status: "1",
        image: false,
      };
      this.setState({
        imageUrl: null,
        imageMobileUrl: null,
      });
    } else {
      for (var i = 0; i < this.state.data.length; i++) {
        if (this.state.data[i].key === key) {
          data = this.state.data[i];
          break;
        }
      }
    }

    var duration = [
      moment(data.start_time, "YYYY/MM/DD"),
      moment(data.end_time, "YYYY/MM/DD"),
    ];
    this.props.form.setFieldsValue({
      id: data.key,
      url: data.url,
      title_kh: data.title_kh,
      description_kh: data.description_kh,
      start_time: data.start_time,
      end_time: data.end_time,
      duration: duration,
      type: "" + data.type,
      phone: "" + data.phone,
      display_type: "" + data.display_type,
      status: data.status,
      image: false,
      imageMobile: false,
    });

    this.setState({
      visible: true,
      detail: data,
      deleteButton: key === -1 ? false : true,
    });

    image2base64(data.image) // you can also to use url
      .then((response) => {
        var b64 = "data:image/";
        var ext = path.extname(data.image);
        if (ext === ".jpg" || ext === ".jpeg") {
          b64 += "jpeg;base64," + response;
        } else if (ext === ".png") {
          b64 += "png;base64," + response;
        } else {
          b64 = false;
        }
        if (b64 !== "data:image/") {
          this.props.form.setFieldsValue({
            image: b64,
          });

          this.setState({
            imageUrl: b64,
          });
        }
      })
      .catch((error) => {
        //Exepection error....
      });

    image2base64(data.imageMobile) // you can also to use url
      .then((response) => {
        var b64 = "data:image/";
        var ext = path.extname(data.imageMobile);
        if (ext === ".jpg" || ext === ".jpeg") {
          b64 += "jpeg;base64," + response;
        } else if (ext === ".png") {
          b64 += "png;base64," + response;
        } else {
          b64 = false;
        }
        if (b64 !== "data:image/") {
          this.props.form.setFieldsValue({
            imageMobile: b64,
          });

          this.setState({
            imageMobileUrl: b64,
          });
        }
      })
      .catch((error) => {
        //Exepection error....
      });

    this.setState({
      imageUrl: data.image,
      imageMobileUrl: data.imageMobile,
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };

  onSave = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        if (!values.image || !values.imageMobile) {
          message.error("please add Image");
        } else {
          const withHttp = (url) =>
            !/^https?:\/\//i.test(url) ? `https://${url}` : url;

          if (values.url) {
            values.url = values.url ? withHttp(values.url) : "";
          }
          if (values.duration) {
            if (values.duration.length > 0) {
              values.start_time = moment(values.duration[0]).format(
                "YYYY/MM/DD"
              );
            }
            if (values.duration.length > 1) {
              values.end_time = moment(values.duration[1]).format("YYYY/MM/DD");
            }
          }
          reqwest({
            url: process.env.REACT_APP_API + "/product_banner/save",
            method: "post",
            data: values,
            type: "json",
          }).then(() => {
            this.setState({ visible: false });
            this.fetch();
			message.success("Success.");
          });
        }
      }
    });
  };

  handleChange = (info) => {
    if (info.file.status === "uploading") {
      this.setState({ loading: true });
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (imageUrl) => {
        this.props.form.setFieldsValue({
          image: imageUrl,
        });
        this.setState({
          imageUrl,
          loading: false,
        });
      });
    }
  };

  handleMobileChange = (info) => {
    if (info.file.status === "uploading") {
      this.setState({ loading: true });
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (imageMobileUrl) => {
        this.props.form.setFieldsValue({
          imageMobile: imageMobileUrl,
        });
        this.setState({
          imageMobileUrl,
          loading: false,
        });
      });
    }
  };

  handleSearchChange(value) {
    this.setState({ searchValue: value });
    if (searchTimeoutId) {
      window.clearTimeout(searchTimeoutId);
    }
    searchTimeoutId = window.setTimeout(() => {
      this.fetch();
    }, 1500);
  }

  fetch = (params = {}) => {
    this.setState({ loading: true });
    reqwest({
      url: process.env.REACT_APP_API + "/product_banner",
      method: "get",
      data: {
        search: this.state.searchValue,
        ...params,
      },
      type: "json",
    }).then((data) => {
      this.setState({
        loading: false,
        data: data.results,
      });
      document.body.style.overflow = null;
    });
  };

  showDeleteModal = () => {
    this.setState({
      deleteModalVisible: true,
    });
  };

  handleDeleteModalCancel = () => {
    this.setState({ deleteModalVisible: false });
  };

  handleDeleteModalOk = () => {
    var detail = this.state.detail;
    this.setState({ deleteLoading: true });

    reqwest({
      url: process.env.REACT_APP_API + "/product_banner/delete",
      method: "post",
      data: {
        id: detail.key,
      },
      type: "json",
    }).then(() => {
      this.setState({
        deleteLoading: false,
        deleteModalVisible: false,
        visible: false,
      });
      this.fetch();
    });
  };

  handleReorder = (dragIndex, draggedIndex) => {
    const data = [...this.state.data];
    const item = data.splice(dragIndex, 1)[0];
    data.splice(draggedIndex, 0, item);
    this.setState({
      data,
    });

    var postBody = [];
    for (var i = 0; i < data.length; i++) {
      postBody.push({
        key: data[i].key,
        display_order: i + 1,
      });
    }

    reqwest({
      url: process.env.REACT_APP_API + "/product_banner/reorder",
      method: "post",
      data: {
        v: postBody,
      },
      type: "json",
    }).then(() => {});
  };

  render() {
    const uploadButton = (
      <div>
        <Icon type={this.state.loading ? "loading" : "plus"} />
        <div className="ant-upload-text">Upload</div>
      </div>
    );
    const {
      deleteModalVisible,
      deleteLoading,
      deleteButton,
      imageMobileUrl,
      imageUrl,
      detail,
    } = this.state;
    const { getFieldDecorator } = this.props.form;

    return (
      <div id="product-banner-component">
        <Drawer
          id="product-banner-component-drawer"
          title="EDIT"
          placement="right"
          closable={false}
          width={730}
          onClose={this.onClose}
          visible={this.state.visible}
          bodyStyle={{ paddingBottom: 80 }}
        >
          {/* <Form.Item label="Title EN *">
						{getFieldDecorator('title_en', {
							initialValue: detail.title_en,
							rules: [{ required: true, message: 'Please input your title!' }],
						})(
							<Input placeholder="" />
						)}
					</Form.Item> */}
          <Form.Item label="Title KH ">
            {getFieldDecorator("title_kh", {
              initialValue: detail.title_kh,
              // rules: [{ required: true, message: 'Please input your title!' }],
            })(<Input placeholder="" />)}
          </Form.Item>
          {/* <Form.Item label="Description EN *">
						{getFieldDecorator('description_en', {
							initialValue: detail.description_en,
							rules: [{ required: true, message: 'Please input your title!' }],
						})(
							<TextArea rows={4} placeholder="" />
						)}
					</Form.Item> */}
          <Form.Item label="Description KH ">
            {getFieldDecorator("description_kh", {
              initialValue: detail.description_kh,
              // rules: [{ required: true, message: 'Please input your title!' }],
            })(<TextArea rows={4} placeholder="" />)}
          </Form.Item>
          <Form onSubmit={this.onSave} layout={"vertical"}>
            <Form.Item label="" style={{ display: "none" }}>
              {getFieldDecorator("id", {
                initialValue: "",
              })(<Input />)}
            </Form.Item>
            <div>Desktop Banner</div>
            <Form.Item>
              <Upload
                accept=".png, .jpg"
                name="Upload"
                listType="picture-card"
                className="photo-uploader"
                showUploadList={false}
                action={process.env.REACT_APP_API + "/image/test/upload"}
                beforeUpload={beforeUpload}
                onChange={this.handleChange}
              >
                {imageUrl ? (
                  <img src={imageUrl} alt="Upload" style={{ width: "100%" }} />
                ) : (
                  uploadButton
                )}
              </Upload>
              {getFieldDecorator("image")(<Input type="hidden" />)}
              <div className="text-center">
                <Text>1900 x 800</Text>
              </div>
            </Form.Item>
            <div>Mobile Banner</div>
            <Form.Item>
              <Upload
                accept=".png, .jpg"
                name="Upload"
                listType="picture-card"
                className="photo-uploader"
                showUploadList={false}
                action={process.env.REACT_APP_API + "/image/test/upload"}
                beforeUpload={beforeUpload}
                onChange={this.handleMobileChange}
              >
                {imageMobileUrl ? (
                  <img
                    src={imageMobileUrl}
                    alt="Upload"
                    style={{ width: "100%" }}
                  />
                ) : (
                  uploadButton
                )}
              </Upload>
              {getFieldDecorator("imageMobile")(<Input type="hidden" />)}
              <div className="text-center">
                <Text>800 x 600</Text>
              </div>
            </Form.Item>

            <Form.Item label="Phone">
              {getFieldDecorator("phone", {
                initialValue: detail.phone,
              })(<Input placeholder="" />)}
            </Form.Item>

            {/* <Form.Item label="Link to URL (Optional)">
							{getFieldDecorator('url', {
								initialValue: detail.url
							})(
								<Input placeholder="https://" />
							)}
						</Form.Item>
						<Form.Item label="Duration">
							{getFieldDecorator('duration', {
								initialValue: detail.duration,
								rules: [{ required: true, message: 'Please input duration!' }]
							})(
								<RangePicker />
							)}
						</Form.Item>
						<Form.Item label="Product Category*">
							{getFieldDecorator('type', {
								initialValue: detail.type,
								rules: [{ required: true, message: 'Please input Product Description!' }],
							})(
								<Select style={{ width: '100%', marginRight: 8 }} placeholder="Please select">
									<Select.Option value="1">Dugro</Select.Option>
									<Select.Option value="2">Supergold</Select.Option>
								</Select>
							)}
						</Form.Item> */}
            <Form.Item label="Publish Status*">
              {getFieldDecorator("status", {
                validateTrigger: ["onChange", "onBlur"],
                initialValue: "1",
                rules: [
                  { required: true, message: "Please input your status!" },
                ],
              })(
                <Radio.Group>
                  <Radio.Button value="1">
                    <i className="fa fa-check" style={{ marginRight: 5 }}></i>
                    Active
                  </Radio.Button>
                  <Radio.Button value="0">
                    <i className="fa fa-close" style={{ marginRight: 5 }}></i>
                    Inactive
                  </Radio.Button>
                </Radio.Group>
              )}
            </Form.Item>

            <Form.Item label="Url link out*">
              {getFieldDecorator("url", {
                initialValue: detail.url,
                // rules: [{ required: true, message: 'Please input your status!' }],
              })(<Input placeholder="" maxLength={1000} />)}
            </Form.Item>

            <div
              style={{
                position: "absolute",
                bottom: 0,
                width: "100%",
                borderTop: "1px solid #e8e8e8",
                padding: "10px 16px",
                left: 0,
                background: "#fff",
                borderRadius: "0 0 4px 4px",
                zIndex: 2,
              }}
            >
              <Row>
                <Col span={12}>
                  <Button
                    onClick={this.onClose}
                    style={{ background: "#EDEDED" }}
                  >
                    Close
                  </Button>
                </Col>
                <Col span={12} className="text-right">
                  <Button
                    style={{
                      marginRight: 8,
                    }}
                    disabled={deleteButton ? false : true}
                    onClick={this.showDeleteModal}
                  >
                    <i className="fa fa-close" style={{ marginRight: 5 }}></i>
                    Delete
                  </Button>
                  <Button htmlType="submit" type="primary">
                    Save
                  </Button>
                </Col>
              </Row>
            </div>
          </Form>
        </Drawer>
        <Row>
          <Col span={24}>
            <Title level={1}>Product Banner</Title>
          </Col>
        </Row>
        <div className="paper-panel">
          <Row>
            <Col span={12}>
              <Search
                placeholder="Title"
                onChange={({ target: { value } }) =>
                  this.handleSearchChange(value)
                }
                style={{ width: 350 }}
              />
            </Col>
            <Col span={12} className="text-right">
              <Button
                type="primary"
                style={{ minWidth: 140 }}
                onClick={() => this.showDrawer(-1)}
              >
                <Icon type="plus" />
                Create
              </Button>
            </Col>
          </Row>
          <Table
            columns={this.columns}
            dataSource={this.state.data}
            pagination={this.state.pagination}
            loading={this.state.loading}
            onChange={this.handleTableChange}
          />
        </div>
        <Modal
          visible={deleteModalVisible}
          closable={false}
          width={400}
          footer={[
            <Button
              key="submit"
              loading={deleteLoading}
              onClick={this.handleDeleteModalOk}
            >
              Yes, Delete
            </Button>,
            <Button
              key="back"
              type="primary"
              onClick={this.handleDeleteModalCancel}
              style={{ minWidth: 120 }}
            >
              No
            </Button>,
          ]}
        >
          <div className="text-center">
            <p>Do you want to delete this item?</p>
          </div>
        </Modal>
      </div>
    );
  }
}

export default Form.create({ name: "product_banner_form" })(App);
