import React from "react";
import {
  Modal,
  Icon,
  Typography,
  Table,
  Button,
  Row,
  Col,
  Input,
  Drawer,
  Form,
  Radio,
  Upload,
  message,
} from "antd";
import "antd/dist/antd.css";
import "./home_banner.css";
import "./news.css";
import reqwest from "reqwest";

import Dotdotdot from "react-dotdotdot";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import dragula from "dragula";
import "dragula/dist/dragula.css";
import moment from "moment";
import { Tabs } from "antd";

const { TabPane } = Tabs;
const path = require("path");
const image2base64 = require("image-to-base64");
const { Search } = Input;
const { Text, Title } = Typography;

var searchTimeoutId = false;

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
}

function beforeUpload(file) {
  const isJpgOrPng =
    file.type === "image/jpeg" ||
    file.type === "image/png" ||
    file.type === "image/webp";
  if (!isJpgOrPng) {
    message.error("You can only upload JPG/PNG/Webp file!");
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error("Image must smaller than 2MB!");
  }
  return isJpgOrPng && isLt2M;
}

function validateImage(file) {
  const isJpgOrPng =
    file.type === "image/jpeg" ||
    file.type === "image/png" ||
    file.type === "image/webp";
  if (!isJpgOrPng) {
    message.error("You can only upload JPG/PNG/WebP file!");
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error("Image must smaller than 2MB!");
  }
  return isJpgOrPng && isLt2M;
}

function uploadImage(file, cb) {
  let reader = new FileReader();
  reader.readAsDataURL(file);

  reader.onload = function () {
    var b64 = reader.result;
    reqwest({
      url: process.env.REACT_APP_API + "/image/save",
      method: "post",
      data: {
        image: b64,
      },
      type: "json",
    }).then((data) => {
      cb(data.src);
    });
  };

  reader.onerror = function (error) {};

  return false;
}

var Size = ReactQuill.Quill.import("attributors/style/size");
Size.whitelist = ["13px", "14px", "16px", "18px", "20px", "32px", "72px"];
ReactQuill.Quill.register(Size, true);

const validateEnglishOnly = (rule, value, callback) => {
  const englishOnlyRegex = /^[a-zA-Z\s.,!?'\-@#]*$/; // Added symbols: .,!?'-@#
  if (value && !englishOnlyRegex.test(value)) {
    callback(
      "Please use only English letters, spaces, and basic punctuation symbols: .,!?'-@#"
    );
  } else {
    callback();
  }
};

class HomeArticle extends React.Component {
  state = {
    data: [],
    key: false,
    loading: false,
    visible: false,
    imageUrl_1: false,
    imageUrl_2: false,
    imageBase64: "",
    saveButton: false,
    deleteModalVisible: false,
    deleteLoading: false,
    deleteButton: false,
    searchValue: "",
    detail: { title: ["", ""] },
  };

  constructor(props) {
    super(props);
    this.columns = [
      {
        title: "Sort",
        dataIndex: "display_index",
        key: "display_index",
        render: () => (
          <Icon
            type="menu"
            className="draggable"
            style={{
              fontSize: "13pt",
              fontWeight: "bolder",
              cursor: "pointer",
            }}
          />
        ),
      },
      {
        title: "Article Titles",
        dataIndex: "title_en",
        key: "title_en",
        render: (data) => (
          <div>
            <Dotdotdot clamp={1}>
              <Text>{data}</Text>
            </Dotdotdot>
          </div>
        ),
      },
      {
        title: "Picture",
        dataIndex: "thumbnail_photo",
        key: "image",
        width: 300,
        render: (thumbnail_photo) => (
          <img src={thumbnail_photo} style={{ maxHeight: 150 }} alt="" />
        ),
      },
      {
        title: "Group",
        dataIndex: "group",
        key: "group",
        render: (data) => (
          <div>
            <Dotdotdot clamp={1}>
              <Text>{data === 1 ? "Home Article" : "Other Article"}</Text>
            </Dotdotdot>
          </div>
        ),
      },
      {
        title: "Created Date",
        dataIndex: "created_time",
        key: "created_time",
        width: 200,
        render: (data) => (
          <div>
            <Text style={{ wordWrap: "break-word" }}>
              {moment(data).format("DD/MM/YYYY")}
            </Text>
          </div>
        ),
      },
      {
        title: "Published",
        dataIndex: "status",
        key: "published",
        width: 100,
        align: "center",
        render: (status) =>
          "" + status === "1" ? (
            <i className="fa fa-check" style={{ fontSize: 15 }}></i>
          ) : (
            <i className="fa fa-times" style={{ fontSize: 15 }}></i>
          ),
      },
      {
        title: "",
        dataIndex: "id",
        key: "id",
        width: 50,
        render: (key) => (
          <Button
            onClick={() => {
              this.showDrawer(key);
            }}
            className="icon-btn"
          >
            <Icon type="setting" />
          </Button>
        ),
      },
    ];
  }

  componentDidMount() {
    document.title = "Home Article";

    this.fetch();
    console.log("is fetch active");

    const container = document.querySelector(".ant-table-tbody");
    const drake = dragula([container], {
      moves: (el) => {
        this.start = this.getIndexInParent(el);
        return true;
      },
    });

    drake.on("drop", (el) => {
      this.end = this.getIndexInParent(el);
      console.log("drop : ", this.state);
      this.handleReorder(this.start, this.end);
    });
  }

  getIndexInParent = (el) => {
    return Array.from(el.parentNode.children).indexOf(el);
  };

  showDrawer = async (key) => {
    var data = {};
    this.setState({
      imageUrl: null,
    });
    if (key === -1) {
      data = {
        id: "",
        title_en: "",
        title_km: "",
        short_description_en: "",
        short_description_km: "",
        detail_en: "",
        detail_km: "",
        status: "1",
        btn_status: "1",
        imageUrl_1: null,
        imageUrl_2: null,
      };
    } else {
      for (var i = 0; i < this.state.data.length; i++) {
        if (this.state.data[i].id === key) {
          data = this.state.data[i];
          break;
        }
      }
    }
    console.log(data);
    data.status = "" + data.status;

    data.btn_status = "" + data.btn_status;

    this.props.form.setFieldsValue(data);

    this.setState({
      visible: true,
      detail: data,
      saveButton: true,
      deleteButton: key === -1 ? false : true,
    });

    if (data.imageUrl_1) {
      image2base64(data.imageUrl_1) // you can also to use url
        .then((response) => {
          var b64 = "data:image/";
          var ext = path.extname(data.imageUrl_1);
          if (ext === ".jpg" || ext === ".jpeg") {
            b64 += "jpeg;base64," + response;
          } else if (ext === ".png") {
            b64 += "png;base64," + response;
          }
          if (b64 !== "data:image/") {
            this.props.form.setFieldsValue({
              imageUrl_1: b64,
            });

            this.setState({
              imageUrl_1: b64,
              detail: {
                cover_photo: b64,
              },
            });
          }
        })
        .catch((error) => {
          //Exepection error....
        });
    }
    if (data.imageUrl_2) {
      image2base64(data.imageUrl_1) // you can also to use url
        .then((response) => {
          var b64 = "data:image/";
          var ext = path.extname(data.imageUrl_2);
          if (ext === ".jpg" || ext === ".jpeg") {
            b64 += "jpeg;base64," + response;
          } else if (ext === ".png") {
            b64 += "png;base64," + response;
          }
          if (b64 !== "data:image/") {
            this.props.form.setFieldsValue({
              imageUrl_2: b64,
            });

            this.setState({
              imageUrl_2: b64,
              detail: {
                thumbnail_photo: b64,
              },
            });
          }
        })
        .catch((error) => {
          //Exepection error....
        });
    }
    this.props.form.setFieldsValue({
      imageUrl_1: data.cover_photo,
      imageUrl_2: data.thumbnail_photo,
    });

    this.setState({
      imageUrl_1: data.cover_photo,
      imageUrl_2: data.thumbnail_photo,
      detail: {
        id: data.id,
        cover_photo: data.cover_photo,
        thumbnail_photo: data.thumbnail_photo,
      },
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };

  onSave = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        if (values.imageUrl_1 && values.imageUrl_2) {
          if (!this.checkTitleForSlug(values.title_en) || values.id !== "") {
            this.setState({ saveButton: false });
            reqwest({
              url: process.env.REACT_APP_API + "/home_article/save",
              method: "post",
              data: values,
              type: "json",
            })
              .then((data) => {
                console.log(data);
                if (data.success) {
                  this.props.form.setFieldsValue({
                    imageUrl_1: null,
                    imageUrl_2: null,
                  });
                  this.setState({
                    visible: false,
                    imageUrl_1: null,
                    imageUrl_2: null,
                  });
                  this.fetch();
                  message.success("Success.");
                } else {
                  this.setState({ visible: true });
                }
              })
              .catch((err) => {
                console.error(err);
              });
          } else {
            this.props.form.setFieldsValue({
              title_en: "",
            });
            message.error("Have this title.");
            console.log("have this title");
          }
        } else {
          message.error("Please add image");
        }
      }
    });
  };

  handleChange_1 = (info) => {
    if (info.file.status === "uploading") {
      this.setState({ loading: true });
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (image) => {
        this.props.form.setFieldsValue({
          imageUrl_1: image,
        });
        this.setState({
          imageUrl_1: image,
          loading: false,
        });
      });
    }
  };

  handleChange_2 = (info) => {
    if (info.file.status === "uploading") {
      this.setState({ loading: true });
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (image) => {
        this.props.form.setFieldsValue({
          imageUrl_2: image,
        });
        this.setState({
          imageUrl_2: image,
          loading: false,
        });
      });
    }
  };
  handleSearchChange(value) {
    this.setState({ searchValue: value });
    if (searchTimeoutId) {
      window.clearTimeout(searchTimeoutId);
    }
    searchTimeoutId = window.setTimeout(() => {
      this.fetch();
    }, 1500);
  }

  fetch = (params = {}) => {
    this.setState({ loading: true });
    this.setState({ data: [] });
    reqwest({
      url: process.env.REACT_APP_API + "/home_article",
      method: "get",
      data: {
        search: this.state.searchValue,
        ...params,
      },
      type: "json",
    }).then((data) => {
      this.setState({
        loading: false,
        data: data.results,
      });
      console.log("fetch:", data);
      document.body.style.overflow = null;
    });
  };

  showDeleteModal = () => {
    this.setState({
      deleteModalVisible: true,
    });
  };

  handleDeleteModalCancel = () => {
    this.setState({ deleteModalVisible: false });
  };

  handleDeleteModalOk = () => {
    var detail = this.state.detail;
    this.setState({ deleteLoading: true });
    console.log("id :", this.state.detail);
    reqwest({
      url: process.env.REACT_APP_API + "/home_article/delete",
      method: "post",
      data: {
        id: detail.id,
      },
      type: "json",
    }).then(() => {
      this.setState({
        deleteLoading: false,
        deleteModalVisible: false,
        visible: false,
      });
      this.fetch();
    });
  };

  handleReorder = (dragIndex, draggedIndex) => {
    const data = [...this.state.data];
    const item = data.splice(dragIndex, 1)[0];
    data.splice(draggedIndex, 0, item);
    this.setState({
      data,
    });

    var postBody = [];
    for (var i = 0; i < data.length; i++) {
      postBody.push({
        key: data[i].id,
        display_order: i + 1,
      });
    }

    reqwest({
      url: process.env.REACT_APP_API + "/home_article/reorder",
      method: "post",
      data: {
        v: postBody,
      },
      type: "json",
    }).then((data) => {
      console.log("order chagne : ", data);
      this.fetch();
    });
  };

  modules = {
    toolbar: {
      container: [
        ["bold", "italic", "underline", "strike"], // toggled buttons
        ["link", "image", "video"],
        [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        [{ list: "ordered" }, { list: "bullet" }],
        [{ script: "sub" }, { script: "super" }], // superscript/subscript
        [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
        // [{ 'direction': 'rtl' }],                         // text direction

        // [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
        [{ size: Size.whitelist }],

        // [{ 'font': Font.whitelist }],
        [{ align: [] }],

        ["clean"], // remove formatting button
      ],
      handlers: {
        image: this.imageHandler,
      },
    },
  };

  imageHandler() {
    const input = document.createElement("input");

    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    input.onchange = async () => {
      const file = input.files[0];
      if (!validateImage(file)) {
        return false;
      }
      const formData = new FormData();

      formData.append("image", file);

      // Save current cursor state
      const range = this.quill.getSelection(true);

      // Insert temporary loading placeholder image
      // this.quill.insertEmbed(range.index, 'image', `${window.location.origin}/images/loaders/placeholder.gif`);

      // Move cursor to right side of image (easier to continue typing)
      this.quill.setSelection(range.index + 1);

      uploadImage(file, (cb) => {
        this.quill.insertEmbed(range.index, "image", cb);
      });

      // Remove placeholder image
      // this.quill.deleteText(range.index, 1);

      // Insert uploaded image
      // this.quill.insertEmbed(range.index, 'image', res.body.image);
      // this.quill.insertEmbed(range.index, 'image', res);
    };
  }

  getBase64 = (file, cb) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result);
    };
    reader.onerror = function (error) {};
  };

  checkTitleForSlug = (title_en) => {
    return this.state.data.some((detail) => detail.title_en === title_en);
  };

  render() {
    const uploadButton = (
      <div>
        <Icon type={this.state.loading ? "loading" : "plus"} />
        <div className="ant-upload-text">Upload</div>
      </div>
    );
    const {
      saveButton,
      deleteButton,
      deleteModalVisible,
      deleteLoading,
      imageUrl_1,
      imageUrl_2,
      searchValue,
      detail,
    } = this.state;
    const { getFieldDecorator } = this.props.form;

    return (
      <div id="#news-component">
        <Drawer
          id="#news-component-drawer"
          title="Home Article"
          placement="right"
          closable={false}
          width={730}
          onClose={this.onClose}
          visible={this.state.visible}
          bodyStyle={{ paddingBottom: 80 }}
        >
          <Form onSubmit={this.onSave} layout={"vertical"}>
            <Form.Item label="" style={{ display: "none" }}>
              {getFieldDecorator("id", {
                initialValue: detail.id || "",
              })(<Input />)}
            </Form.Item>
            <Form.Item label="title en*">
              {getFieldDecorator("title_en", {
                initialValue: detail.title_en,
                rules: [
                  { required: true, message: "Please input Article Name!" },
                  { validator: validateEnglishOnly },
                ],
              })(<Input placeholder="" maxLength={1000} />)}
            </Form.Item>
            <Form.Item label="title km*">
              {getFieldDecorator("title_km", {
                initialValue: detail.title_km,
                rules: [
                  { required: true, message: "Please input Article Name!" },
                ],
              })(<Input placeholder="" maxLength={1000} />)}
            </Form.Item>
            <Form.Item label="Description en*">
              {getFieldDecorator("short_description_en", {
                initialValue: detail.short_description_en,
                rules: [
                  { required: true, message: "Please input Article Subtitle!" },
                ],
              })(<Input placeholder="" maxLength={1000} />)}
            </Form.Item>
            <Form.Item label="Description km*">
              {getFieldDecorator("short_description_km", {
                initialValue: detail.short_description_km,
                rules: [
                  { required: true, message: "Please input Article Subtitle!" },
                ],
              })(<Input placeholder="" maxLength={1000} />)}
            </Form.Item>
            <div className="news-row">
              <div className="news-col-50">
                <Text strong={true}>image cover</Text>
                <hr className="divider" />
                <Form.Item>
                  {getFieldDecorator("imageUrl_1", {
                    initialValue: detail.cover_photo,
                  })(
                    <Upload
                      accept=".png, .jpg .webp"
                      name="Upload"
                      listType="picture-card"
                      className="photo-uploader"
                      showUploadList={false}
                      action={process.env.REACT_APP_API + "/image/test/upload"}
                      beforeUpload={beforeUpload}
                      onChange={this.handleChange_1}
                    >
                      {imageUrl_1 ? (
                        <img
                          src={imageUrl_1}
                          alt="Upload"
                          style={{ width: "100%" }}
                        />
                      ) : (
                        uploadButton
                      )}
                    </Upload>
                  )}
                  <Text>1600 x 900</Text>
                </Form.Item>
              </div>
              <div className="news-col-50">
                <Text strong={true}>image thumbnail</Text>
                <hr className="divider" />
                <Form.Item>
                  {getFieldDecorator("imageUrl_2", {
                    initialValue: detail.thumbnail_photo,
                  })(
                    <Upload
                      accept=".png, .jpg"
                      name="Upload"
                      listType="picture-card"
                      className="photo-uploader"
                      showUploadList={false}
                      action={process.env.REACT_APP_API + "/image/test/upload"}
                      beforeUpload={beforeUpload}
                      onChange={this.handleChange_2}
                    >
                      {imageUrl_2 ? (
                        <img
                          src={imageUrl_2}
                          alt="Upload"
                          style={{ width: "100%" }}
                        />
                      ) : (
                        uploadButton
                      )}
                    </Upload>
                  )}
                  <Text>800 x 300</Text>
                </Form.Item>
              </div>
            </div>
            <Tabs defaultActiveKey="1">
              <TabPane tab="Detail en" key="1">
                {getFieldDecorator("detail_en", {
                  initialValue: detail.detail_en ? detail.detail_en : "",
                })(
                  <ReactQuill
                    id="detail-information-en"
                    modules={this.modules}
                  />
                )}
              </TabPane>
              <TabPane tab="Detail kn" key="2">
                {getFieldDecorator("detail_km", {
                  initialValue: detail.detail_km ? detail.detail_km : "",
                })(
                  <ReactQuill
                    id="detail-information-en"
                    modules={this.modules}
                  />
                )}
              </TabPane>
            </Tabs>
            <div
              style={{
                marginTop: 10,
              }}
            >
              <Form.Item label="Article Group*" style={{ display: "none" }}>
                {getFieldDecorator("news_group", {
                  validateTrigger: ["onChange", "onBlur"],
                  initialValue: 1, // Ensure the initial value is an integer
                  rules: [
                    { required: true, message: "Please input your status!" },
                  ],
                })(
                  <Radio.Group>
                    <Radio.Button value={1}>Home Article</Radio.Button>
                  </Radio.Group>
                )}
              </Form.Item>
            </div>

            <Form.Item label="Publish Status*">
              {getFieldDecorator("status", {
                validateTrigger: ["onChange", "onBlur"],
                initialValue: "1",
                rules: [
                  { required: true, message: "Please input your status!" },
                ],
              })(
                <Radio.Group>
                  <Radio.Button value="1">
                    <i className="fa fa-check" style={{ marginRight: 5 }}></i>
                    Active
                  </Radio.Button>
                  <Radio.Button value="0">
                    <i className="fa fa-close" style={{ marginRight: 5 }}></i>
                    Inactive
                  </Radio.Button>
                </Radio.Group>
              )}
            </Form.Item>

            <div
              style={{
                position: "absolute",
                bottom: 0,
                width: "100%",
                borderTop: "1px solid #e8e8e8",
                padding: "10px 16px",
                left: 0,
                background: "#fff",
                borderRadius: "0 0 4px 4px",
                zIndex: 2,
              }}
            >
              <Row>
                <Col span={12}>
                  <Button
                    onClick={this.onClose}
                    style={{ background: "#EDEDED" }}
                  >
                    Close
                  </Button>
                </Col>
                <Col span={12} className="text-right">
                  <Button
                    style={{
                      marginRight: 8,
                    }}
                    disabled={deleteButton ? false : true}
                    onClick={this.showDeleteModal}
                  >
                    <i className="fa fa-close" style={{ marginRight: 5 }}></i>
                    Delete
                  </Button>
                  <Button
                    disabled={saveButton ? false : true}
                    htmlType="submit"
                    type="primary"
                  >
                    Save
                  </Button>
                </Col>
              </Row>
            </div>
          </Form>
        </Drawer>
        <Row>
          <Col span={24}>
            <Title level={1}>Home Article</Title>
          </Col>
        </Row>
        <div className="paper-panel">
          <Row>
            <Col span={12}>
              <Search
                placeholder="Product Name"
                onChange={({ target: { value } }) =>
                  this.handleSearchChange(value)
                }
                value={searchValue}
                style={{ width: 350 }}
              />
            </Col>
            <Col span={12} className="text-right">
              <Button
                type="primary"
                onClick={() => this.showDrawer(-1)}
                style={{ minWidth: 140 }}
              >
                <Icon type="plus" />
                Create
              </Button>
            </Col>
          </Row>
          <Table
            columns={this.columns}
            dataSource={this.state.data}
            pagination={this.state.pagination}
            loading={this.state.loading}
            onChange={this.handleTableChange}
          />
        </div>
        <Modal
          visible={deleteModalVisible}
          closable={false}
          width={400}
          footer={[
            <Button
              key="submit"
              loading={deleteLoading}
              onClick={this.handleDeleteModalOk}
            >
              Yes, Delete
            </Button>,
            <Button
              key="back"
              type="primary"
              onClick={this.handleDeleteModalCancel}
              style={{ minWidth: 120 }}
            >
              No
            </Button>,
          ]}
        >
          <div className="text-center">
            <p>Do you want to delete this item?</p>
          </div>
        </Modal>
      </div>
    );
  }
}

export default Form.create({ name: "News" })(HomeArticle);