import React from "react";
import {
  Modal,
  Select,
  Drawer,
  Form,
  Icon,
  Checkbox,
  Typography,
  Table,
  Button,
  Row,
  Col,
  Input,
  DatePicker,
  Radio,
} from "antd";
import "antd/dist/antd.css";
import "./register.css";
import reqwest from "reqwest";
import Dotdotdot from "react-dotdotdot";
import moment from "moment";
const { Search } = Input;
const { Text, Title } = Typography;

const { RangePicker } = DatePicker;

const dateFormat = 'YYYY/MM/DD';

var searchTimeoutId = false;

class App extends React.Component {
  constructor(props) {
    super(props);

    this.columns = [
      {
        title: "Register date",
        dataIndex: "created_time",
        key: "created_time",
        render: (data) => (
          <div>
            <Dotdotdot clamp={1}>
              <Text style={{ wordWrap: "break-word" }}>
                {moment(data).format("DD/MM/YYYY")}
              </Text>
            </Dotdotdot>
          </div>
        ),
      },
      {
        title: "Full name",
        dataIndex: "fullname",
        key: "fullname",
        render: (data) => (
          <div>
            <Text>{data}</Text>
          </div>
        ),
      },
      // {
      //   title: "Address",
      //   dataIndex: "address",
      //   key: "address",
      //   render: (data) => (
      //     <div>
      //       <Text>{data}</Text>
      //     </div>
      //   ),
      // },
      {
        title: "Phone number",
        dataIndex: "phone",
        width: 250,
        key: "phone",
        render: (data, row) => (
          <div>
            <Text>{data}</Text>
          </div>
        ),
      },
      {
        title: "Email",
        dataIndex: "email",
        key: "email",
        render: (data, row) => (
          <div>
            <Text>{data}</Text>
          </div>
        ),
      },
      {
        title: "Pregnant",
        dataIndex: "pregnant",
        key: "pregnant",
        render: (data, row) => (
          <div>
            <Text>{data}</Text>
          </div>
        ),
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        width: 120,
        align: "center",
        render: (status) =>
          status === 1 ? (
            <i className='fa fa-check' style={{ fontSize: 15 }}></i>
          ) : (
              <i className='fa fa-times' style={{ fontSize: 15 }}></i>
            ),
      },
      {
        title: "UUID",
        dataIndex: "uuid",
        key: "uuid",
        align: "center",
        render: (uuid, row) =>
          uuid ? (
            <div>
              <Text>{uuid}</Text>
            </div>
          ) : (
              <Button
                onClick={() => {
                  this.requestUUID(row);
                }}
                className='icon-btn'
              >
                Request
              </Button>
            ),
      },
      {
        title: "Manage",
        dataIndex: "id",
        key: "manage",
        width: 100,
        render: (key) => (
          <Button
            onClick={() => {
              this.showDrawer(key);
            }}
            className='icon-btn'
          >
            Edit
          </Button>
        ),
      },
    ];
  }

  handleExport() {
    window.open(
      process.env.REACT_APP_API +
      "/club_register/export?search=" +
      this.state.searchValue +
      "&pregnantValue=" +
      this.state.pregnantValue +
      // "&registerDateValue=" +
      // this.state.registerDateValue
      "&datestart=" +
      this.state.searchDateStart +
      "&dateend=" +
      this.state.searchDateEnd
    );
  }

  state = {
    data: [],
    region: [],
    pagination: { pageSize: 10 },
    loading: false,
    visible: false,
    deleteButton: false,
    saveButton: true,
    deleteModalVisible: false,
    deleteLoading: false,
    searchValue: "",
    pregnantValue: "",
    registerDateValue: "",
    child_required: false,
    child_pregnant_required: false,
    child_hidden: "none",
    searchDateStart:"",
    searchDateEnd:"",
    detail: {
      id: "",
      fullname: "",
      status: "1",
      phone: "",
      pregnant: false,
      have_other_children: false,
      address1: "",
      address2: "",
      child_fullname: "",
      child_birthdate: null,
      get_by: "",
    },
  };

  requestUUID = async (row) => {
    this.props.pageLoading(true);
    var data = {};
    for (var i = 0; i < this.state.data.length; i++) {
      if (this.state.data[i].id === row.id) {
        data = this.state.data[i];
        break;
      }
    }
        ;

    var body = {
      platform: "apta",
      id: data.id,
      email: data.email,
      phone_number: data.phone,
      profile: {
        name: data.fullname,
        phone_number: data.phone,
        contact_channel: data.opt_in,
      },
      address: {
        house: data.house,
        street: data.street,
        commune: data.commune,
        district: data.district,
        province: data.province,
        zip_code: "",
      },
      children: [
        {
          pregnant: data.pregnant === "Yes" ? 1 : 0,
          estimated_due_date: data.pregnant_date ? data.pregnant_date : "",
          gender: data.gender_pregnant === "0" ? "Boy" : "Girl",
          name: data.child_fullname_pregnant
            ? data.child_fullname_pregnant
            : "",
          birthdate: data.child_birthday_pregnant
            ? data.child_birthday_pregnant
            : "",
          delivered:
            data.labour_pregnant === "Naturally" ? "natural" : "c-section",
          danone_brands_pregnant: data.danone_brands_pregnant
            ? data.danone_brands_pregnant
            : "",
        },
      ],
    };

    reqwest({
      url: process.env.REACT_APP_API + "/club_register/uuid",
      method: "post",
      data: body,
      type: "json",
    }).then((data) => {
      this.props.pageLoading(false);
      if (data.success) {
        this.fetch();
      }
    });
  };

  showDrawer = async (key) => {
    var data = {};
    this.props.form.resetFields();
    if (key === -1) {
      data = {
        id: "",
        fullname: "",
        status: "1",
        phone: "",
        pregnant: "",
        have_other_children: false,
        address1: "",
        address2: "",
        child_fullname: "",
        child_birthdate: null,
        get_by: "",
        status_admin: "",
      };
    } else {
      for (var i = 0; i < this.state.data.length; i++) {
        if (this.state.data[i].id === key) {
          data = this.state.data[i];
          break;
        }
      }
    }

    var statusAdmin = data.status_admin ? data.status_admin.split(",") : [];

    this.props.form.setFieldsValue({
      id: data.id,
      fullname: data.fullname,
      phone: data.phone,
      house: data.house,
      street: data.street,
      commune: data.commune,
      district: data.district,
      province: data.province,
      pregnant: data.pregnant,
      pregnant_date: data.pregnant_date ? moment(data.pregnant_date) : null,
      have_other_children: data.have_other_children,
      child_fullname: data.child_fullname,
      child_birthdate: data.child_birthdate
        ? moment(data.child_birthdate)
        : null,
      labour: data.labour,
      opt_in: data.opt_in ? data.opt_in.split(",") : [],
      gender: data.gender,
      child_fullname_pregnant: data.child_fullname_pregnant,
      child_birthdate_pregnant: data.child_birthdate_pregnant
        ? moment(data.child_birthdate_pregnant)
        : null,
      labour_pregnant: data.labour_pregnant ? data.labour_pregnant : "",
      danone_brands_pregnant: data.danone_brands_pregnant
        ? data.danone_brands_pregnant
        : "",
      gender_pregnant: data.gender_pregnant ? data.gender_pregnant : "",
      status: "" + data.status,
      status_admin: statusAdmin,
    });

    this.setState({
      deleteButton: key === -1 ? true : false,
      visible: true,
      detail: data,
      pregnant: key === -1 ? "none" : data.pregnant === "No" ? "none" : "block",
      pregnant_child:
        key === -1 ? "none" : data.pregnant !== "No" ? "none" : "block",
      child_required: data.have_other_children !== "No",
      child_pregnant_required: data.pregnant === "No",
      child_hidden:
        key === -1
          ? "none"
          : data.have_other_children === "No"
            ? "none"
            : "block",
      saveButton: true,
    });
  };

  showDeleteModal = () => {
    this.setState({
      deleteModalVisible: true,
    });
  };

  handleDeleteModalCancel = () => {
    this.setState({ deleteModalVisible: false });
  };

  handleDeleteModalOk = () => {
    var detail = this.state.detail;
    this.setState({ deleteLoading: true });

    reqwest({
      url: process.env.REACT_APP_API + "/club_register/delete",
      method: "post",
      data: {
        id: detail.id,
      },
      type: "json",
    }).then(() => {
      this.setState({
        deleteLoading: false,
        deleteModalVisible: false,
        visible: false,
      });
      this.fetch();
    });
  };

  onSave = (e) => {
    e.preventDefault();

    this.props.form.validateFields((err, values) => {
      if (!err) {
        if (values.password !== values.confirmPassword) {
          this.props.form.setFields({
            confirmPassword: {
              value: values.confirmPassword,
              errors: [new Error("Confirm Password is not same Password")],
            },
          });
        } else {
          this.setState({ saveButton: false });

          if (values.pregnant_date) {
            values.pregnant_date = moment(values.pregnant_date).format(
              "YYYY-MM-DD"
            );
          }
          values.child_birthdate = moment(values.child_birthdate).format(
            "YYYY-MM-DD"
          );

          if (values.have_other_children === "No") {
            values.gender = "";
            values.child_birthdate = null;
            values.child_fullname = "";
            values.danone_brands_pregnant = "";
          }

          values.child_birthdate_pregnant = moment(
            values.child_birthdate_pregnant
          ).format("YYYY-MM-DD");

          if (values.opt_in) {
            values.opt_in = values.opt_in.join(",");
          }

          reqwest({
            url: process.env.REACT_APP_API + "/club_register/save",
            method: "post",
            data: values,
            type: "json",
          }).then((data) => {
            if (data.success) {
              this.setState({ visible: false });
              this.fetch();
            } else {
              this.setState({ saveButton: true });
            }
          });
        }
      }
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };

  componentDidMount() {
    document.title = "Club Register";
    this.fetch();
    this.fetchRegion();
  }

  getIndexInParent = (el) => {
    return Array.from(el.parentNode.children).indexOf(el);
  };

  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager,
    });
    this.fetch({
      size: pagination.pageSize,
      page: pagination.current,
      sortField: sorter.field,
      sortOrder: sorter.order,
      ...filters,
    });
  };

  fetchRegion = () => {
    reqwest({
      url: "https://api.careline.cambodia.dumex.rgb72.dev/v1/data/provinces",
      method: "get",
      type: "json",
    }).then((data) => {
      this.setState({
        region: data.data,
      });
    });
  };

  fetch = (params = {}) => {
    this.props.pageLoading(true);
    reqwest({
      url: process.env.REACT_APP_API + "/club_register",
      method: "get",
      data: {
        size: this.state.pagination.pageSize,
        search: this.state.searchValue,
        pregnantValue: this.state.pregnantValue,
        // registerDateValue: this.state.registerDateValue,
        datestart: this.state.searchDateStart,
        dateend: this.state.searchDateEnd,
        ...params,
      },
      type: "json",
    }).then((data) => {
      this.setState({
        data: data.results,
      });
      this.props.pageLoading(false);
      document.body.style.overflow = null;
    });
  };

  handleSearchChange(value) {
    this.setState({ searchValue: value });
    if (searchTimeoutId) {
      window.clearTimeout(searchTimeoutId);
    }
    searchTimeoutId = window.setTimeout(() => {
      this.fetch({
        pregnantValue: this.state.pregnantValue,
        registerDateValue: this.state.registerDateValue,
      });
    }, 1500);
  }

  handleIsPregnantChange = (value) => {
    this.setState({
      pregnant_required: value === "Yes",
      pregnant: value === "Yes" ? "block" : "none",
      child_pregnant_required: value !== "Yes",
      pregnant_child: value !== "Yes" ? "block" : "none",
    });
  };

  handleIsPregnantFilterChange = (value) => {
    this.setState({ pregnantValue: value });
    if (searchTimeoutId) {
      window.clearTimeout(searchTimeoutId);
    }
    searchTimeoutId = window.setTimeout(() => {
      this.fetch({
        pregnantValue: this.state.pregnantValue,
        registerDateValue: this.state.registerDateValue,
      });
    }, 1500);
  };

  handleIsRegisterDateFilterChange = (value) => {
    // var date = value ? moment(value).format("DD/MM/YYYY") : "";
    // this.setState({ registerDateValue: date });

    this.setState({ searchDateStart: moment(value[0]).format('YYYY-MM-DD') });
    this.setState({ searchDateEnd: moment(value[1]).format('YYYY-MM-DD') });

    if (searchTimeoutId) {
      window.clearTimeout(searchTimeoutId);
    }
    searchTimeoutId = window.setTimeout(() => {
      this.fetch({
        pregnantValue: this.state.pregnantValue,
        registerDateValue: this.state.registerDateValue,
      });
    }, 1500);
  };

  handleHaveOtherChildChange = (value) => {
    this.setState({
      child_required: value !== "No",
      child_hidden: value === "No" ? "none" : "block",
    });
  };

  handleOptInChange = (value) => {
    const { detail } = this.state;
    detail.opt_in = value;
    this.setState({
      detail: detail,
    });
  };

  render() {

    if (!this.state.region.length) {
      return <></>
    }

    const {
      saveButton,
      deleteButton,
      deleteModalVisible,
      deleteLoading,
      searchValue,
      detail,
    } = this.state;
    const { getFieldDecorator } = this.props.form;
    const options = [
      { label: "Address", value: "Address" },
      { label: "Phone", value: "Phone" },
      { label: "SMS", value: "SMS" },
    ];

    const stationAdminOptions = [
      { label: "Welcome Pack", value: "Welcome Pack" },
      { label: "Birthday gift", value: "Birthday gift" },
      { label: "Sample", value: "Sample" },
    ];

    const optionItems = options.map((item, i) => (
      <Row key={i}>
        <Col span={12}>
          <Checkbox key={item.label} value={item.value}>
            {item.label}
          </Checkbox>
        </Col>
      </Row>
    ));

    const statusAdminItems = stationAdminOptions.map((item, i) => (
      <Row key={i}>
        <Col span={18}>
          <Checkbox key={item.label} value={item.value}>
            {item.label}
          </Checkbox>
        </Col>
      </Row>
    ));

    const getCommunesOptions = () => {
      const provinceSelected = this.state.region.find((item) => item.name === detail.province)
      if (provinceSelected && provinceSelected.districts && provinceSelected.districts.data && provinceSelected.districts.data.length) {
        const districtSelected = provinceSelected.districts.data.find((item) => item.name === detail.district)
        if (districtSelected && districtSelected.communes && districtSelected.communes.data && districtSelected.communes.data.length) {
          return districtSelected.communes.data
        }
      }
      return []
    }

    const getDistrictsOptions = () => {
      const provinceSelected = this.state.region.find((item) => item.name === detail.province)
      if (provinceSelected && provinceSelected.districts && provinceSelected.districts.data && provinceSelected.districts.data.length) {
        return provinceSelected.districts.data
      } else {
        return []
      }
    }

    return (
      <div id='register-component'>
        <Row>
          <Col span={24}>
            <Title level={1}>Club Register</Title>
          </Col>
        </Row>
        <div className='paper-panel'>
          <Row gutter={{ xs: 8, sm: 8, md: 8, lg: 8 }}>
            <Col span={7}>
              <Search
                placeholder='Full name / Phone Number '
                onChange={({ target: { value } }) =>
                  this.handleSearchChange(value)
                }
                style={{ width: "100%" }}
                value={searchValue}
              />
            </Col>
            <Col span={4}>
            { /*
              <DatePicker
                format={"DD/MM/YYYY"}
                onChange={this.handleIsRegisterDateFilterChange}
              />
              */ }

              <RangePicker
                onChange={this.handleIsRegisterDateFilterChange}
                format={dateFormat}
              />

            </Col>
            <Col span={4}>
              <Select
                style={{ width: "100%", marginRight: 8 }}
                onChange={this.handleIsPregnantFilterChange}
                placeholder='Pregnant'
              >
                <Select.Option value=''>All</Select.Option>
                <Select.Option value='Yes'>Yes</Select.Option>
                <Select.Option value='No'>No</Select.Option>
              </Select>
            </Col>
            <Col span={9} className='text-right'>
              <Button
                type='default'
                style={{ minWidth: 140, marginRight: "8px" }}
                onClick={() => this.handleExport()}
              >
                Export
              </Button>
              <Button
                type='primary'
                style={{ minWidth: 140 }}
                onClick={() => this.showDrawer(-1)}
              >
                <Icon type='plus' />
                Create
              </Button>
            </Col>
          </Row>

          <Table
            rowKey='id'
            columns={this.columns}
            dataSource={this.state.data}
            pagination={this.state.pagination}
            loading={this.state.loading}
            onChange={this.handleTableChange}
          />

          <Drawer
            id='register-component-drawer'
            title='Manage Profile'
            width={460}
            closable={false}
            onClose={this.onClose}
            headerStyle={{ textTransform: "uppercase", fontWeight: "bold" }}
            visible={this.state.visible}
            bodyStyle={{ paddingBottom: 120 }}
          >
            <Form onSubmit={this.onSave} layout='vertical' hideRequiredMark>
              <Form.Item label='' style={{ display: "none" }}>
                {getFieldDecorator("id", {
                  initialValue: detail.id,
                })(<Input />)}
              </Form.Item>
              <Row gutter={[16, 0]}>
                <Col span={12}>
                  <Form.Item label='Full name*'>
                    {getFieldDecorator("fullname", {
                      validateTrigger: ["onBlur"],
                      rules: [
                        {
                          required: true,
                          message: "Please enter full name",
                        },
                      ],
                    })(<Input placeholder='' />)}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label='Phone number*'>
                    {getFieldDecorator("phone", {
                      validateTrigger: ["onBlur"],
                      rules: [
                        {
                          required: true,
                          message: "Please enter phone number",
                        },
                      ],
                    })(<Input placeholder='' />)}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[16, 0]}>
                <Col span={12}>
                  <Form.Item label='Password*'>
                    {getFieldDecorator(
                      "password",
                      {}
                    )(<Input.Password placeholder='' />)}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label='Re-Password*'>
                    {getFieldDecorator(
                      "confirmPassword",
                      {}
                    )(<Input.Password placeholder='' />)}
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item label='House N#'>
                {getFieldDecorator("house", {
                  initialValue: detail.house,
                  rules: [
                    {
                      required: true,
                      message: "Please enter House N#",
                    },
                  ],
                })(<Input placeholder='' />)}
              </Form.Item>
              <Form.Item label='Street'>
                {getFieldDecorator("street", {
                  initialValue: detail.street,
                  rules: [
                    {
                      required: false,
                      message: "Please enter Street",
                    },
                  ],
                })(<Input placeholder='' />)}
              </Form.Item>
              <Form.Item label='City/Province'>
                {getFieldDecorator("province", {
                  initialValue: detail.province,
                  rules: [
                    {
                      required: false,
                      message: "Please enter City/Province",
                    },
                  ],
                })(
                  <Select
                    style={{ width: "100%", marginRight: 8 }}
                    onChange={(v) => {
                      let new_detail = detail;
                      new_detail.province = v;
                      this.setState({
                        detail: new_detail,
                      });
                      this.props.form.setFieldsValue({
                        commune: undefined,
                        district: undefined,
                      });
                    }}
                  >
                    {this.state.region ? (
                      this.state.region.map((data, index) => (
                        <Select.Option key={index} value={data.name}>
                          {data.name}
                        </Select.Option>
                      ))
                    ) : (
                        <Select.Option value={0}>Please select</Select.Option>
                      )}
                  </Select>
                )}
              </Form.Item>
              <Form.Item label='District / Khan'>
                {getFieldDecorator("district", {
                  initialValue: detail.district,
                  rules: [
                    {
                      required: false,
                      message: "Please enter District / Khan",
                    },
                  ],
                })(
                  <Select
                    style={{ width: "100%", marginRight: 8 }}
                    onChange={(v) => {
                      let new_detail = detail;
                      new_detail.district = v;
                      this.setState({
                        detail: new_detail,
                      });
                      this.props.form.setFieldsValue({
                        district: undefined,
                      });
                    }}
                  >
                    {getDistrictsOptions().map((data, index) => (
                      <Select.Option key={index} value={data.name}>
                        {data.name}
                      </Select.Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
              <Form.Item label='Commune / district'>
                {getFieldDecorator("commune", {
                  initialValue: detail.commune,
                  rules: [
                    {
                      required: false,
                      message: "Please enter Commune / district",
                    },
                  ],
                })(
                  <Select
                    style={{ width: "100%", marginRight: 8 }}
                    onChange={(v) => {
                      let new_detail = detail;
                      new_detail.commune = v;
                      this.setState({
                        detail: new_detail,
                      });
                    }}
                  >
                    {getCommunesOptions().map((data, index) => (
                      <Select.Option key={index} value={data.name}>
                        {data.name}
                      </Select.Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
              <Row gutter={[16, 0]}>
                <Col span={12}>
                  <Form.Item label='Are you pregnant?'>
                    {getFieldDecorator("pregnant", {
                      validateTrigger: ["onChange", "onBlur"],
                      rules: [
                        { required: true, message: "Please choose answer" },
                      ],
                    })(
                      <Select
                        style={{ width: "100%", marginRight: 8 }}
                        onChange={this.handleIsPregnantChange}
                      >
                        <Select.Option value={"Yes"}>Yes</Select.Option>
                        <Select.Option value={"No"}>No</Select.Option>
                      </Select>
                    )}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label='Pregnant Date'
                    style={{ display: this.state.pregnant }}
                  >
                    {getFieldDecorator("pregnant_date", {
                      rules: [
                        {
                          required: this.state.pregnant_required,
                          message: "Please enter Pregnant date",
                        },
                      ],
                    })(<DatePicker format={"DD/MM/YYYY"} />)}
                  </Form.Item>

                </Col>
              </Row>
               <Row gutter={[16, 0]} style={{display: this.state.pregnant_child}}>
								<Col span={12}>
                <Form.Item label="Child's full name" style={{display: this.state.pregnant_child}}>
										{getFieldDecorator('child_fullname_pregnant', {
											validateTrigger: ["onChange", "onBlur"],
											rules: [
												{
													required: this.state.child_pregnant_required,
													message: 'Please enter child\'s full name',
												},
											],
										})(<Input placeholder=""  />)}
									</Form.Item>
								</Col>
								<Col span={12}>
                <Form.Item label="Gender">
										{getFieldDecorator('gender_pregnant', {
											validateTrigger: ["onChange", "onBlur"],
											rules: [
												{
													required: this.state.child_pregnant_required,
													message: 'Please select',
												},
											],
										})(
										<Select style={{ width: '100%', marginRight: 8 }} placeholder="Please select">
											<Select.Option value="Boy">Boy</Select.Option>
											<Select.Option value="Girl">Girl</Select.Option>
										</Select>
										)}
									</Form.Item>
								</Col>
							</Row>
							<Row gutter={[16, 0]} style={{display: this.state.pregnant_child}}>
								<Col span={12}>
                <Form.Item label="Child's birthdate">
										{getFieldDecorator('child_birthdate_pregnant', {
											rules: [
												{
													required: this.state.child_pregnant_required,
													message: 'Please enter child\'s date',
												},
											],
										})(<DatePicker format={"DD/MM/YYYY"} />)}
									</Form.Item>

								</Col>
                <Col span={12} >
                <Form.Item label="Labour">
										{getFieldDecorator('labour_pregnant', {
											validateTrigger: ["onBlur"],
											rules: [
												{
													required: this.state.child_pregnant_required,
													message: 'Please enter value',
												},
											],
										})(
										 <Radio.Group>
											<Radio value={"Naturally"}>Naturally</Radio>
											<Radio value={"C-Section"}>C-Section</Radio>
										  </Radio.Group>

										)}
									</Form.Item>
                </Col>
							</Row>
              <Row gutter={[16, 0]}>
                <Col span={12}>
                  {/* <Form.Item label='Do you have other children?'>
                    {getFieldDecorator("have_other_children", {
                      initialValue: detail.have_other_children,
                      validateTrigger: ["onChange", "onBlur"],
                      rules: [
                        {
                          required: true,
                          message: "Please select",
                        },
                      ],
                    })(
                      <Select
                        style={{ width: "100%", marginRight: 8 }}
                        dropdownClassName='have_other_children_clz'
                        onChange={this.handleHaveOtherChildChange}
                        placeholder='Please select'
                      >
                        <Select.Option value='No'>No</Select.Option>
                        <Select.Option value='Yes'>
                          Yes, I have other children.
                        </Select.Option>
                      </Select>
                    )}
                  </Form.Item> */}
                </Col>
                {/* <Col span={12}>
                  <Form.Item
                    label="Child's full name"
                    style={{ display: this.state.child_hidden }}
                  >
                    {getFieldDecorator("child_fullname", {
                      validateTrigger: ["onChange", "onBlur"],
                      rules: [
                        {
                          required: this.state.child_required,
                          message: "Please enter child's full name",
                        },
                      ],
                    })(<Input placeholder='' />)}
                  </Form.Item>
                </Col> */}
              </Row>
              <Row gutter={[16, 0]}>
                {/* <Col span={12}>
                  <Form.Item
                    label='Gender'
                    style={{ display: this.state.child_hidden }}
                  >
                    {getFieldDecorator("gender", {
                      validateTrigger: ["onChange", "onBlur"],
                      rules: [
                        {
                          required: this.state.child_required,
                          message: "Please select",
                        },
                      ],
                    })(
                      <Select
                        style={{ width: "100%", marginRight: 8 }}
                        placeholder='Please select'
                      >
                        <Select.Option value='Boy'>Boy</Select.Option>
                        <Select.Option value='Girl'>Girl</Select.Option>
                      </Select>
                    )}
                  </Form.Item>
                </Col> */}
                {/* <Col span={12}>
                  <Form.Item
                    label="Child's birthdate"
                    style={{ display: this.state.child_hidden }}
                  >
                    {getFieldDecorator("child_birthdate", {
                      rules: [
                        {
                          required: this.state.child_required,
                          message: "Please enter child's date",
                        },
                      ],
                    })(<DatePicker format={"DD/MM/YYYY"} />)}
                  </Form.Item>
                </Col> */}
              </Row>
              <Row gutter={[16, 0]}>
                {/* <Col span={12} style={{display: this.state.child_hidden}}>
									<Form.Item label="Labour">
										{getFieldDecorator('labour', {
											validateTrigger: ["onBlur"],
											rules: [
												{
													required: this.state.child_required,
													message: 'Please enter value',
												},
											],
										})(
										 <Radio.Group>
											<Radio value={"Naturally"}>Naturally</Radio>
											<Radio value={"C-Section"}>C-Section</Radio>
										  </Radio.Group>
										)}
									</Form.Item>
								</Col> */}
                <Col span={12} >
                  <Form.Item label='Product using'>
                    {getFieldDecorator("danone_brands_pregnant", {
                      initialValue: detail.danone_brands_pregnant,
                      validateTrigger: ["onChange", "onBlur"],
                      rules: [
                        {
                          required: this.state.child_pregnant_required,
                          message: "Please select",
                        },
                      ],
                    })(
                      <Select
                        style={{ width: "100%", marginRight: 8 }}
                        placeholder='Please select'
                      >
                        <Select.Option value="Dumex SuperGold Mama">Dumex SuperGold Mama</Select.Option>
                        <Select.Option value="NutriMum">NutriMum</Select.Option>
                        <Select.Option value="Similac Mum">Similac Mum</Select.Option>
                        <Select.Option value="Leben Mum">Leben Mum</Select.Option>
                        <Select.Option value="XO Mum">XO Mum</Select.Option>
                        <Select.Option value="Vitamin and Supplements">Vitamin and Supplements</Select.Option>
                        <Select.Option value="Freshmilk">Freshmilk</Select.Option>

                        <Select.Option value="-">-</Select.Option>

                        <Select.Option value="Aptamil 1">Aptamil 1</Select.Option>
                        <Select.Option value="Aptamil 2">Aptamil 2</Select.Option>
                        <Select.Option value="Aptakid 3">Aptakid 3</Select.Option>
                        <Select.Option value="Dupro 1">Dupro 1</Select.Option>
                        <Select.Option value="Dupro 2">Dupro 2</Select.Option>
                        <Select.Option value="Dugro 3">Dugro 3</Select.Option>
                        <Select.Option value="Dugro All N One (UHT)">Dugro All N One (UHT)</Select.Option>
                        <Select.Option value="Dulac 1">Dulac 1</Select.Option>
                        <Select.Option value="Dulac 2">Dulac 2</Select.Option>
                        <Select.Option value="Dumilk 3">Dumilk 3</Select.Option>
                        <Select.Option value="Dumex SuperGold Step 1">Dumex SuperGold Step 1</Select.Option>
                        <Select.Option value="Dumex SuperGold Step 2">Dumex SuperGold Step 2</Select.Option>
                        <Select.Option value="Dumex SuperGold Kid 3">Dumex SuperGold Kid 3</Select.Option>
                        <Select.Option value="Nutrilatt 1">Nutrilatt 1</Select.Option>
                        <Select.Option value="Nutrilatt 2">Nutrilatt 2</Select.Option>
                        <Select.Option value="Nutrilatt 3">Nutrilatt 3</Select.Option>
                        <Select.Option value="Nutrilatt 4">Nutrilatt 4</Select.Option>
                        <Select.Option value="Nutrigold 1">Nutrigold 1</Select.Option>
                        <Select.Option value="Nutrigold 2">Nutrigold 2</Select.Option>
                        <Select.Option value="Nutrigold 3">Nutrigold 3</Select.Option>
                        <Select.Option value="Nutrigold 4">Nutrigold 4</Select.Option>
                        <Select.Option value="Physiolac 1">Physiolac 1</Select.Option>
                        <Select.Option value="Physiolac 2">Physiolac 2</Select.Option>
                        <Select.Option value="Physiolac 3">Physiolac 3</Select.Option>
                        <Select.Option value="Similac Step 1">Similac Step 1</Select.Option>
                        <Select.Option value="Similac Step 2">Similac Step 2</Select.Option>
                        <Select.Option value="Similac Step 3">Similac Step 3</Select.Option>
                        <Select.Option value="Gain IG 3">Gain IG 3</Select.Option>
                        <Select.Option value="Gain IG 4">Gain IG 4</Select.Option>
                        <Select.Option value="Total Comfort Step 1">Total Comfort Step 1</Select.Option>
                        <Select.Option value="Total Comfort Step 2">Total Comfort Step 2</Select.Option>
                        <Select.Option value="Pediasure">Pediasure</Select.Option>
                        <Select.Option value="Pediasure Liquid">Pediasure Liquid</Select.Option>
                        <Select.Option value="Leben 1">Leben 1</Select.Option>
                        <Select.Option value="Leben 2">Leben 2</Select.Option>
                        <Select.Option value="Leben 3">Leben 3</Select.Option>
                        <Select.Option value="XO 1">XO 1</Select.Option>
                        <Select.Option value="XO 2">XO 2</Select.Option>
                        <Select.Option value="XO 3">XO 3</Select.Option>
                        <Select.Option value="XO 4">XO 4</Select.Option>
                        <Select.Option value="XO Kid 5">XO Kid 5</Select.Option>
                        <Select.Option value="Guigoz 1">Guigoz 1</Select.Option>
                        <Select.Option value="Guigoz 2">Guigoz 2</Select.Option>
                        <Select.Option value="Guigoz 3">Guigoz 3</Select.Option>
                        <Select.Option value="Lactogen 1">Lactogen 1</Select.Option>
                        <Select.Option value="Lactogen 2">Lactogen 2</Select.Option>
                        <Select.Option value="Lactogen 3">Lactogen 3</Select.Option>
                        <Select.Option value="Lactogrow UHT">Lactogrow UHT</Select.Option>
                        <Select.Option value="Stargrow 1">Stargrow 1</Select.Option>
                        <Select.Option value="Stargrow 2">Stargrow 2</Select.Option>
                        <Select.Option value="Stargrow 3">Stargrow 3</Select.Option>
                        <Select.Option value="France BeBe 1">France BeBe 1</Select.Option>
                        <Select.Option value="France BeBe 2">France BeBe 2</Select.Option>
                        <Select.Option value="France BeBe 3">France BeBe 3</Select.Option>
                        <Select.Option value="France BeBe 4">France BeBe 4</Select.Option>
                        <Select.Option value="Breast Feeding">Breast Feeding</Select.Option>
                        <Select.Option value="Freshmilk">Freshmilk</Select.Option>
                        <Select.Option value="Other Brands">Other Brands</Select.Option>
                        <Select.Option value="Not Use">Not Use</Select.Option>
                      </Select>
                    )}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label='Status'>
                    {getFieldDecorator("status", {
                      validateTrigger: ["onChange", "onBlur"],
                      rules: [
                        {
                          required: true,
                          message: "Please enter value",
                        },
                      ],
                    })(
                      <Select
                        style={{ width: "100%", marginRight: 8 }}
                        onChange={this.handleIsPregnantChange}
                        placeholder='Please select'
                      >
                        <Select.Option value='1'>Active</Select.Option>
                        <Select.Option value='0'>InActive</Select.Option>
                      </Select>
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[16, 0]}>
                <Col span={12}>
                  <Form.Item label='Opt in Channel*'>
                    {getFieldDecorator("opt_in")(
                      <Checkbox.Group
                        style={{ width: "100%" }}
                        onChange={this.handleOptInChange}
                      >
                        {optionItems}
                      </Checkbox.Group>
                    )}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label='Status'>
                    {getFieldDecorator("status_admin")(
                      <Checkbox.Group style={{ width: "100%" }}>
                        {statusAdminItems}
                      </Checkbox.Group>
                    )}
                  </Form.Item>
                </Col>
              </Row>

              <div
                style={{
                  position: "absolute",
                  right: 0,
                  bottom: 0,
                  width: "100%",
                  textAlign: "left",
                  zIndex: 2,
                }}
              >
                <div
                  className='text-right'
                  style={{
                    paddingBottom: 10,
                    paddingRight: 16,
                    paddingLeft: 16,
                    paddingTop: 16,
                    background: "rgba(255, 255, 255, 0.75)",
                  }}
                >
                  <Text className=''>Please complete all required field*</Text>
                </div>
                <Row
                  style={{
                    width: "100%",
                    background: "#fff",
                    padding: "10px 16px",
                    borderTop: "1px solid #e9e9e9",
                  }}
                >
                  <Col span={12}>
                    <Button
                      onClick={this.onClose}
                      style={{ marginRight: 8 }}
                      className='close-btn'
                    >
                      Close
                    </Button>
                  </Col>
                  <Col span={12} className='text-right'>
                    <Button
                      disabled={deleteButton}
                      style={{
                        marginRight: 8,
                      }}
                      onClick={this.showDeleteModal}
                    >
                      <i className='fa fa-close' style={{ marginRight: 5 }}></i>
                      Delete
                    </Button>
                    <Button
                      disabled={saveButton ? false : true}
                      type='primary'
                      htmlType='submit'
                      className='primary-action-btn'
                    >
                      Save
                    </Button>
                  </Col>
                </Row>
              </div>
            </Form>
          </Drawer>
        </div>
        <Modal
          visible={deleteModalVisible}
          width={400}
          closable={false}
          footer={[
            <Button
              key='submit'
              loading={deleteLoading}
              onClick={this.handleDeleteModalOk}
            >
              Yes, Delete
            </Button>,
            <Button
              key='back'
              type='primary'
              onClick={this.handleDeleteModalCancel}
              style={{ minWidth: 120 }}
            >
              No
            </Button>,
          ]}
        >
          <div className='text-center'>
            <p>Do you want to delete this item?</p>
          </div>
        </Modal>
      </div>
    );
  }
}

export default Form.create({ name: "register" })(App);
