import React from "react";
import {
  Modal,
  Icon,
  Typography,
  Table,
  Button,
  Row,
  Checkbox,
  Col,
  Input,
  Drawer,
  Form,
  Radio,
  Upload,
  message,
} from "antd";
import "antd/dist/antd.css";
import "./home_banner.css";
import reqwest from "reqwest";

import Dotdotdot from "react-dotdotdot";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import dragula from "dragula";
import "dragula/dist/dragula.css";
import moment from "moment";
import { Tabs } from "antd";

const { TabPane } = Tabs;
const path = require("path");
const image2base64 = require("image-to-base64");
const { Search } = Input;
const { Text, Title } = Typography;

var searchTimeoutId = false;

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
}

function beforeUpload(file) {
  const isJpgOrPng =
    file.type === "image/jpeg" ||
    file.type === "image/png" ||
    file.type === "image/webp";
  if (!isJpgOrPng) {
    message.error("You can only upload JPG/PNG/webp file!");
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error("Image must smaller than 2MB!");
  }
  return isJpgOrPng && isLt2M;
}

function validateImage(file) {
  const isJpgOrPng =
    file.type === "image/jpeg" ||
    file.type === "image/png" ||
    file.type === "image/webp";
  if (!isJpgOrPng) {
    message.error("You can only upload JPG/PNG/webp file!");
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error("Image must smaller than 2MB!");
  }
  return isJpgOrPng && isLt2M;
}

function uploadImage(file, cb) {
  let reader = new FileReader();
  reader.readAsDataURL(file);

  reader.onload = function () {
    var b64 = reader.result;
    reqwest({
      url: process.env.REACT_APP_API + "/image/save",
      method: "post",
      data: {
        image: b64,
      },
      type: "json",
    }).then((data) => {
      cb(data.src);
    });
  };

  reader.onerror = function (error) {};

  return false;
}

const fontSizeArr = [
  "8px",
  "9px",
  "10px",
  "12px",
  "14px",
  "16px",
  "20px",
  "24px",
  "32px",
  "42px",
  "54px",
  "68px",
  "84px",
  "98px",
];

var Size = ReactQuill.Quill.import("attributors/style/size");
Size.whitelist = fontSizeArr; // No need to use `this` here
ReactQuill.Quill.register(Size, true);

class App extends React.Component {
  state = {
    data: [],
    key: false,
    loading: false,
    visible: false,
    image_banner_desktop: false,
    image_banner_mobile: false,
    image_thumbnail: false,
    imageBase64: "",
    saveButton: false,
    deleteModalVisible: false,
    deleteLoading: false,
    deleteButton: false,
    searchValue: "",
    detail: { title: ["", ""] },
  };

  constructor(props) {
    super(props);
    this.columns = [
      {
        title: "Sort",
        dataIndex: "display_index",
        key: "display_index",
        render: () => (
          <Icon
            type="menu"
            className="draggable"
            style={{
              fontSize: "13pt",
              fontWeight: "bolder",
              cursor: "pointer",
            }}
          />
        ),
      },
      {
        title: "Product Name",
        dataIndex: "name",
        key: "name",
        render: (data) => (
          <div>
            <Dotdotdot clamp={1}>
              <Text>{data}</Text>
            </Dotdotdot>
          </div>
        ),
      },
      {
        title: "Created Date",
        dataIndex: "created_time",
        key: "created_time",
        width: 200,
        render: (data) => (
          <div>
            <Text style={{ wordWrap: "break-word" }}>
              {moment(data).format("DD/MM/YYYY")}
            </Text>
          </div>
        ),
      },
      {
        title: "Published",
        dataIndex: "status",
        key: "published",
        width: 100,
        align: "center",
        render: (status) =>
          "" + status === "1" ? (
            <i className="fa fa-check" style={{ fontSize: 15 }}></i>
          ) : (
            <i className="fa fa-times" style={{ fontSize: 15 }}></i>
          ),
      },
      {
        title: "",
        dataIndex: "key",
        key: "key",
        width: 50,
        render: (key) => (
          <Button
            onClick={() => {
              this.showDrawer(key);
            }}
            className="icon-btn"
          >
            <Icon type="setting" />
          </Button>
        ),
      },
    ];
  }

  componentDidMount() {
    document.title = "Product";

    this.fetch();

    const container = document.querySelector(".ant-table-tbody");
    const drake = dragula([container], {
      moves: (el) => {
        this.start = this.getIndexInParent(el);
        return true;
      },
    });

    drake.on("drop", (el) => {
      this.end = this.getIndexInParent(el);
      this.handleReorder(this.start, this.end);
    });
  }

  getIndexInParent = (el) => {
    return Array.from(el.parentNode.children).indexOf(el);
  };

  showDrawer = async (key) => {
    var data = {};
    console.log(key);
    if (key === -1) {
      data = {
        id: "",
        name: "",
        desc: "",
        sub_title: "",
        status: "1",
        btn_status: "1",
        url: "",
        information: "",
        information_kh: "",
        preparation: "",
        nutrition_info: "",
        img: "",
        link_video: "",
      };
      this.setState({
        image_banner_desktop: null,
        image_banner_mobile: null,
        image_thumbnail: null,
      });
    } else {
      for (var i = 0; i < this.state.data.length; i++) {
        if (this.state.data[i].id === key) {
          data = this.state.data[i];
          break;
        }
      }
    }
    console.log(data);

    data.status = "" + data.status;

    data.btn_status = "" + data.btn_status;

    data.type = "" + data.type;
    console.log("type : ", data.type);

    this.props.form.setFieldsValue(data);

    this.setState({
      visible: true,
      detail: data, // Remove the curly braces around data
      saveButton: true,
      deleteButton: key === -1 ? false : true,
    });

    if (data.img) {
      image2base64(data.img) // you can also to use url
        .then((response) => {
          var b64 = "data:image/";
          var ext = path.extname(data.img);
          if (ext === ".jpg" || ext === ".jpeg") {
            b64 += "jpeg;base64," + response;
          } else if (ext === ".png") {
            b64 += "png;base64," + response;
          }
          if (b64 !== "data:image/") {
            this.props.form.setFieldsValue({
              image_desktop: b64,
              image_mobile: b64,
              image_thumbnail: b64,
            });

            this.setState({
              image_banner_desktop: b64,
              image_banner_mobile: b64,
              image_thumbnail: b64,
            });
          }
        })
        .catch((error) => {
          //Exepection error....
        });
    }
    this.props.form.setFieldsValue({
      image_desktop:
        data.image_product_banner_desktop !== ""
          ? data.image_product_banner_desktop
          : null,
      image_mobile:
        data.image_product_banner_mobile !== ""
          ? data.image_product_banner_mobile
          : null,
      image_thumbnail: data.image_product !== "" ? data.image_product : null,
    });

    this.setState({
      image_banner_desktop:
        data.image_product_banner_desktop !== ""
          ? data.image_product_banner_desktop
          : null,
      image_banner_mobile:
        data.image_product_banner_mobile !== ""
          ? data.image_product_banner_mobile
          : null,
      image_thumbnail: data.image_product !== "" ? data.image_product : null,
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
      detail: [],
      image_banner_desktop: null,
      image_banner_mobile: null,
      image_thumbnail: null,
      image_desktop: null,
      image_mobile: null,
    });
  };

  onSave = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        console.log(values);
        this.setState({ saveButton: false });

        values.information_use = values.information_use ? 1 : 0;
        values.preparation_use = values.preparation_use ? 1 : 0;
        values.nutrition_use = values.nutrition_use ? 1 : 0;
        if (
          values.image_desktop &&
          values.image_mobile &&
          values.image_thumbnail
        ) {
          reqwest({
            url: process.env.REACT_APP_API + "/product/save",
            method: "post",
            data: values,
            type: "json",
          }).then((data) => {
            if (data.success) {
              this.setState({
                visible: false,
                image_banner_desktop: null,
                image_banner_mobile: null,
                image_thumbnail: null,
              });
              this.fetch();
              message.success('Success.')
            } else {
              this.setState({
                visible: true,
                image_banner_desktop: null,
                image_banner_mobile: null,
                image_thumbnail: null,
              });
            }
          });
        } else {
          message.error('Please Add Image.')
        }
      }
    });
  };

  handleChangeDesktop = (info) => {
    if (info.file.status === "uploading") {
      this.setState({ loading: true });
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (imageUrl) => {
        this.props.form.setFieldsValue({
          image_desktop: imageUrl,
        });
        this.setState({
          image_banner_desktop: imageUrl,
          loading: false,
        });
      });
    }
  };

  handleChangeMobile = (info) => {
    if (info.file.status === "uploading") {
      this.setState({ loading: true });
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (imageUrl) => {
        this.props.form.setFieldsValue({
          image_mobile: imageUrl,
        });
        this.setState({
          image_banner_mobile: imageUrl,
          loading: false,
        });
      });
    }
  };

  handleChangeThumbnail = (info) => {
    if (info.file.status === "uploading") {
      this.setState({ loading: true });
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (imageUrl) => {
        this.props.form.setFieldsValue({
          image_thumbnail: imageUrl,
        });
        this.setState({
          image_thumbnail: imageUrl,
          loading: false,
        });
      });
    }
  };

  handleSearchChange(value) {
    this.setState({ searchValue: value });
    if (searchTimeoutId) {
      window.clearTimeout(searchTimeoutId);
    }
    searchTimeoutId = window.setTimeout(() => {
      this.fetch();
    }, 1500);
  }

  fetch = (params = {}) => {
    this.setState({ loading: true });
    reqwest({
      url: process.env.REACT_APP_API + "/product",
      method: "get",
      data: {
        search: this.state.searchValue,
        ...params,
      },
      type: "json",
    }).then((data) => {
      this.setState({
        loading: false,
        data: data.results,
      });
      console.log(data);
      document.body.style.overflow = null;
    });
  };

  showDeleteModal = () => {
    this.setState({
      deleteModalVisible: true,
    });
  };

  handleDeleteModalCancel = () => {
    this.setState({ deleteModalVisible: false });
  };

  handleDeleteModalOk = () => {
    var detail = this.state.detail;
    this.setState({ deleteLoading: true });

    reqwest({
      url: process.env.REACT_APP_API + "/product/delete",
      method: "post",
      data: {
        id: detail.id,
      },
      type: "json",
    }).then(() => {
      this.setState({
        deleteLoading: false,
        deleteModalVisible: false,
        visible: false,
      });
      this.fetch();
    });
  };

  handleReorder = (dragIndex, draggedIndex) => {
    const data = [...this.state.data];
    const item = data.splice(dragIndex, 1)[0];
    data.splice(draggedIndex, 0, item);
    this.setState({
      data,
    });

    var postBody = [];
    for (var i = 0; i < data.length; i++) {
      postBody.push({
        key: data[i].id,
        display_order: i + 1,
      });
    }

    reqwest({
      url: process.env.REACT_APP_API + "/product/reorder",
      method: "post",
      data: {
        v: postBody,
      },
      type: "json",
    }).then((res) => {
      console.log("reorder are done : ", res);
    });
  };

  modules = {
    toolbar: {
      container: [
        ["bold", "italic", "underline", "strike"], // toggled buttons
        [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        // [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
        [{ size: Size.whitelist }],

        // [{ 'font': Font.whitelist }],
        [{ align: [] }],
        ["link", "image", "video"],

        [{ list: "ordered" }, { list: "bullet" }],
        [{ script: "sub" }, { script: "super" }], // superscript/subscript
        [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
        // [{ 'direction': 'rtl' }],                         // text direction

        ["clean"], // remove formatting button
      ],
      handlers: {
        image: this.imageHandler,
      },
    },
  };

  imageHandler() {
    const input = document.createElement("input");

    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    input.onchange = async () => {
      const file = input.files[0];
      if (!validateImage(file)) {
        return false;
      }
      const formData = new FormData();

      formData.append("image", file);

      // Save current cursor state
      const range = this.quill.getSelection(true);

      // Insert temporary loading placeholder image
      // this.quill.insertEmbed(range.index, 'image', `${window.location.origin}/images/loaders/placeholder.gif`);

      // Move cursor to right side of image (easier to continue typing)
      this.quill.setSelection(range.index + 1);

      uploadImage(file, (cb) => {
        this.quill.insertEmbed(range.index, "image", cb);
      });

      // Remove placeholder image
      // this.quill.deleteText(range.index, 1);

      // Insert uploaded image
      // this.quill.insertEmbed(range.index, 'image', res.body.image);
      // this.quill.insertEmbed(range.index, 'image', res);
    };
  }

  getBase64 = (file, cb) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result);
    };
    reader.onerror = function (error) {};
  };

  render() {
    const uploadButton = (
      <div>
        <Icon type={this.state.loading ? "loading" : "plus"} />
        <div className="ant-upload-text">Upload</div>
      </div>
    );
    const {
      saveButton,
      deleteButton,
      deleteModalVisible,
      deleteLoading,
      image_banner_desktop,
      image_banner_mobile,
      image_thumbnail,
      searchValue,
      detail,
    } = this.state;
    const { getFieldDecorator } = this.props.form;

    return (
      <div id="home-banner-component">
        <Drawer
          id="home-banner-component-drawer"
          title="PRODUCT DETAIL"
          placement="right"
          closable={false}
          width={730}
          onClose={this.onClose}
          visible={this.state.visible}
          bodyStyle={{ paddingBottom: 80 }}
        >
          <Form onSubmit={this.onSave} layout={"vertical"}>
            <Form.Item label="" style={{ display: "none" }}>
              {getFieldDecorator("id", {
                initialValue: "",
              })(<Input />)}
            </Form.Item>
            <Form.Item label="Product Name*">
              {getFieldDecorator("name", {
                initialValue: detail.name,
                rules: [
                  { required: true, message: "Please input Product Name!" },
                ],
              })(<Input placeholder="" maxLength={1000} />)}
            </Form.Item>
            <Form.Item label="Product Subtitle*">
              {getFieldDecorator("sub_title", {
                initialValue: detail.sub_title,
                rules: [
                  { required: true, message: "Please input Product Subtitle!" },
                ],
              })(<Input placeholder="" maxLength={1000} />)}
            </Form.Item>
            <Form.Item label="Product Description*">
              {getFieldDecorator("desc", {
                initialValue: detail.desc,
                rules: [
                  {
                    required: true,
                    message: "Please input Product Description!",
                  },
                ],
              })(<Input placeholder="" maxLength={1000} />)}
            </Form.Item>
            <Form.Item label="link video*">
              {getFieldDecorator("link_video", {
                initialValue: detail.link_video,
                rules: [
                  {
                    required: true,
                    message: "Please input link youtube!",
                  },
                ],
              })(<Input placeholder="" maxLength={1000} />)}
            </Form.Item>
            {/* <Form.Item label="Product Category*">
							{getFieldDecorator('type', {
								initialValue: detail.type,
								rules: [{ required: true, message: 'Please input Product Description!' }],
							})(
								<Select style={{ width: '100%', marginRight: 8 }} placeholder="Please select">
									<Select.Option value="1">Dugro</Select.Option>
									<Select.Option value="2">Supergold</Select.Option>
								</Select>
							)}
						</Form.Item> */}
            <Row>
              <Col span={12}>
                <Form.Item>
                  <div>Product Banner Desktop </div>
                  <Upload
                    accept=".png, .jpg .webp"
                    name="Upload"
                    listType="picture-card"
                    showUploadList={false}
                    action={process.env.REACT_APP_API + "/image/test/upload"}
                    beforeUpload={beforeUpload}
                    onChange={this.handleChangeDesktop}
                  >
                    {image_banner_desktop ? (
                      <img
                        src={image_banner_desktop}
                        alt="Upload"
                        style={{ width: "100%" }}
                      />
                    ) : (
                      uploadButton
                    )}
                  </Upload>

                  {getFieldDecorator("image_desktop")(<Input type="hidden" />)}
                  <div>
                    <Text>1600 x 800</Text>
                  </div>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item>
                  <div>Product Banner Mobile </div>
                  <Upload
                    accept=".png, .jpg .webp"
                    name="Upload"
                    listType="picture-card"
                    showUploadList={false}
                    action={process.env.REACT_APP_API + "/image/test/upload"}
                    beforeUpload={beforeUpload}
                    onChange={this.handleChangeMobile}
                  >
                    {image_banner_mobile ? (
                      <img
                        src={image_banner_mobile}
                        alt="Upload"
                        style={{ width: "100%" }}
                      />
                    ) : (
                      uploadButton
                    )}
                  </Upload>

                  {getFieldDecorator("image_mobile")(<Input type="hidden" />)}
                  <div>
                    <Text>800 x 600</Text>
                  </div>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item>
                  <div>Product thumbnail</div>
                  <Upload
                    accept=".png, .jpg .webp"
                    name="Upload"
                    listType="picture-card"
                    showUploadList={false}
                    action={process.env.REACT_APP_API + "/image/test/upload"}
                    beforeUpload={beforeUpload}
                    onChange={this.handleChangeThumbnail}
                  >
                    {image_thumbnail ? (
                      <img
                        src={image_thumbnail}
                        alt="Upload"
                        style={{ width: "100%" }}
                      />
                    ) : (
                      uploadButton
                    )}
                  </Upload>

                  {getFieldDecorator("image_thumbnail")(
                    <Input type="hidden" />
                  )}
                  <div>
                    <Text>300 x 200</Text>
                  </div>
                </Form.Item>
              </Col>
            </Row>
            <Tabs defaultActiveKey="1">
              <TabPane tab="Product Information" key="1">
                {this.props.form.getFieldsValue().type === "1" ? (
                  <div>
                    {getFieldDecorator("information_use", {
                      initialValue: detail.information_use,
                      valuePropName: "checked",
                    })(
                      <Checkbox>
                        <strong>Use this section</strong>
                      </Checkbox>
                    )}
                    {/* <span style={{marginTop : '10px', display: 'block'}}><strong>Description EN</strong></span>
								{getFieldDecorator('information', {
									initialValue: detail.information,
								})(
									<ReactQuill id="product-information" modules={this.modules}  />
								)} */}
                    {getFieldDecorator("information", {
                      initialValue: detail.information,
                    })}

                    <span style={{ marginTop: "10px", display: "block" }}>
                      <strong>Description</strong>{" "}
                      {"( Recommended size of image 1600 × 800 )"}
                    </span>
                    {getFieldDecorator("information_kh", {
                      initialValue: detail.information_kh
                        ? detail.information_kh
                        : "",
                    })(
                      <ReactQuill
                        id="product-information-kh"
                        modules={this.modules}
                      />
                    )}
                  </div>
                ) : (
                  <div>
                    <div>
                      {getFieldDecorator("information_use", {
                        initialValue: detail.information_use,
                        valuePropName: "checked",
                      })(
                        <Checkbox>
                          <strong>Use this section</strong>
                        </Checkbox>
                      )}
                      <span style={{ marginTop: "10px", display: "block" }}>
                        <strong>Description top</strong>{" "}
                        {"( Recommended size of image 1600 × 800 )"}
                      </span>
                      {getFieldDecorator("information_top", {
                        initialValue: detail.information_top
                          ? detail.information_top
                          : "",
                      })(
                        <ReactQuill
                          id="product-information-kh"
                          modules={this.modules}
                        />
                      )}
                    </div>
                    <div>
                      <span style={{ marginTop: "10px", display: "block" }}>
                        <strong>Description middle</strong>{" "}
                        {"( Recommended size of image 800 × 800 )"}
                      </span>
                      {getFieldDecorator("information_mid", {
                        initialValue: detail.information_mid
                          ? detail.information_mid
                          : "",
                      })(
                        <ReactQuill
                          id="product-information-kh"
                          modules={this.modules}
                        />
                      )}
                    </div>
                    <div>
                      <span style={{ marginTop: "10px", display: "block" }}>
                        <strong>Description bottom</strong>{" "}
                        {"( Recommended size 800 × 800 )"}
                      </span>
                      {getFieldDecorator("information_bottom", {
                        initialValue: detail.information_bottom
                          ? detail.information_bottom
                          : "",
                      })(
                        <ReactQuill
                          id="product-information-kh"
                          modules={this.modules}
                        />
                      )}
                    </div>
                  </div>
                )}
              </TabPane>
              <TabPane tab="Nutrition Information" key="2">
                {getFieldDecorator("nutrition_use", {
                  initialValue: detail.nutrition_use,
                  valuePropName: "checked",
                })(
                  <Checkbox>
                    <strong>Use this section</strong>
                  </Checkbox>
                )}

                <span style={{ marginTop: "10px", display: "block" }}>
                  <strong>Nutrition Information</strong>
                </span>
                {getFieldDecorator("nutrition_info", {
                  initialValue: detail.nutrition_info
                    ? detail.nutrition_info
                    : "",
                })(
                  <ReactQuill
                    id="product-nutrition_info"
                    modules={this.modules}
                  />
                )}

                <span style={{ marginTop: "10px", display: "block" }}>
                  <strong>Ingredients</strong>
                </span>
                {getFieldDecorator("ingredients", {
                  initialValue: detail.ingredients ? detail.ingredients : "",
                })(
                  <ReactQuill id="product-ingredients" modules={this.modules} />
                )}
              </TabPane>
              <TabPane tab="Product Usage" key="3">
                {getFieldDecorator("preparation_use", {
                  initialValue: detail.preparation_use,
                  valuePropName: "checked",
                })(
                  <Checkbox>
                    <strong>Use this section</strong>
                  </Checkbox>
                )}

                <span style={{ marginTop: "10px", display: "block" }}>
                  <strong>Feeding Guide</strong>
                </span>
                {getFieldDecorator("feeding_guide", {
                  initialValue: detail.feeding_guide
                    ? detail.feeding_guide
                    : "",
                })(
                  <ReactQuill
                    id="product-feeding-guide"
                    modules={this.modules}
                  />
                )}

                <span style={{ marginTop: "10px", display: "block" }}>
                  <strong>Preparation Instructions</strong>
                </span>
                {getFieldDecorator("preparation", {
                  initialValue: detail.preparation ? detail.preparation : "",
                })(
                  <ReactQuill id="product-preparation" modules={this.modules} />
                )}
              </TabPane>
              <TabPane tab="Natural Defences & Brain Development" key="4">
                {getFieldDecorator("defences_use", {
                  initialValue: detail.defences_use,
                  valuePropName: "checked",
                })(
                  <Checkbox>
                    <strong>Use this section</strong>
                  </Checkbox>
                )}

                <span style={{ marginTop: "10px", display: "block" }}>
                  <strong>Natural Defences</strong>
                </span>
                {getFieldDecorator("natural_defences", {
                  initialValue: detail.natural_defences
                    ? detail.natural_defences
                    : "",
                })(
                  <ReactQuill
                    id="product-natural-defences"
                    modules={this.modules}
                  />
                )}

                <span style={{ marginTop: "10px", display: "block" }}>
                  <strong>Brain Development</strong>
                </span>
                {getFieldDecorator("brain_development", {
                  initialValue: detail.brain_development
                    ? detail.brain_development
                    : "",
                })(
                  <ReactQuill
                    id="product-brain-development"
                    modules={this.modules}
                  />
                )}
              </TabPane>
            </Tabs>

            <Form.Item label="Type of Product*">
              {getFieldDecorator("type", {
                validateTrigger: ["onChange", "onBlur"],
                initialValue: "1",
                rules: [
                  { required: true, message: "Please input your status!" },
                ],
              })(
                <Radio.Group>
                  <Radio.Button value="1">Product</Radio.Button>
                  <Radio.Button value="0">Brand Product</Radio.Button>
                </Radio.Group>
              )}
            </Form.Item>
            <Form.Item label="Publish Status*">
              {getFieldDecorator("status", {
                validateTrigger: ["onChange", "onBlur"],
                initialValue: "1",
                rules: [
                  { required: true, message: "Please input your status!" },
                ],
              })(
                <Radio.Group>
                  <Radio.Button value="1">
                    <i className="fa fa-check" style={{ marginRight: 5 }}></i>
                    Active
                  </Radio.Button>
                  <Radio.Button value="0">
                    <i className="fa fa-close" style={{ marginRight: 5 }}></i>
                    Inactive
                  </Radio.Button>
                </Radio.Group>
              )}
            </Form.Item>

            <Form.Item label="Button Buy Now*">
              {getFieldDecorator("btn_status", {
                validateTrigger: ["onChange", "onBlur"],
                initialValue: "1",
                rules: [
                  { required: true, message: "Please input your status!" },
                ],
              })(
                <Radio.Group>
                  <Radio.Button value="1">
                    <i className="fa fa-check" style={{ marginRight: 5 }}></i>
                    Show
                  </Radio.Button>
                  <Radio.Button value="0">
                    <i className="fa fa-close" style={{ marginRight: 5 }}></i>
                    hidden
                  </Radio.Button>
                </Radio.Group>
              )}
            </Form.Item>

            <Form.Item label="Url Buy Now Button*">
              {getFieldDecorator("url", {
                initialValue: detail.url,
              })(<Input placeholder="" maxLength={1000} />)}
            </Form.Item>

            <div
              style={{
                position: "absolute",
                bottom: 0,
                width: "100%",
                borderTop: "1px solid #e8e8e8",
                padding: "10px 16px",
                left: 0,
                background: "#fff",
                borderRadius: "0 0 4px 4px",
                zIndex: 2,
              }}
            >
              <Row>
                <Col span={12}>
                  <Button
                    onClick={this.onClose}
                    style={{ background: "#EDEDED" }}
                  >
                    Close
                  </Button>
                </Col>
                <Col span={12} className="text-right">
                  <Button
                    style={{
                      marginRight: 8,
                    }}
                    disabled={deleteButton ? false : true}
                    onClick={this.showDeleteModal}
                  >
                    <i className="fa fa-close" style={{ marginRight: 5 }}></i>
                    Delete
                  </Button>
                  <Button
                    disabled={saveButton ? false : true}
                    htmlType="submit"
                    type="primary"
                    onClick={console.log("click")}
                  >
                    Save
                  </Button>
                </Col>
              </Row>
            </div>
          </Form>
        </Drawer>
        <Row>
          <Col span={24}>
            <Title level={1}>Products</Title>
          </Col>
        </Row>
        <div className="paper-panel">
          <Row>
            <Col span={12}>
              <Search
                placeholder="Product Name"
                onChange={({ target: { value } }) =>
                  this.handleSearchChange(value)
                }
                value={searchValue}
                style={{ width: 350 }}
              />
            </Col>
            <Col span={12} className="text-right">
              <Button
                type="primary"
                onClick={() => this.showDrawer(-1)}
                style={{ minWidth: 140 }}
              >
                <Icon type="plus" />
                Create
              </Button>
            </Col>
          </Row>
          <Table
            columns={this.columns}
            dataSource={this.state.data}
            pagination={this.state.pagination}
            loading={this.state.loading}
            onChange={this.handleTableChange}
          />
        </div>
        <Modal
          visible={deleteModalVisible}
          closable={false}
          width={400}
          footer={[
            <Button
              key="submit"
              loading={deleteLoading}
              onClick={this.handleDeleteModalOk}
            >
              Yes, Delete
            </Button>,
            <Button
              key="back"
              type="primary"
              onClick={this.handleDeleteModalCancel}
              style={{ minWidth: 120 }}
            >
              No
            </Button>,
          ]}
        >
          <div className="text-center">
            <p>Do you want to delete this item?</p>
          </div>
        </Modal>
      </div>
    );
  }
}

export default Form.create({ name: "product" })(App);
