import React from "react";
import { message, Modal, Upload, Drawer, Form, Icon, Typography, Table, Button, Row, Col, Input } from 'antd';
import "antd/dist/antd.css";
import "./thumbnails.css";
import reqwest from 'reqwest';
import Dotdotdot from 'react-dotdotdot';

import dragula from 'dragula';
import 'dragula/dist/dragula.css';

const path = require('path');
const image2base64 = require('image-to-base64');
const { TextArea } = Input;
const { Text, Title } = Typography;

var searchTimeoutId = false;

function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
}

function beforeUpload(file) {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
        message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
        message.error('Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
}

class App extends React.Component {
    constructor(props) {
        super(props);

        this.columns = [
            {
                title: 'Sort',
                dataIndex: 'display_index',
                key: 'display_index',
                render: () => (
                    <Icon type="menu" className="draggable" style={{ fontSize: "13pt", fontWeight: "bolder", cursor: "pointer" }} />
                )
            },
            {
                title: '',
                dataIndex: 'thumbnail_photo',
                key: 'image',
                render: image => (
                    <img width="50" src={image} alt="Thumbnail" />
                )
            },
            {
                title: 'Title',
                dataIndex: 'title',
                key: 'title',
                render: data => (
                    <div>
                        <Dotdotdot clamp={1}>
                            <Text style={{ 'wordWrap': 'break-word' }}>{data[0]}</Text>
                        </Dotdotdot>
                    </div>
                )
            },
            {
                title: 'Category',
                dataIndex: 'category_name',
                key: 'category',
                render: data => (
                    <Text>{data[1]}</Text>
                )
            }
        ];
    }

    state = {
        data: [],
        pagination: { pageSize: 10 },
        loading: false,
        visible: false,
        deleteModalVisible: false,
        deleteLoading: false,
        deleteButton: false,
        saveButton: false,
        imageUrl: false,
        imageBase64: "",
        searchValue: '',
        detail: { id: "", testimonail: "", status: "1", image: false, customer_name: "", child: "son", child_age: "" }
    };

    handleChange = info => {
        if (info.file.status === 'uploading') {
            this.props.pageLoading(true);
            return;
        }
        if (info.file.status === 'done') {
            // Get this url from response in real world.
            getBase64(info.file.originFileObj, imageUrl => {
                this.props.form.setFieldsValue({
                    image: imageUrl
                });
                this.setState({
                    imageUrl
                });
                this.props.pageLoading(false);
            });
        }
    };

    componentDidMount() {
        document.title = "Related"
        this.fetch();

        const container = document.querySelector('.ant-table-tbody');
        const drake = dragula([container], {
            moves: (el) => {
                this.start = this.getIndexInParent(el);
                return true;
            }
        });

        drake.on('drop', (el) => {
            this.end = this.getIndexInParent(el);
            this.handleReorder(this.start, this.end);
        });
    }

    getIndexInParent = el => {
        return Array.from(el.parentNode.children).indexOf(el);
    };

    handleTableChange = (pagination, filters, sorter) => {
        const pager = { ...this.state.pagination };
        pager.current = pagination.current;
        this.setState({
            pagination: pager,
        });
        this.fetch({
            size: pagination.pageSize,
            page: pagination.current,
            sortField: sorter.field,
            sortOrder: sorter.order,
            ...filters,
        });
    };

    handleReorder = (dragIndex, draggedIndex) => {
        const data = [...this.state.data];
        const item = data.splice(dragIndex, 1)[0];
        data.splice(draggedIndex, 0, item);
        this.setState({
            data
        });

        var postBody = [];
        for (var i = 0; i < data.length; i++) {
            postBody.push({
                key: data[i].key,
                display_order: i + 1
            });
        }

        ;

        reqwest({
            url: process.env.REACT_APP_API + '/article/reorder',
            method: 'post',
            data: {
                v: postBody
            },
            type: 'json',
        }).then(() => {

        });
    };

    fetch = (params = {}) => {
        this.props.pageLoading(true);
        reqwest({
            url: process.env.REACT_APP_API + '/article/home',
            method: 'get',
            data: {
                home: 1
            },
            type: 'json',
        }).then(data => {
            this.setState({
                data: data.results
            });
            this.props.pageLoading(false);

            document.body.style.overflow = null;
        });
    };

    handleSearchChange(value) {
        this.setState({ searchValue: value });
        if (searchTimeoutId) { window.clearTimeout(searchTimeoutId); }
        searchTimeoutId = window.setTimeout(() => {
            this.fetch();
        }, 1500);
    }

    render() {
        const { saveButton, deleteModalVisible, deleteLoading, imageUrl, detail } = this.state;
        const { getFieldDecorator } = this.props.form;
        const uploadButton = (
            <div>
                <Icon type={this.state.loading ? 'loading' : 'plus'} />
                <div className="ant-upload-text">Upload</div>
            </div>
        );


        return (
            <div id="testimonial-component">
                <Row>
                    <Col span={24}>
                        <Title level={1}>Related Topic</Title>
                    </Col>
                </Row>
                <div className="paper-panel">
                    <Table
                        columns={this.columns}
                        dataSource={this.state.data}
                        pagination={this.state.pagination}
                        loading={this.state.loading}
                        onChange={this.handleTableChange}
                    />

                </div>

                <Modal
                    visible={deleteModalVisible}
                    width={400}
                    closable={false}
                    footer={[
                        <Button key="submit" loading={deleteLoading} onClick={this.handleDeleteModalOk}>
                            Yes, Delete
                        </Button>,
                        <Button key="back" type="primary" onClick={this.handleDeleteModalCancel} style={{ minWidth: 120 }}>
                            No
                        </Button>
                    ]}
                >
                    <div className="text-center">
                        <p>Do you want to delete this item?</p>
                    </div>
                </Modal>
            </div>
        );
    }
}

export default Form.create({ name: 'home_related' })(App);