import React from "react";
import {
  Icon,
  Form,
  Typography,
  message,
  Button,
  Row,
  Col,
  Input,
  Upload,
  Radio,
} from "antd";
import "antd/dist/antd.css";
import "./c_section_landing_page.css";
import reqwest from "reqwest";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
const { Text, Title } = Typography;

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
}

function validateImage(file) {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    message.error("You can only upload JPG/PNG file!");
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error("Image must smaller than 2MB!");
  }
  return isJpgOrPng && isLt2M;
}

function uploadImage(file, cb) {
  let reader = new FileReader();
  reader.readAsDataURL(file);

  console.log("reader: ", reader);
  reader.onload = function () {
    var b64 = reader.result;
    reqwest({
      url: process.env.REACT_APP_API + "/image/save",
      method: "post",
      data: {
        image: b64,
      },
      type: "json",
    }).then((data) => {
      cb(data.src);
    });
  };

  reader.onerror = function (error) {};

  return false;
}

function beforeUpload(file) {
  const isJpgOrPng =
    file.type === "image/jpeg" ||
    file.type === "image/png" ||
    file.type === "image/webp";
  if (!isJpgOrPng) {
    message.error("You can only upload JPG/PNG/WebP file!");
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error("Image must smaller than 2MB!");
  }
  return isJpgOrPng && isLt2M;
}

var Size = ReactQuill.Quill.import("attributors/style/size");
Size.whitelist = ["13px", "14px", "16px", "18px", "20px", "32px", "72px"];
ReactQuill.Quill.register(Size, true);

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      id: "",
      disabled: true,
      loading: false,
      image_1: false,
      image_2: false,
      url_name_part_1_kh: "",
      url_name_part_1_en: "",
      url_part_1_kh: "",
      url_part_1_en: "",
      type_button_part_2: "1",
      url_name_part_2_kh: "",
      url_name_part_2_en: "",
      url_part_2_kh: "",
      url_part_2_en: "",
      phone_number_part_2: "",
    };
  }

  componentDidMount() {
    document.title = "C-section";
    this.fetch();
  }

  imageHandler() {
    const input = document.createElement("input");

    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    input.onchange = async () => {
      const file = input.files[0];
      if (!validateImage(file)) {
        return false;
      }
      const formData = new FormData();

      formData.append("image", file);

      // Save current cursor state
      const range = this.quill.getSelection(true);

      // Insert temporary loading placeholder image
      // this.quill.insertEmbed(range.index, 'image', `${window.location.origin}/images/loaders/placeholder.gif`);

      // Move cursor to right side of image (easier to continue typing)
      this.quill.setSelection(range.index + 1);

      uploadImage(file, (cb) => {
        this.quill.insertEmbed(range.index, "image", cb);
      });

      // Remove placeholder image
      // this.quill.deleteText(range.index, 1);

      // Insert uploaded image
      // this.quill.insertEmbed(range.index, 'image', res.body.image);
      // this.quill.insertEmbed(range.index, 'image', res);
    };
  }

  handleChange_1 = (info) => {
    if (info.file.status === "uploading") {
      this.setState({ loading: true });
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (image) => {
        this.props.form.setFieldsValue({
          image_1: image,
        });
        this.setState({
          image_1: image,
          loading: false,
        });
      });
    }
  };

  handleChange_2 = (info) => {
    if (info.file.status === "uploading") {
      this.setState({ loading: true });
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (image) => {
        this.props.form.setFieldsValue({
          image_2: image,
        });
        this.setState({
          image_2: image,
          loading: false,
        });
      });
    }
  };

  getBase64 = (file, cb) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result);
    };
    reader.onerror = function (error) {};
  };

  fetch = (params = {}) => {
    this.setState({ loading: true });
    reqwest({
      url: process.env.REACT_APP_API + "/notice",
      method: "get",
      data: {
        ...params,
      },
      type: "json",
    }).then((data) => {
      console.log(data.results[0]);
      if (data.results.length > 0) {
        this.props.form.setFieldsValue({
          id: data.results[0].id || "",
          image_1: data.results[0].image_1 || null,
          image_2: data.results[0].image_2 || null,
          url_name_part_1_kh: data.results[0].url_name_part_1_kh || "",
          url_name_part_1_en: data.results[0].url_name_part_1_en || "",
          url_part_1_kh: data.results[0].url_part_1_kh || "",
          url_part_1_en: data.results[0].url_part_1_en || "",
          type_button_part_2:
            data.results[0].type_button_part_2.toString() || "1",
          url_name_part_2_kh: data.results[0].url_name_part_2_kh || "",
          url_name_part_2_en: data.results[0].url_name_part_2_en || "",
          url_part_2_kh: data.results[0].url_part_2_kh || "",
          url_part_2_en: data.results[0].url_part_2_en || "",
          phone_number_part_2: data.results[0].phone_number_part_2 || "",
        });

        this.setState({
          id: data.results[0].id || "",
          image_1: data.results[0].image_1 || null,
          image_2: data.results[0].image_2 || null,
          url_name_part_1_kh: data.results[0].url_name_part_1_kh || "",
          url_name_part_1_en: data.results[0].url_name_part_1_en || "",
          url_part_1_kh: data.results[0].url_part_1_kh || "",
          url_part_1_en: data.results[0].url_part_1_en || "",
          type_button_part_2:
            data.results[0].type_button_part_2.toString() || "1",
          url_name_part_2_kh: data.results[0].url_name_part_2_kh || "",
          url_name_part_2_en: data.results[0].url_name_part_2_en || "",
          url_part_2_kh: data.results[0].url_part_2_kh || "",
          url_part_2_en: data.results[0].url_part_2_en || "",
          phone_number_part_2: data.results[0].phone_number_part_2 || "",
        });
        console.log(this.props.form.getFieldsValue());
      }

      document.body.style.overflow = null;

      this.setState({
        loading: false,
      });

      if (!this.isset(this.state.id)) {
        this.setState({
          disabled: false,
        });
      }

      this.props.pageLoading(false);
    });
  };

  ChangeType = (el) => {
    this.setState({
      type_button_part_2: el.target.value,
    });
  };

  ChangeDisable = (el) => {
    this.setState({
      disabled: el.target.value === "true" ? true : false,
    });

    return true;
  };

  isset = (data) => {
    if (data !== undefined && data !== "" && data != null) {
      return true;
    }
    return false;
  };

  modules = {
    toolbar: {
      container: [
        ["bold", "italic", "underline", "strike"], // toggled buttons
        ["link", "image"],

        [{ list: "ordered" }, { list: "bullet" }],
        [{ script: "sub" }, { script: "super" }], // superscript/subscript
        [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
        // [{ 'direction': 'rtl' }],                         // text direction

        // [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
        [{ size: Size.whitelist }],

        [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        // [{ 'font': Font.whitelist }],
        [{ align: [] }],

        ["clean"], // remove formatting button
      ],
      handlers: {
        image: this.imageHandler,
      },
    },
  };

  onSave = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        console.log(values);
        this.props.pageLoading(true);
        reqwest({
          url: process.env.REACT_APP_API + "/notice/save",
          method: "post",
          data: values,
          type: "json",
        })
          .then(() => {
            this.setState({ visible: false });
            this.setState({
              image: null,
            });
            this.fetch();

            this.setState({
              disabled: true,
            });

            return message.success("Success.");
          })
          .catch((error) => {
            console.log(error);
            return message.warning("Something went wrong.");
          });
      } else {
        return message.error("Please complete the information.");
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const uploadButton = (
      <div>
        <Icon type={this.state.loading ? "loading" : "plus"} />
        <div className="ant-upload-text">Upload</div>
      </div>
    );
    const { id, image_1, image_2 } = this.state;
    return (
      <Form onSubmit={this.onSave} layout={"vertical"}>
        <div id="ReadmoreLink-component">
          <Row>
            <Col span={12}>
              <Title level={1}>C-section notice</Title>
            </Col>
            <Col span={12} className="text-right">
              {this.state.disabled ? (
                <button
                  type="button"
                  className="ant-btn"
                  onClick={this.ChangeDisable}
                  value={false}
                  style={{
                    minWidth: 140,
                    marginTop: 13,
                    backgroundColor: "rgb(0,82,155)",
                    color: "white",
                  }}
                >
                  Edit
                </button>
              ) : (
                <div>
                  {this.isset(this.state.id) ? (
                    <button
                      type="button"
                      className="ant-btn"
                      onClick={this.ChangeDisable}
                      value={true}
                      style={{
                        minWidth: 140,
                        marginTop: 13,
                        marginRight: 10,
                        backgroundColor: "rgb(237, 237, 237)",
                      }}
                    >
                      Cancel
                    </button>
                  ) : (
                    ""
                  )}
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ minWidth: 140, marginTop: 13 }}
                  >
                    Save
                  </Button>
                </div>
              )}
            </Col>
          </Row>
          <div className="paper-panel">
            <Row>
              <Col>
                <Form.Item label="" style={{ display: "none" }}>
                  {getFieldDecorator("id", {
                    initialValue: id || "",
                  })(<Input />)}
                </Form.Item>
                <div className="row">
                  <div className="col-50">
                    <Text strong={true}>Desktop Notice</Text>
                    <hr className="divider" />
                    <Form.Item>
                      {getFieldDecorator("image_2", {
                        initialValue: this.state.image_2,
                      })(
                        <Upload
                          accept=".png, .jpg .webp"
                          name="Upload"
                          listType="picture-card"
                          className="photo-uploader"
                          showUploadList={false}
                          action={
                            process.env.REACT_APP_API + "/image/test/upload"
                          }
                          beforeUpload={beforeUpload}
                          onChange={this.handleChange_2}
                          disabled={this.state.disabled}
                        >
                          {image_2 ? (
                            <img
                              src={image_2}
                              alt="Upload"
                              style={{ width: "100%" }}
                            />
                          ) : (
                            uploadButton
                          )}
                        </Upload>
                      )}
                    </Form.Item>
                    <div className="text-center">
                      <Text>1900 x 800</Text>
                    </div>
                  </div>
                  <div className="col-50">
                    <Text strong={true}>Mobile Notice</Text>
                    <hr className="divider" />
                    <Form.Item>
                      {getFieldDecorator("image_1", {
                        initialValue: this.state.image_1,
                      })(
                        <Upload
                          accept=".png, .jpg .webp"
                          name="Upload"
                          listType="picture-card"
                          className="photo-uploader"
                          showUploadList={false}
                          action={
                            process.env.REACT_APP_API + "/image/test/upload"
                          }
                          beforeUpload={beforeUpload}
                          onChange={this.handleChange_1}
                          disabled={this.state.disabled}
                        >
                          {image_1 ? (
                            <img
                              src={image_1}
                              alt="Upload"
                              style={{ width: "100%" }}
                            />
                          ) : (
                            uploadButton
                          )}
                        </Upload>
                      )}
                    </Form.Item>
                    <div className="text-center">
                      <Text>800 x 600</Text>
                    </div>
                  </div>
                </div>
                <hr style={{ color: "aliceblue" }} />
                <div>
                  <h4>Button Part : 1</h4>
                </div>
                <div className="row">
                  <div className="col-50">
                    <Form.Item label="URL Name (KH)">
                      {getFieldDecorator("url_name_part_1_kh", {
                        initialValue: this.state.url_name_part_1_kh,
                        rules: [
                          {
                            required: true,
                            message: "Please input your URL Name (KH)!",
                          },
                        ],
                      })(
                        <Input placeholder="" disabled={this.state.disabled} />
                      )}
                    </Form.Item>
                  </div>
                  <div className="col-50">
                    <Form.Item label="URL Name (EN)">
                      {getFieldDecorator("url_name_part_1_en", {
                        initialValue: this.state.url_name_part_1_en,
                        rules: [
                          {
                            required: true,
                            message: "Please input your URL Name (EN)!",
                          },
                        ],
                      })(
                        <Input placeholder="" disabled={this.state.disabled} />
                      )}
                    </Form.Item>
                  </div>
                </div>
                <div className="row">
                  <div className="col-50">
                    <Form.Item label="URL (KH)">
                      {getFieldDecorator("url_part_1_kh", {
                        initialValue: this.state.url_part_1_kh,
                        rules: [
                          {
                            required: true,
                            message: "Please input your URL (KH)!",
                          },
                        ],
                      })(
                        <Input placeholder="" disabled={this.state.disabled} />
                      )}
                    </Form.Item>
                  </div>
                  <div className="col-50">
                    <Form.Item label="URL (EN)">
                      {getFieldDecorator("url_part_1_en", {
                        initialValue: this.state.url_part_1_en,
                        rules: [
                          {
                            required: true,
                            message: "Please input your URL (EN)!",
                          },
                        ],
                      })(
                        <Input placeholder="" disabled={this.state.disabled} />
                      )}
                    </Form.Item>
                  </div>
                </div>
                <hr style={{ color: "aliceblue" }} />
                <div>
                  <h4>Button Part : 2</h4>
                </div>
                <Form.Item label="Type Button">
                  {getFieldDecorator("type_button_part_2", {
                    validateTrigger: ["onChange", "onBlur"],
                    initialValue: this.state.type_button_part_2,
                    rules: [
                      {
                        required: true,
                        message: "Please input your type button!",
                      },
                    ],
                  })(
                    <Radio.Group>
                      <Radio.Button
                        value="1"
                        onChange={this.ChangeType}
                        disabled={this.state.disabled}
                      >
                        <i
                          className="fa fa-link"
                          style={{ marginRight: 5 }}
                        ></i>
                        URL
                      </Radio.Button>
                      <Radio.Button
                        value="0"
                        onChange={this.ChangeType}
                        disabled={this.state.disabled}
                      >
                        <i
                          className="fa fa-phone"
                          style={{ marginRight: 5 }}
                        ></i>
                        Phone
                      </Radio.Button>
                    </Radio.Group>
                  )}
                </Form.Item>
                {this.state.type_button_part_2 === "1" ? (
                  <div>
                    <div className="row">
                      <div className="col-50">
                        <Form.Item label="URL Name (KH)">
                          {getFieldDecorator("url_name_part_2_kh", {
                            initialValue: this.state.url_name_part_2_kh,
                            rules: [
                              {
                                required: true,
                                message: "Please input your URL Name (KH)!",
                              },
                            ],
                          })(
                            <Input
                              placeholder=""
                              disabled={this.state.disabled}
                            />
                          )}
                        </Form.Item>
                      </div>
                      <div className="col-50">
                        <Form.Item label="URL Name (EN)">
                          {getFieldDecorator("url_name_part_2_en", {
                            initialValue: this.state.url_name_part_2_en,
                            rules: [
                              {
                                required: true,
                                message: "Please input your URL Name (EN)!",
                              },
                            ],
                          })(
                            <Input
                              placeholder=""
                              disabled={this.state.disabled}
                            />
                          )}
                        </Form.Item>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-50">
                        <Form.Item label="URL (KH)">
                          {getFieldDecorator("url_part_2_kh", {
                            initialValue: this.state.url_part_2_kh,
                            rules: [
                              {
                                required: true,
                                message: "Please input your URL (KH)!",
                              },
                            ],
                          })(
                            <Input
                              placeholder=""
                              disabled={this.state.disabled}
                            />
                          )}
                        </Form.Item>
                      </div>
                      <div className="col-50">
                        <Form.Item label="URL (EN)">
                          {getFieldDecorator("url_part_2_en", {
                            initialValue: this.state.url_part_2_en,
                            rules: [
                              {
                                required: true,
                                message: "Please input your URL (EN)!",
                              },
                            ],
                          })(
                            <Input
                              placeholder=""
                              disabled={this.state.disabled}
                            />
                          )}
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div>
                    <div className="row">
                      <div className="col-50">
                        <Form.Item label="Phone number">
                          {getFieldDecorator("phone_number_part_2", {
                            initialValue: this.state.phone_number_part_2,
                            rules: [
                              {
                                required: true,
                                message: "Please input your phone number!",
                              },
                            ],
                          })(
                            <Input
                              placeholder=""
                              disabled={this.state.disabled}
                            />
                          )}
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                )}
              </Col>
            </Row>
          </div>
        </div>
      </Form>
    );
  }
}

export default Form.create({ name: "notices" })(App);
