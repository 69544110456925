import React from "react";
import { Modal, Icon, Typography, Table, Button, Row, Col, Input, Drawer, Form, Radio, Upload, message } from 'antd';
import "antd/dist/antd.css";
import "./home_banner.css";
import reqwest from 'reqwest';

import Dotdotdot from 'react-dotdotdot';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import dragula from 'dragula';
import 'dragula/dist/dragula.css';
// import moment from 'moment';
// import { Tabs } from 'antd';

// const { TabPane } = Tabs;
const path = require('path');
const image2base64 = require('image-to-base64');
const { Search } = Input;
const { Text, Title } = Typography;

var searchTimeoutId = false;

function getBase64(img, callback) {
	const reader = new FileReader();
	reader.addEventListener('load', () => callback(reader.result));
	reader.readAsDataURL(img);
}

function beforeUpload(file) {
	const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
	if (!isJpgOrPng) {
		message.error('You can only upload JPG/PNG file!');
	}
	const isLt2M = file.size / 1024 / 1024 < 2;
	if (!isLt2M) {
		message.error('Image must smaller than 2MB!');
	}
	return isJpgOrPng && isLt2M;
}

function validateImage(file) {
	const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
	if (!isJpgOrPng) {
		message.error('You can only upload JPG/PNG file!');
	}
	const isLt2M = file.size / 1024 / 1024 < 2;
	if (!isLt2M) {
		message.error('Image must smaller than 2MB!');
	}
	return isJpgOrPng && isLt2M;
}

function uploadImage(file, cb) {
	let reader = new FileReader();
	reader.readAsDataURL(file);

	reader.onload = function () {
		var b64 = reader.result;
		reqwest({
			url: process.env.REACT_APP_API + '/image/save',
			method: 'post',
			data: {
				image : b64
			},
			type: 'json',
		}).then((data) => {
			cb(data.src);
		});
	};

	reader.onerror = function (error) {
		    ;
	};

	return false;
};

var Size = ReactQuill.Quill.import('attributors/style/size');
Size.whitelist = ['13px', '14px', '16px', '18px', '20px', '32px', '72px'];
ReactQuill.Quill.register(Size, true);

class App extends React.Component {
	state = {
		data: [],
		key: false,
		loading: false,
		visible: false,
		imageUrl: false,
		imageBase64: "",
		saveButton : false,
		deleteModalVisible: false,
		deleteLoading: false,
		deleteButton:false,
		searchValue: "",
		detail: { title: ["", ""] }
	};

	constructor(props) {
		super(props);
		this.columns = [
			{
				title: 'Sort',
				dataIndex: 'display_index',
				key: 'display_index',
				render: () => (
					<Icon type="menu" className="draggable" style={{ fontSize: "13pt", fontWeight: "bolder", cursor: "pointer" }} />
				)
			},
			{
				title: 'Product Name',
				dataIndex: 'name',
				key: 'name',
				render: data => (
					<div>
						<Dotdotdot clamp={1}>
							<Text>{data}</Text>
						</Dotdotdot>
					</div>
				)
			},
			{
				title: '',
				dataIndex: 'img',
				key: 'img',
				width: 120,
				render: img => (
					<img width="250" src={img} style={{ maxHeight: 150 }} alt="" />
				)
			},
			{
				title: 'Published',
				dataIndex: 'status',
				key: 'published',
				width: 100,
				align: 'center',
				render: status => (
					("" + status) === "1" ?
						<i className="fa fa-check" style={{ fontSize: 15 }}></i>
						:
						<i className="fa fa-times" style={{ fontSize: 15 }}></i>
				)
			},
			{
				title: '',
				dataIndex: 'key',
				key: 'key',
				width: 50,
				render: key => (
					<Button onClick={() => {
						this.showDrawer(key);
					}} className="icon-btn"><Icon type="setting" /></Button>
				)
			}
		];
	}

	componentDidMount() {
		document.title = "Product Get Sample"

		this.fetch();

		const container = document.querySelector('.ant-table-tbody');
		const drake = dragula([container], {
			moves: (el) => {
				this.start = this.getIndexInParent(el);
				return true;
			}
		});

		drake.on('drop', (el) => {
			this.end = this.getIndexInParent(el);
			this.handleReorder(this.start, this.end);
		});
	}

	getIndexInParent = el => {
		return Array.from(el.parentNode.children).indexOf(el);
	};

	showDrawer = async (key) => {
		var data = {};

		if (key === -1) {
			data = {id : "", name: "", desc : "", sub_title : "", status : "1", btn_status : "1" , url: "", information : "", information_kh: "", preparation : "", nutrition_info : "", img : ""};
			this.setState({
				imageUrl: null
			});
		} else {
			for (var i = 0; i < this.state.data.length; i++) {
				if (this.state.data[i].id === key) {
					data = this.state.data[i];
					break;
				}
			}
		}

		data.status = "" + data.status;


		data.btn_status = "" + data.btn_status;


		//data.type = "" + data.type;

		this.props.form.setFieldsValue(data);

		this.setState({
			visible: true,
			detail: data,
			saveButton : true,
			deleteButton : key === -1 ? false : true
		});

		if (data.img) {
			image2base64(data.img) // you can also to use url
			.then(
				(response) => {

					var b64 = "data:image/";
					var ext = path.extname(data.img);
					if (ext === ".jpg" || ext === ".jpeg") {
						b64 += "jpeg;base64," + response;
					} else if (ext === ".png") {
						b64 += "png;base64," + response;
					}
					if(b64 !== "data:image/"){
						this.props.form.setFieldsValue({
							image: b64
						});

						this.setState({
							imageUrl: b64
						});
					}

				}
			)
			.catch(
				(error) => {
					    ; //Exepection error....
				}
			)
		}
	};

	onClose = () => {
		this.setState({
			visible: false
		});
	};

	onSave = e => {
		e.preventDefault();
		this.props.form.validateFields((err, values) => {
			if (!err) {
				this.setState({saveButton : false});

				values.information_use = values.information_use ? 1 : 0;
				values.preparation_use = values.preparation_use ? 1 : 0;
				values.nutrition_use = values.nutrition_use ? 1 : 0;

				reqwest({
					url: process.env.REACT_APP_API + '/product_get_sample/save',
					method: 'post',
					data: values,
					type: 'json',
				}).then((data) => {
					if (data.success) {

						let slug = values.name.toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g, '');

						let data_values = {
							title : values.name,
							title_seo : values.name,
							desc : values.desc,
							desc_seo : values.desc,
							keyword_seo : values.name,
							page_key :slug,
							img : values.image,
						}

						reqwest({
							url: process.env.REACT_APP_API + '/meta/save',
							method: 'post',
							data: data_values,
							type: 'json',
						}).then((data) => {
							if (data.success) {
								this.setState({ visible: false });
								this.fetch();
							} else {
								this.setState({ saveButton : true });
							}
						});


						this.setState({ visible: false });
						this.fetch();
					} else {
						this.setState({ visible: true});
					}
				});
			}
		});
	};

	handleChange = info => {
		if (info.file.status === 'uploading') {
			this.setState({ loading: true });
			return;
		}
		if (info.file.status === 'done') {
			// Get this url from response in real world.
			getBase64(info.file.originFileObj, imageUrl => {
				this.props.form.setFieldsValue({
					image: imageUrl
				});
				this.setState({
					imageUrl,
					loading: false,
				});
			});
		}
	};

	handleSearchChange(value) {
		this.setState({ searchValue: value });
		if (searchTimeoutId) { window.clearTimeout(searchTimeoutId); }
		searchTimeoutId = window.setTimeout(() => {
			this.fetch();
		}, 1500);
	}

	fetch = (params = {}) => {
		    ;
		this.setState({ loading: true });
		reqwest({
			url: process.env.REACT_APP_API + "/product_get_sample",
			method: "get",
			data: {
				search: this.state.searchValue,
				...params
			},
			type: "json"
		}).then(data => {
			this.setState({
				loading: false,
				data: data.results
			});
			document.body.style.overflow = null;
		});
	};

	showDeleteModal = () => {
		this.setState({
			deleteModalVisible: true,
		});
	};

	handleDeleteModalCancel = () => {
		this.setState({ deleteModalVisible: false });
	};


	handleDeleteModalOk = () => {
		var detail = this.state.detail;
		this.setState({ deleteLoading: true });

		reqwest({
			url: process.env.REACT_APP_API + '/product_get_sample/delete',
			method: 'post',
			data: {
				id: detail.id
			},
			type: 'json',
		}).then(() => {
			this.setState({ deleteLoading: false, deleteModalVisible: false, visible: false });
			this.fetch();
		});
	};

	handleReorder = (dragIndex, draggedIndex) => {
		const data = [...this.state.data];
		const item = data.splice(dragIndex, 1)[0];
		data.splice(draggedIndex, 0, item);
		this.setState({
			data
		});

		var postBody = [];
		for (var i = 0; i < data.length; i++) {
			postBody.push({
				key: data[i].id,
				display_order: i + 1
			});
		}

		    ;

		reqwest({
			url: process.env.REACT_APP_API + '/product_get_sample/reorder',
			method: 'post',
			data: {
				v: postBody
			},
			type: 'json',
		}).then(() => {

		});
	};

	modules = {
		toolbar: {
			container : [
				['bold', 'italic', 'underline', 'strike'],        // toggled buttons
				['link', 'image', 'video'],

				[{ 'list': 'ordered'}, { 'list': 'bullet' }],
				[{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
				[{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
				// [{ 'direction': 'rtl' }],                         // text direction

				// [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
				[{ 'size': Size.whitelist }],

				[{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
				// [{ 'font': Font.whitelist }],
				[{ 'align': [] }],

				['clean']                                         // remove formatting button
			],
			handlers: {
				image: this.imageHandler
			}
		}
	};

	imageHandler() {
        const input = document.createElement('input');

        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'image/*');
        input.click();

        input.onchange = async () => {
            const file = input.files[0];
			if (!validateImage(file)) {
				return false;
			}
            const formData = new FormData();

            formData.append('image', file);

            // Save current cursor state
            const range = this.quill.getSelection(true);

            // Insert temporary loading placeholder image
            // this.quill.insertEmbed(range.index, 'image', `${window.location.origin}/images/loaders/placeholder.gif`);

            // Move cursor to right side of image (easier to continue typing)
            this.quill.setSelection(range.index + 1);

            uploadImage(file, (cb) => {
				this.quill.insertEmbed(range.index, 'image', cb);
			});

            // Remove placeholder image
            // this.quill.deleteText(range.index, 1);

            // Insert uploaded image
            // this.quill.insertEmbed(range.index, 'image', res.body.image);
            // this.quill.insertEmbed(range.index, 'image', res);
        };
    }

	getBase64 = (file, cb) => {
		let reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = function () {
			cb(reader.result)
		};
		reader.onerror = function (error) {
			    ;
		};
	};

	render() {
		const uploadButton = (
			<div>
				<Icon type={this.state.loading ? 'loading' : 'plus'} />
				<div className="ant-upload-text">Upload</div>
			</div>
		);
		const { saveButton, deleteButton, deleteModalVisible, deleteLoading, imageUrl, searchValue, detail } = this.state;
		const { getFieldDecorator } = this.props.form;


		return (
			<div id="home-banner-component">
				<Drawer
					id="home-banner-component-drawer"
					title="PRODUCT GET SAMPLE DETAIL"
					placement="right"
					closable={false}
					width={730}
					onClose={this.onClose}
					visible={this.state.visible}
					bodyStyle={{ paddingBottom: 80 }}
				>
					<Form onSubmit={this.onSave} layout={'vertical'}>
						<Form.Item label="" style={{ display: "none" }}>
							{getFieldDecorator('id', {
								initialValue: ""
							})(
								<Input />
							)}
						</Form.Item>
						<Form.Item label="Product Name*">
							{getFieldDecorator('name', {
								initialValue: detail.name,
								rules: [{ required: true, message: 'Please input Product Name!' }],
							})(
								<Input placeholder="" maxLength={1000} />
							)}
						</Form.Item>
						<Form.Item label="Product Subtitle*">
							{getFieldDecorator('sub_title', {
								initialValue: detail.sub_title,
								// rules: [{ required: true, message: 'Please input Product Subtitle!' }],
							})(
								<Input placeholder="" maxLength={1000} />
							)}
						</Form.Item>
						<Form.Item label="Product Description*">
							{getFieldDecorator('desc', {
								initialValue: detail.desc,
								rules: [{ required: true, message: 'Please input Product Description!' }],
							})(
								<Input placeholder="" maxLength={1000} />
							)}
						</Form.Item>

						{/*<Form.Item label="Product Keyword SEO*">
							{getFieldDecorator('keyword_seo', {
								initialValue: detail.keyword_seo,
								rules: [{ required: true, message: 'Please input Product Keyword SEO!' }],
							})(
								<Input placeholder="" maxLength={1000} />
							)}
						</Form.Item>*/}

						{/*<Form.Item label="Product Slug*">
							{getFieldDecorator('slug', {
								initialValue: detail.slug,
								rules: [{ required: true, message: 'Please input Product Slug!' }],
							})(
								<Input placeholder="" maxLength={1000} />
							)}
						</Form.Item>*/}

						{detail.url ? (

							<div>
							<b>Preview URL</b>
								<div>
									<p onClick={() => window.open(detail.url,'_blank')} style={{
										marginLeft: "10px",
										color: "blue",
										cursor: "pointer"
									}}>{process.env.REACT_APP_URL +'/km' + detail.url}</p>
								</div>
							</div>


						) : (<div></div>) }

						<Form.Item>
							<div>Product Image</div>
							<Upload
								accept=".png, .jpg"
								name="Upload"
								listType="picture-card"
								showUploadList={false}
								action={process.env.REACT_APP_API + '/image/test/upload'}
								beforeUpload={beforeUpload}
								onChange={this.handleChange}
							>
								{imageUrl ? <img src={imageUrl} alt="Upload" style={{ width: '100%' }} /> : uploadButton}
							</Upload>

							{getFieldDecorator('image')(
								<Input type="hidden" />
							)}
							<div>
								<Text>300 x 300</Text>
							</div>
						</Form.Item>


						<Form.Item label="Code">
							{getFieldDecorator('code', {
								initialValue: detail.code,
								// rules: [{ required: true, message: 'Please input your title!' }],
							})(
								<Input placeholder="" />
							)}
						</Form.Item>

						<Form.Item label="Publish Status*">
							{getFieldDecorator('status', {
								validateTrigger: ["onChange", "onBlur"],
								initialValue: "1",
								rules: [{ required: true, message: 'Please input your status!' }],
							})(
								<Radio.Group>
									<Radio.Button value="1"><i className="fa fa-check" style={{ marginRight: 5 }}></i>Active</Radio.Button>
									<Radio.Button value="0"><i className="fa fa-close" style={{ marginRight: 5 }}></i>Inactive</Radio.Button>
								</Radio.Group>
							)}
						</Form.Item>

						<div
							style={{
								position: "absolute",
								bottom: 0,
								width: "100%",
								borderTop: "1px solid #e8e8e8",
								padding: "10px 16px",
								left: 0,
								background: "#fff",
								borderRadius: "0 0 4px 4px",
								zIndex: 2
							}}
						>
							<Row>
								<Col span={12}>
									<Button onClick={this.onClose} style={{ background: '#EDEDED' }}>
										Close
									</Button>
								</Col>
								<Col span={12} className="text-right">
									<Button
										style={{
											marginRight: 8
										}}
										disabled={deleteButton ? false : true}
										onClick={this.showDeleteModal}
									>
										<i className="fa fa-close" style={{ marginRight: 5 }}></i>
										Delete
									</Button>
									<Button disabled={saveButton ? false : true} htmlType="submit" type="primary">
										Save
									</Button>
								</Col>
							</Row>
						</div>
					</Form>
				</Drawer>
				<Row>
					<Col span={24}>
						<Title level={1}>Products GetSample</Title>
					</Col>
				</Row>
				<div className="paper-panel">
					<Row>
						<Col span={12}>
							<Search
								placeholder="Product Name"
								onChange={({ target: { value } }) => this.handleSearchChange(value)}
								value={searchValue}
								style={{ width: 350 }}
							/>
						</Col>
						<Col span={12} className="text-right">
							<Button type="primary" onClick={() => this.showDrawer(-1)} style={{ minWidth: 140 }}>
								<Icon type="plus" />
								Create
							</Button>
						</Col>
					</Row>
					<Table
						columns={this.columns}
						dataSource={this.state.data}
						pagination={this.state.pagination}
						loading={this.state.loading}
						onChange={this.handleTableChange}
					/>
				</div>
				<Modal
					visible={deleteModalVisible}
					closable={false}
					width={400}
					footer={[
						<Button key="submit" loading={deleteLoading} onClick={this.handleDeleteModalOk}>
							Yes, Delete
					</Button>,
						<Button key="back" type="primary" onClick={this.handleDeleteModalCancel} style={{ minWidth: 120 }}>
							No
					</Button>
					]}
				>
					<div className="text-center">
						<p>Do you want to delete this item?</p>
					</div>
				</Modal>
			</div>
		);
	}
}

export default Form.create({ name: 'product' })(App);
