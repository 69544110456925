import React from "react";
import { Modal, Typography, Table, Button, Row, Col, Form, Drawer, Input } from 'antd';
import "antd/dist/antd.css";
import "./careline_message.css";
import reqwest from 'reqwest';
import Dotdotdot from 'react-dotdotdot';


const { Search } = Input;
const { Title } = Typography;

var searchTimeoutId = false;

class App extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      data: [],
      pagination: { pageSize: 10 },
      loading: false,
      visible: false,
      deleteModalVisible: false,
      deleteLoading: false,
      searchValue: "",
      detail: { title: ["", ""] }
    };

    this.columns = [
      {
        title: 'Submit Date',
        dataIndex: 'submitDate',
        width: 120,
        key: 'submitDate'
      },
      {
        title: 'Title/Detail',
        dataIndex: 'title',
        key: 'title',
        render: title => (
          <div>
            <Dotdotdot clamp={1}>
              <strong>{title[0]}</strong>
            </Dotdotdot>
            <Dotdotdot clamp={2}>
              {title[1]}
            </Dotdotdot>
          </div>
        )
      },
      {
        title: '',
        dataIndex: 'key',
        key: 'key',
        render: key => (
          <Button style={{marginRight: 50}} onClick={() => {
            this.showDrawer(key);
          }}>Detail</Button>
        )
      },
      {
        title: 'Contact',
        dataIndex: 'contact',
        key: 'contact',
        width: 250,
        render: contact => (
          <div>
            <strong>{contact[0]}</strong>
            <div>{contact[1]}</div>
            <div>{contact[2]}</div>
          </div>
        ),
      },
    ];
  }

  showDrawer = (key) => {
    var data = false;
    for (var i = 0; i < this.state.data.length; i++) {
      if (this.state.data[i].key === key) {
        data = this.state.data[i];
        break;
      }
    }

    //     ;

    this.setState({
      visible: true,
      detail: data
    });
  };

  showDeleteModal = () => {
    this.setState({
      deleteModalVisible: true,
    });
  };

  handleSearchChange(value) {
    this.setState({ searchValue: value });
    if (searchTimeoutId) { window.clearTimeout(searchTimeoutId); }
    searchTimeoutId = window.setTimeout(() => {
      this.fetch();
    }, 1500);
  }

  handleDeleteModalOk = () => {
    var detail = this.state.detail;
    this.setState({ deleteLoading: true });

    reqwest({
      url: process.env.REACT_APP_API + '/careline_message/delete',
      method: 'post',
      data: {
        id: detail.key
      },
      type: 'json',
    }).then(() => {
      this.setState({ deleteLoading: false, deleteModalVisible: false, visible: false });
      this.fetch();
    });
  };

  handleDeleteModalCancel = () => {
    this.setState({ deleteModalVisible: false });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };

  componentDidMount() {
    document.title = "Careline Message"
    this.fetch();
  }

  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager,
    });
    this.fetch({
      size: pagination.pageSize,
      page: pagination.current,
      sortField: sorter.field,
      sortOrder: sorter.order,
      ...filters,
    });
  };

  fetch = (params = {}) => {
        ;

    this.props.pageLoading(true);

    reqwest({
      url: process.env.REACT_APP_API + '/careline_message',
      method: 'get',
      data: {
        size: this.state.pagination.pageSize,
        search: this.state.searchValue,
        ...params,
      },
      type: 'json',
    }).then(data => {
      const pagination = { ...this.state.pagination };
      // Read total count from server
      // pagination.total = data.totalCount;
      pagination.total = data.total;
	  document.body.style.overflow = null;
	  this.props.pageLoading(false);
      this.setState({
        data: data.results,
        pagination,
      });
    });
  };

  handleExport() {
    window.open(process.env.REACT_APP_API + '/careline_message/export?search=' + this.state.searchValue);
  }

  render() {
    const { deleteModalVisible, deleteLoading, searchValue, detail } = this.state;

    return (
      <div id="careline-component">
        <Row>
          <Col span={24}>
            <Title level={1}>Careline Message</Title>
          </Col>
        </Row>
        <div className="paper-panel">
          <Row>
            <Col span={12}>
              <Search
                placeholder="Message / Customer"
                onChange={({ target: { value } }) => this.handleSearchChange(value)}
                value={searchValue}
                style={{ width: 350 }}
              />
            </Col>
            <Col span={12} className="text-right">
              <Button type="primary" style={{ minWidth: 140 }} onClick={() => this.handleExport()}>Export</Button>
            </Col>
          </Row>
          <Table
            columns={this.columns}
            dataSource={this.state.data}
            pagination={this.state.pagination}
            loading={this.state.loading}
            onChange={this.handleTableChange}
          />

          <Drawer
            title="Detail"
            width={380}
            closable={false}
            onClose={this.onClose}
            headerStyle={{ textTransform: 'uppercase', fontWeight: 'bold' }}
            visible={this.state.visible}
            bodyStyle={{ paddingBottom: 80 }}
          >
            <div>
              <Form.Item label={detail.title[0]} colon={false}>
                <div className="">
                  {detail.title[1]}
                </div>
              </Form.Item>
            </div>

            <div
              style={{
                position: 'absolute',
                right: 0,
                bottom: 0,
                width: '100%',
                borderTop: '1px solid #e9e9e9',
                padding: '10px 16px',
                background: '#fff',
                textAlign: 'left',
              }}
            >
              <Button onClick={this.onClose} style={{ marginRight: 8 }} className="close-btn">
                Close
              </Button>
              <Button onClick={this.showDeleteModal} className="secondary-action-btn">
                <i className="fa fa-close" style={{ marginRight: 5 }}></i>Delete
              </Button>
            </div>
          </Drawer>
        </div>

        <Modal
          visible={deleteModalVisible}
          width={400}
          closable={false}
          footer={[
            <Button key="submit" loading={deleteLoading} onClick={this.handleDeleteModalOk}>
              Yes, Delete
            </Button>,
            <Button key="back" type="primary" onClick={this.handleDeleteModalCancel} style={{ minWidth: 120 }}>
              No
            </Button>
          ]}
        >
          <div className="text-center">
            <p>Do you want to delete this item?</p>
          </div>
        </Modal>
      </div>
    );
  }
}

export default App;