import React from "react";
import { Modal, Button, Input, Form } from 'antd';
import { Redirect } from "react-router-dom";
import "antd/dist/antd.css";
import "./login.css";
import authen from "./authen.js";
import reqwest from 'reqwest';

import cookie from 'js-cookie'

class App extends React.Component {
	state = {
		redirectToReferrer: false,
		visible: false
	};

	componentDidMount() {
		document.title = "Login"

		var token = cookie.get('token');

		if (token) {
			reqwest({
				url: process.env.REACT_APP_API + "/auth/",
				method: "get",
				data: {
					token : cookie.get('token')
				},
				type: "json"
			}).then(data => {
				authen.authenticate(() => {
					this.setState({
						redirectToReferrer: true,
						username : data.message
					});
				});
			});
		}
	}

	showModal = (message) => {
		this.setState({
			visible: true,
			message: message
		});
	};

	closeModal = () => {
		this.setState({
			visible: false
		});
	}

	handleLogin = e => {
		e.preventDefault();
		this.props.form.validateFields((err, values) => {
			if (!err) {
				this.props.pageLoading(true);
				reqwest({
					url: process.env.REACT_APP_API + '/auth/login',
					method: 'post',
					data: values,
					type: 'json',
				}).then(data => {
					this.props.pageLoading(false);
					if (data.success) {
						cookie.set('token', data.message);
						reqwest({
							url: process.env.REACT_APP_API + "/auth/",
							method: "get",
							data: {
								token : cookie.get('token')
							},
							type: "json"
						}).then(data => {
							this.props.setUsername(data.message);
							authen.authenticate(() => {
								this.setState({
									redirectToReferrer: true
								});
							});
						});
					} else {
						this.showModal(data.message);
					}
				});
			}
		});
	};

	render() {
		const { message, redirectToReferrer } = this.state

		if (redirectToReferrer === true) {
			return <Redirect to='/careline_message' />
		}

		const { getFieldDecorator } = this.props.form;

		return (
			<div id="login-component"
				style={{
					background: "url('../assets/images/people.png') no-repeat bottom center",
					backgroundSize: "100% auto"
				}}>
				<Form onSubmit={this.handleLogin} id="login-form" layout={'vertical'}>
					<div style={{ height: 138, marginBottom: 30, textAlign: 'center' }}>
						<img src="./assets/images/logo.png" alt="" />
					</div>

					<Form.Item label="">
						{getFieldDecorator('username', {
							rules: [{ required: true, message: 'Please input your username!' }],
						})(
							<Input placeholder="Username" autoComplete="new-password" />
						)}
					</Form.Item>

					<Form.Item label="">
						{getFieldDecorator('password', {
							rules: [{ required: true, message: 'Please input your password!' }],
						})(
							<Input.Password placeholder="Password" autoComplete="new-password" />
						)}
					</Form.Item>

					<Button block id="login-btn" htmlType="submit">Login</Button>
				</Form>

				<Modal
					title="Error"
					visible={this.state.visible}
					onOk={this.closeModal}
					onCancel={this.closeModal}
					footer={[
						<Button key="ok" type="primary" onClick={this.closeModal}>
							OK
					</Button>,
					]}
				>
					<p>{message}</p>
				</Modal>
			</div>
		);
	}
}

export default Form.create({ name: 'normal_login' })(App);