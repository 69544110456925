import React from "react";
import { Checkbox, Drawer, Modal, Form, Radio, message, Upload, Select, Icon, Typography, Table, Button, Row, Col, Input } from 'antd';
import "antd/dist/antd.css";
import "./article.css";
import reqwest from 'reqwest';

import ReactQuill, {Quill} from 'react-quill';
import 'react-quill/dist/quill.snow.css';
const path = require('path');
const image2base64 = require('image-to-base64');
const { Search } = Input;
const { Text, Title } = Typography;

var Link = Quill.import('formats/link');
Quill.debug(false)

class MyLink extends Link {
 static create(value) {
   let node = super.create(value);
   value = this.sanitize(value);
   if(!value.startsWith("http")) {
     value = "http://" + value;
   }
   node.setAttribute('href', value);
   return node;
 }
}
Quill.register(MyLink);

var searchTimeoutId = false;
function getBase64(img, callback) {
	const reader = new FileReader();
	reader.addEventListener('load', () => callback(reader.result));
	reader.readAsDataURL(img);
}

function beforeCoverUpload(file) {
	const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
	if (!isJpgOrPng) {
		message.error('You can only upload JPG/PNG file!');
	}
	const isLt2M = file.size / 1024 / 1024 < 2;
	if (!isLt2M) {
		message.error('Image must smaller than 2MB!');
	}
	return isJpgOrPng && isLt2M;
}

function beforeThumbnailUpload(file) {
	const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
	if (!isJpgOrPng) {
		message.error('You can only upload JPG/PNG file!');
	}
	const isLt2M = file.size / 1024 / 1024 < 2;
	if (!isLt2M) {
		message.error('Image must smaller than 2MB!');
	}
	return isJpgOrPng && isLt2M;
}

function validateImage(file) {
	const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
	if (!isJpgOrPng) {
		message.error('You can only upload JPG/PNG file!');
	}
	const isLt2M = file.size / 1024 / 1024 < 2;
	if (!isLt2M) {
		message.error('Image must smaller than 2MB!');
	}
	return isJpgOrPng && isLt2M;
}

function uploadImage(file, cb) {
	let reader = new FileReader();
	reader.readAsDataURL(file);

	reader.onload = function () {
		var b64 = reader.result;
		reqwest({
			url: process.env.REACT_APP_API + '/image/save',
			method: 'post',
			data: {
				image : b64
			},
			type: 'json',
		}).then((data) => {
			cb(data.src);
		});
	};

	reader.onerror = function (error) {
		    ;
	};

	return false;
};

var Size = ReactQuill.Quill.import('attributors/style/size');
Size.whitelist = ['13px', '14px', '16px', '18px', '20px', '32px', '72px'];
ReactQuill.Quill.register(Size, true);

class App extends React.Component {
	onCheckChange = (e, record) => {
		var postBody = {
			key: record.key,
			is_highlight: e.target.checked ? 1 : 0
		};

		    ;

		reqwest({
			url: process.env.REACT_APP_API + '/article/highlight',
			method: 'post',
			data: postBody,
			type: 'json',
		}).then(() => {
			this.setState ({
				highlight: record
			});
			this.fetch();
		});
	}

	constructor(props) {
		super(props);

		this.columns = [
			{
				title: '',
				dataIndex: 'thumbnail_photo',
				key: 'image',
				render: image => (
					<img width="50" src={image} alt="Thumbnail" />
				)
			},
			{
				title: 'Title',
				dataIndex: 'title',
				key: 'title',
				render: data => (
					<div>
						<Text>{data[0]}</Text>
						{/* <br /> */}
						{/* <Text>{data[1]}</Text> */}
					</div>
				)
			},
			{
				title: 'Category',
				dataIndex: 'category_name',
				key: 'category',
				render: data => (
					<Text>{data[1]}</Text>
				)
			},
			{
				title: 'Group',
				dataIndex: 'article_group_name',
				key: 'group',
				render: data => (
					<Text>{data[1]}</Text>
				)
			},
			{
				title: 'Topic',
				dataIndex: 'topic_name',
				key: 'topic',
				render: data => (
					<Text>{data[1]}</Text>
				)
			},
			{
				title: 'Unique View',
				dataIndex: 'unique_view',
				key: 'unique_view',
				render: data => (
					<Text>{data}</Text>
				)
			},
			{
				title: 'Highlight',
				dataIndex: 'is_highlight',
				key: 'is_highlight',
				render: (is_highlight, record) => (
					<Checkbox checked={this.state.highlight && this.state.highlight.key && record.key === this.state.highlight.key ? true : false} onChange={(e) => this.onCheckChange(e, record)}></Checkbox>
				)
			},
			{
				title: 'Published',
				dataIndex: 'status',
				key: 'published',
				render: status => (
					status === 1 ?
						<i className="fa fa-check" style={{ fontSize: 15 }}></i>
						:
						<i className="fa fa-times" style={{ fontSize: 15 }}></i>
				)
			},
			{
				title: '',
				dataIndex: 'key',
				key: 'key',
				width: 50,
				render: key => (
					<Button onClick={() => {
						this.showDrawer(key);
					}} className="icon-btn"><Icon type="setting" /></Button>
				)
			}
		];
	}

	state = {
		data: [],
		detail: { id: "", title_en: "", title_kh: "", short_description_en: "", short_description_kh: "", detail_en: "", detail_kh: "", cover_photo: "", thumbnail_photo: "", status: 1, category: "", topic: "" , text_kh: "", link_to: ""},
		description: '',
		pagination: {},
		loading: false,
		visible: false,
		imageCoverUrl: false,
		imageThumbnailUrl: false,
		deleteButton : false,
		saveButton : true,
		deleteModalVisible: false,
		deleteLoading: false,
		searchValue: '',
		searchCategory: '',
		searchGroup : '',
		searchTopic: '',
		category: [],
		topic: [],
		group : [],
		allGroup : [],
		allTopic: [],
		nameDisabled : false,
		formGroupSelected : '',
		formCategorySelected : '',
		showGroup : [],
		showTopic: [],
		highlight: {}
	};
	componentDidMount() {
		document.title = "Article"
		this.fetch();
	}

	handleSearchChange(value) {
		this.setState({ searchValue: value });
		if (searchTimeoutId) { window.clearTimeout(searchTimeoutId); }
		searchTimeoutId = window.setTimeout(() => {
			this.fetch();
		}, 1500);
	}

	showDrawer = async (key) => {
		this.props.pageLoading(true);
		var data = {};

		if (key === -1) {
			data = { id: "", title_en: "", title_kh: "", short_description_en: "", short_description_kh: "", detail_en: "", detail_kh: "", cover_photo: "", thumbnail_photo: "", status: 1, category: "", group : "", topic: "", text_kh: "", link_to: ""};
			this.setState({
				imageUrl: null
			});
		} else {
			for (var i = 0; i < this.state.data.length; i++) {
				if (this.state.data[i].key === key) {
					data = this.state.data[i];
					break;
				}
			}
		}

		if (key === -1) {
			this.populateGroup(data.category);
			this.populateTopic(data.article_group);

			this.props.form.setFieldsValue({
				id: data.key,
				title_en: data.title_en,
				title_kh: data.title_kh,
				short_description_en: data.short_description_en,
				short_description_kh: data.short_description_kh,
				detail_en: data.detail_en,
				detail_kh: data.detail_kh,
				category: data.category,
				topic: data.topic,
				group : data.article_group,
				text_kh: data.text_kh,
				link_to: data.link_to,
				status: "" + data.status,
				cover_photo: false,
				thumbnail_photo: false
			});

			this.setState({
				visible: true,
				detail: data,
				imageCoverUrl: false,
				nameDisabled : false,
				imageThumbnailUrl: false,
				deleteButton : (key === -1) ? false : true,
				saveButton : true
			});

			this.props.pageLoading(false);
		} else {

			this.state.formCategorySelected = data.category;
			    ;

			this.populateGroup(data.category);
			this.populateTopic(data.article_group);

			this.props.form.setFieldsValue({
				id: data.key,
				title_en: data.title_en,
				title_kh: data.title_kh,
				short_description_en: data.short_description_en,
				short_description_kh: data.short_description_kh,
				detail_en: data.detail_en,
				detail_kh: data.detail_kh,
				category: data.category,
				group : data.article_group,
				topic: data.topic,
				text_kh: data.text_kh,
				link_to: data.link_to,
				status: "" + data.status,
				cover_photo: false,
				thumbnail_photo: false
			});

			this.setState({
				visible: true,
				detail: data,
				deleteButton : (key === -1) ? false : true,
				saveButton : true,
				imageCoverUrl: false,
				imageThumbnailUrl: false
			});

			this.handleFormTopChange(data.topic);

			this.props.pageLoading(false);

		image2base64(data.cover_photo) // you can also to use url
			.then(
				(response) => {
					var b64 = "data:image/";
					var ext = path.extname(data.cover_photo);
					if (ext === ".jpg" || ext === ".jpeg") {
						b64 += "jpeg;base64," + response;
					} else if (ext === ".png") {
						b64 += "png;base64," + response;
					} else {
						b64 = false;
					}

					image2base64(data.thumbnail_photo) // you can also to use url
						.then(
							(response) => {
								var bt64 = "data:image/";
								var ext = path.extname(data.thumbnail_photo);

								if (ext === ".jpg" || ext === ".jpeg") {
									bt64 += "jpeg;base64," + response;
								} else if (ext === ".png") {
									bt64 += "png;base64," + response;
								} else {
									bt64 = false;
								}

								if(b64 !== "data:image/"){
									this.props.form.setFieldsValue({
										cover_photo: b64,
										thumbnail_photo: bt64
									});

									this.setState({
										imageCoverUrl: b64 || false,
										imageThumbnailUrl: bt64 || false,
										loading: false
									});
								}

							})
				}
			)
			.catch(
				(error) => {
					    ; //Exepection error....
					this.props.pageLoading(false);
				}
			)

		}
	};

	onClose = () => {
		this.setState({
			visible: false,
			imageCoverUrl : null,
			imageThumbnailUrl : null

		});
		this.props.form.setFieldsValue({
			cover_photo: null,
			thumbnail_photo: null
		});
	};

	fetch = (params = {}) => {
		    ;

		this.props.pageLoading(true);

		reqwest({
			url: process.env.REACT_APP_API + "/article",
			method: "get",
			data: {
				search: this.state.searchValue,
				category: this.state.searchCategory,
				topic: this.state.searchTopic,
				group : this.state.searchGroup,
				...params
			},
			type: "json"
		}).then(data => {
			this.setState({
				data: data.results,
				highlight: data.results.find((element) => element.is_highlight === 1)
			});



			this.props.pageLoading(false);

			document.body.style.overflow = null;
		});

		reqwest({
			url: process.env.REACT_APP_API + "/article/init",
			method: "get",
			data: {
			},
			type: "json"
		}).then(data => {
			//     ;

			this.setState({
				allTopic: data.results.topic,
				allGroup : data.results.group,
				category: data.results.category
			});
		});
	};

	handleCategoryChange = (value) => {
		this.setState({ searchTopic: value });
		if (searchTimeoutId) { window.clearTimeout(searchTimeoutId); }
		searchTimeoutId = window.setTimeout(() => {
			this.fetch();
		}, 1500);

		var temp = [];
		for (var i = 0; i < this.state.allGroup.length; i++) {
			if (this.state.allGroup[i].article_category_id === value) {
				temp.push(this.state.allGroup[i]);
			}
		}

		    ;

		this.setState({
			searchCategory : value,
			group: temp,
			searchGroup : '',
			searchTopic : '',
			topic : []
		});
	}

	handleGroupChange = (value) => {
		this.setState({ searchTopic: value });
		if (searchTimeoutId) { window.clearTimeout(searchTimeoutId); }
		searchTimeoutId = window.setTimeout(() => {
			this.fetch();
		}, 1500);

		var temp = [];
		for (var i = 0; i < this.state.allTopic.length; i++) {
			if (this.state.allTopic[i].article_category === this.state.searchCategory && this.state.allTopic[i].article_group === value) {
				temp.push(this.state.allTopic[i]);
			}
		}

		    ;


		this.setState({
			searchGroup : value,
			searchTopic : '',
			topic: temp
		});
	}

	handleFormCategoryChange = (value) => {
		this.populateGroup(value);
	}

	handleFormGroupChange = (value) => {
		this.populateTopic(value);
	}

	handleFormTopChange = (value) => {
		var canEdit = true;
		var item = false;
		for (var i = 0; i < this.state.allTopic.length; i++) {
			if (this.state.allTopic[i].id === value) {
				canEdit = (1 === this.state.allTopic[i].can_edit);
				item = this.state.allTopic[i];
				    ;
			}
		}

		this.setState({
			nameDisabled : !canEdit
		});

		if (item && !canEdit) {

			// this.props.form.setFieldsValue({
			// 	title_en: item.name_en,
			// 	title_kh: item.name_kh
			// });
		}
	}

	populateGroup = (value) => {
		var temp = [];
		for (var i = 0; i < this.state.allGroup.length; i++) {
			if (this.state.allGroup[i].article_category_id === value) {
				temp.push(this.state.allGroup[i]);
			}
		}

		    ;

		this.setState({
			formCategorySelected : value,
			showGroup: temp
		});

		this.props.form.setFieldsValue({
			group: '',
			topic : ''
		});
	}

	populateTopic = (value) => {
		    ;
		var temp = [];
		for (var i = 0; i < this.state.allTopic.length; i++) {
			    ;
			if (this.state.allTopic[i].article_group === value && this.state.allTopic[i].article_category === this.state.formCategorySelected) {
				temp.push(this.state.allTopic[i]);
			}
		}
		    ;

		this.setState({
			formGroupSelected : value,
			showTopic: temp
		});

		this.props.form.setFieldsValue({
			topic: ''
		});
	}

	handleTopicChange = (value) => {
		this.setState({ searchTopic: value });
		if (searchTimeoutId) { window.clearTimeout(searchTimeoutId); }
		searchTimeoutId = window.setTimeout(() => {
			this.fetch();
		}, 1500);
	}

	showDeleteModal = () => {
		this.setState({
			deleteModalVisible: true,
		});
	};

	handleDeleteModalCancel = () => {
		this.setState({ deleteModalVisible: false });
	};


	handleDeleteModalOk = () => {
		var detail = this.state.detail;
		this.setState({ deleteLoading: true });

		reqwest({
			url: process.env.REACT_APP_API + '/article/delete',
			method: 'post',
			data: {
				id: detail.key
			},
			type: 'json',
		}).then(() => {
			this.setState({ deleteLoading: false, deleteModalVisible: false, visible: false });
			this.fetch();
		});
	};

	onSave = e => {
		e.preventDefault();
		this.props.form.validateFields((err, values) => {
			if (!err) {
				this.setState({saveButton : false});
				reqwest({
					url: process.env.REACT_APP_API + '/article/save',
					method: 'post',
					data: values,
					type: 'json',
				}).then((data) => {
					if (data.success) {
						this.setState({ visible: false });
						this.fetch();
					} else {
						this.setState({saveButton : true});
					}
				});
			}
		});
	};

	handleCoverChange = info => {
		if (info.file.status === 'uploading') {
			this.props.pageLoading(true);
			return;
		}
		if (info.file.status === 'done') {
			// Get this url from response in real world.
			getBase64(info.file.originFileObj, imageCoverUrl => {
				this.props.form.setFieldsValue({
					cover_photo: imageCoverUrl
				});
				this.setState({
					imageCoverUrl
				});
				this.props.pageLoading(false);
			});
		}
	};

	handleDetailChange() {
		// this.setState({ detail: value })
	}

	handleThumbnailChange = info => {
		if (info.file.status === 'uploading') {
			this.props.pageLoading(true);
			return;
		}
		if (info.file.status === 'done') {
			// Get this url from response in real world.
			getBase64(info.file.originFileObj, imageThumbnailUrl => {
				this.props.form.setFieldsValue({
					thumbnail_photo: imageThumbnailUrl
				});
				this.setState({
					imageThumbnailUrl
				});
				this.props.pageLoading(false);
			});
		}
	};

	modules = {
		toolbar: {
		container : [
			['bold', 'italic', 'underline', 'strike'],        // toggled buttons
			['link', 'image', 'video'],

			[{ 'list': 'ordered'}, { 'list': 'bullet' }],
			[{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
			[{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
			// [{ 'direction': 'rtl' }],                         // text direction

			// [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
			[{ 'size': Size.whitelist }],

			[{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
			// [{ 'font': Font.whitelist }],
			[{ 'align': [] }],

			['clean']                                         // remove formatting button
		],
		handlers: {
			image: this.imageHandler
		}
	}
	};

	imageHandler() {
        const input = document.createElement('input');

        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'image/*');
        input.click();

        input.onchange = async () => {

            const file = input.files[0];
			if (!validateImage(file)) {
				return false;
			}
            const formData = new FormData();

            formData.append('image', file);

            // Save current cursor state
            const range = this.quill.getSelection(true);

            // Insert temporary loading placeholder image
            // this.quill.insertEmbed(range.index, 'image', `${window.location.origin}/images/loaders/placeholder.gif`);

            // Move cursor to right side of image (easier to continue typing)
            this.quill.setSelection(range.index + 1);

            uploadImage(file, (cb) => {
				this.quill.insertEmbed(range.index, 'image', cb);
			});

            // Remove placeholder image
            // this.quill.deleteText(range.index, 1);

            // Insert uploaded image
            // this.quill.insertEmbed(range.index, 'image', res.body.image);
            // this.quill.insertEmbed(range.index, 'image', res);
        };
    }

	getBase64 = (file, cb) => {
		let reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = function () {
			cb(reader.result)
		};
		reader.onerror = function (error) {
			    ;
		};
	};



	render() {
		const { saveButton, deleteButton, searchTopic, deleteModalVisible, deleteLoading, imageCoverUrl, imageThumbnailUrl, detail, topic, showTopic, showGroup, category, group, searchGroup, searchCategory} = this.state;
		const uploadCoverButton = (
			<div>
				<Icon type={this.state.loading ? 'loading' : 'plus'} />
				<div className="ant-upload-text">Upload</div>
			</div>
		);

		const uploadThumbnailButton = (
			<div>
				<Icon type={this.state.loading ? 'loading' : 'plus'} />
				<div className="ant-upload-text">Upload</div>
			</div>
		);

		const { getFieldDecorator } = this.props.form;

		return (
			<div id="article-component">
				<Drawer
					id="article-component-drawer"
					title="ARTICLE"
					placement="right"
					closable={false}
					width={730}
					onClose={this.onClose}
					visible={this.state.visible}
					bodyStyle={{ paddingBottom: 120 }}
				>
					<Form onSubmit={this.onSave} layout={'vertical'}>
						<Form.Item label="Title EN*">
							{getFieldDecorator('title_en', {
								initialValue: detail.title_kh,
								rules: [{ required: true, message: 'Please input Title!' }],
							})(
								// <Input placeholder="" maxLength={55} disabled={nameDisabled} />
								<Input placeholder="" maxLength={55} />
							)}
						</Form.Item>
						<Form.Item label="Title KH*">
							{getFieldDecorator('title_kh', {
								initialValue: detail.title_en,
								rules: [{ required: true, message: 'Please input Title!' }],
							})(
								// <Input placeholder="" maxLength={55} disabled={nameDisabled} />
								<Input placeholder="" maxLength={55} />
							)}
						</Form.Item>
						{/* {getFieldDecorator('title_en', {
							initialValue: detail.title_en,
						})} */}
						<Row gutter={[16, 0]}>
							<Col span={8}>
								<Form.Item style={{ display: "none" }}>
									{getFieldDecorator('id', {
										initialValue: detail.id
									})(
										<Input />
									)}
								</Form.Item>
								<Form.Item label="Category*">
									{getFieldDecorator('category', {
										validateTrigger: ["onChange", "onBlur"],
										initialValue: '',
										rules: [{ required: true, message: 'Please choose category!' }],
									})(
										<Select style={{ width: '100%', marginRight: 8 }} onChange={this.handleFormCategoryChange}>
											<Select.Option key="" value="">Please select</Select.Option>
											{
												category.map(value => <Select.Option key={value.id} value={value.id}>{value.name_en}</Select.Option>)
											}
										</Select>
									)}
								</Form.Item>
							</Col>
							<Col span={8}>
								<Form.Item label="Group*">
									{getFieldDecorator('group', {
										validateTrigger: ["onChange", "onBlur"],
										initialValue: '',
										rules: [{ required: true, message: 'Please choose group!' }],
									})(
										<Select style={{ width: '100%', marginRight : 8 }} onChange={this.handleFormGroupChange}>
											<Select.Option key="" value="">Please select</Select.Option>
											{
												showGroup.map(value => <Select.Option key={value.id} value={value.id}>{value.name_en}</Select.Option>)
											}
										</Select>
									)}
								</Form.Item>
							</Col>
							<Col span={8}>
								<Form.Item label="Topic*">
									{getFieldDecorator('topic', {
										validateTrigger: ["onChange", "onBlur"],
										initialValue: '',
										rules: [{ required: true, message: 'Please choose topic!' }],
									})(
										<Select style={{ width: '100%' }} onChange={this.handleFormTopChange}>
											<Select.Option key="" value="">Please select</Select.Option>
											{
												showTopic.map(value => <Select.Option key={value.id} value={value.id}>{value.name_en}</Select.Option>)
											}
										</Select>
									)}
								</Form.Item>
							</Col>
						</Row>
						<Form.Item label="Short Description*">
							{getFieldDecorator('short_description_kh', {
								initialValue: detail.short_description_kh,
								rules: [{ required: true, message: 'Please input short description!' }],
							})(
								<Input placeholder="" />
							)}
						</Form.Item>
						{/* <Form.Item label="Short Description EN*">
							{getFieldDecorator('short_description_en', {
								initialValue: detail.short_description_en,
								rules: [{ required: true, message: 'Please input short description!' }],
							})(
								<Input placeholder="" />
							)}
						</Form.Item> */}
						{getFieldDecorator('short_description_en', {
							initialValue: detail.short_description_en,
						})}
						<Form.Item label="Detail*">
							{getFieldDecorator('detail_kh', {
								initialValue: detail.detail_kh,
								rules: [{ required: true, message: 'Please input detail!' }],
							})(
								<ReactQuill ref={el => {
									this.quill = el;
								}} formats={MyLink} id="promotion-desc" onChange={this.handleDetailChange} modules={this.modules} height={300} />
							)}
						</Form.Item>
						{/* <Form.Item label="Detail EN*">
							{getFieldDecorator('detail_en', {
								initialValue: detail.detail_en,
								rules: [{ required: true, message: 'Please input detail!' }],
							})(
								<ReactQuill id="promotion-desc" onChange={this.handleDetailChange} modules={this.modules} height={300} />
							)}
						</Form.Item> */}
						{getFieldDecorator('detail_en', {
							initialValue: detail.detail_en,
						})}
						<Row gutter={[16, 0]}>
							<Col span={8}>
								<Form.Item label="Cover Photo*">
									<Upload
										name="cover_photo"
										listType="picture-card"
										className="photo-uploader"
										showUploadList={false}
										action={process.env.REACT_APP_API + '/image/test/upload'}
										beforeUpload={beforeCoverUpload}
										onChange={this.handleCoverChange}
									>
										{imageCoverUrl ? <img src={imageCoverUrl} alt="Cover" style={{ width: '100%' }} /> : uploadCoverButton}
									</Upload>
									{getFieldDecorator('cover_photo')(
										<Input type="hidden" />
									)}
									<div className="text-center">
										<Text>1200 x 900</Text>
									</div>
								</Form.Item>
							</Col>
							<Col span={6}>
								<Form.Item label="Thumbnail*">
									<Upload
										name="thumbnai_photo"
										listType="picture-card"
										className="thumbnail-uploader"
										showUploadList={false}
										action={process.env.REACT_APP_API + '/image/test/upload'}
										beforeUpload={beforeThumbnailUpload}
										onChange={this.handleThumbnailChange}
									>
										{imageThumbnailUrl ? <img src={imageThumbnailUrl} alt="Thumbnail" style={{ width: '100%' }} /> : uploadThumbnailButton}
									</Upload>
									{getFieldDecorator('thumbnail_photo')(
										<Input type="hidden" />
									)}
									<div className="text-center">
										<Text>300 x 200</Text>
									</div>
								</Form.Item>
							</Col>
						</Row>
						<Row gutter={[16, 0]}>
							<p>Floating Button</p>
							<Form.Item label="Text KH*">
								{getFieldDecorator('text_kh', {
									initialValue: detail.title_kh,
									rules: [{ required: true, message: 'Please input Title!' }],
								})(
									<Input placeholder="" />
								)}
							</Form.Item>
							<Form.Item label="Link to*">
								{getFieldDecorator('link_to', {
									initialValue: detail.title_kh,
									rules: [{ required: true, message: 'Please input Title!' }],
								})(
									<Input placeholder="" />
								)}
							</Form.Item>
						</Row>
						<Form.Item label="Publish Status*">
							{getFieldDecorator('status', {
								initialValue: detail.status,
								rules: [{ required: true, message: 'Please choose status!' }],
							})(
								<Radio.Group>
									<Radio.Button value="1"><i className="fa fa-check" style={{ marginRight: 5 }}></i>Active</Radio.Button>
									<Radio.Button value="0"><i className="fa fa-close" style={{ marginRight: 5 }}></i>Inactive</Radio.Button>
								</Radio.Group>
							)}
						</Form.Item>


						<div
							style={{
								position: 'absolute',
								right: 0,
								bottom: 0,
								width: '100%',
								textAlign: 'left',
								zIndex: 2
							}}
						>
							<div className="text-right" style={{ paddingBottom: 10, paddingRight: 16, paddingLeft: 16, paddingTop: 16, background: 'rgba(255, 255, 255, 0.75)', }}>
								<Text className="">Please complete all required field*</Text>
							</div>
							<Row style={{
								width: '100%',
								background: '#fff',
								padding: '10px 16px',
								borderTop: '1px solid #e9e9e9',
							}}>
								<Col span={12}>
									<Button onClick={this.onClose} style={{ background: '#EDEDED' }}>
										Close
                </Button>
								</Col>
								<Col span={12} className="text-right">
									<Button
										disabled={deleteButton ? false : true}
										style={{
											marginRight: 8
										}}
										onClick={this.showDeleteModal}
									>
										<i className="fa fa-close" style={{ marginRight: 5 }}></i>
										Delete
                </Button>
									<Button disabled={saveButton ? false : true} htmlType="submit" type="primary">
										Save
                </Button>
								</Col>
							</Row>
						</div>
					</Form>
				</Drawer>
				<Row>
					<Col span={24}>
						<Title level={1}>Article</Title>
					</Col>
				</Row>
				<div className="paper-panel">
					<Row>
						<Col span={18}>
							<Search
								placeholder="Title"
								onChange={({ target: { value } }) => this.handleSearchChange(value)}
								style={{ width: 240, marginRight: 8 }}
							/>
							<Select defaultValue="" value={searchCategory} style={{ width: 150, marginRight: 8 }} onChange={this.handleCategoryChange}>
								<Select.Option value="">All Category</Select.Option>
								{
									category.map(value => <Select.Option key={value.id} value={value.id}>{value.name_en}</Select.Option>)
								}
							</Select>
							<Select defaultValue="" value={searchGroup} style={{ width: 150, marginRight: 8 }} onChange={this.handleGroupChange}>
								<Select.Option value="">All Groups</Select.Option>
								{
									group.map(value => <Select.Option key={value.id} value={value.id}>{value.name_en}</Select.Option>)
								}
							</Select>
							<Select defaultValue='' value={searchTopic} style={{ width: 150 }} onChange={this.handleTopicChange}>
								<Select.Option value="">All Topic</Select.Option>
								{
									topic.map(value => <Select.Option key={value.id} value={value.id}>{value.name_en}</Select.Option>)
								}
							</Select>
						</Col>
						<Col span={6} className="text-right">
							<Button type="primary" style={{ minWidth: 140 }} onClick={() => this.showDrawer(-1)}><Icon type="plus" />Create</Button>
						</Col>
					</Row>
					<Table
						columns={this.columns}
						dataSource={this.state.data}
						pagination={this.state.pagination}
						loading={this.state.loading}
						onChange={this.handleTableChange}
					/>
				</div>
				<Modal
					visible={deleteModalVisible}
					width={400}
					closable={false}
					footer={[
						<Button key="submit" loading={deleteLoading} onClick={this.handleDeleteModalOk}>
							Yes, Delete
					</Button>,
						<Button key="back" type="primary" onClick={this.handleDeleteModalCancel} style={{ minWidth: 120 }}>
							No
					</Button>
					]}
				>
					<div className="text-center">
						<p>Do you want to delete this item?</p>
					</div>
				</Modal>
			</div>
		);
	}
}

export default Form.create({ name: 'article_form' })(App);
