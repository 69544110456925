import React from "react";
import { Form, Typography, message, Button, Row, Col, Input } from 'antd';
import "antd/dist/antd.css";
import "./promotion.css";
import reqwest from 'reqwest';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const { Text, Title } = Typography;

function validateImage(file) {
	const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
	if (!isJpgOrPng) {
		message.error('You can only upload JPG/PNG file!');
	}
	const isLt2M = file.size / 1024 / 1024 < 2;
	if (!isLt2M) {
		message.error('Image must smaller than 2MB!');
	}
	return isJpgOrPng && isLt2M;
}

function uploadImage(file, cb) {
	let reader = new FileReader();
	reader.readAsDataURL(file);

	reader.onload = function () {
		var b64 = reader.result;
		reqwest({
			url: process.env.REACT_APP_API + '/image/save',
			method: 'post',
			data: {
				image : b64
			},
			type: 'json',
		}).then((data) => {
			cb(data.src);
		});
	};

	reader.onerror = function (error) {
		    ;
	};

	return false;
};

var Size = ReactQuill.Quill.import('attributors/style/size');
Size.whitelist = ['13px', '14px', '16px', '18px', '20px', '32px', '72px'];
ReactQuill.Quill.register(Size, true);

class App extends React.Component {
  state = {
    detail: { title: "", description: "", merchant_description: "" },
    loading: false,
  };

  componentDidMount() {
    document.title = "Promotion"

    this.fetch();
  }

	modules = {
    toolbar: {
		container : [
			['bold', 'italic', 'underline', 'strike'],        // toggled buttons
			['link', 'image'],

			[{ 'list': 'ordered'}, { 'list': 'bullet' }],
			[{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
			[{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
			// [{ 'direction': 'rtl' }],                         // text direction

			// [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
			[{ 'size': Size.whitelist }],

			[{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
			// [{ 'font': Font.whitelist }],
			[{ 'align': [] }],

			['clean']                                         // remove formatting button
		],
		handlers: {
			image: this.imageHandler
		}
	}
  };

	imageHandler() {
        const input = document.createElement('input');

        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'image/*');
        input.click();

        input.onchange = async () => {

            const file = input.files[0];
			if (!validateImage(file)) {
				return false;
			}
            const formData = new FormData();

            formData.append('image', file);

            // Save current cursor state
            const range = this.quill.getSelection(true);

            // Insert temporary loading placeholder image
            // this.quill.insertEmbed(range.index, 'image', `${window.location.origin}/images/loaders/placeholder.gif`);

            // Move cursor to right side of image (easier to continue typing)
            this.quill.setSelection(range.index + 1);

            uploadImage(file, (cb) => {
				this.quill.insertEmbed(range.index, 'image', cb);
			});

            // Remove placeholder image
            // this.quill.deleteText(range.index, 1);

            // Insert uploaded image
            // this.quill.insertEmbed(range.index, 'image', res.body.image);
            // this.quill.insertEmbed(range.index, 'image', res);
        };
    }

	getBase64 = (file, cb) => {
		let reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = function () {
			cb(reader.result)
		};
		reader.onerror = function (error) {
			    ;
		};
	};



  fetch = (params = {}) => {
    this.setState({ loading: true });
    reqwest({
      url: process.env.REACT_APP_API + '/promotion',
      method: 'get',
      data: {
        ...params
      },
      type: 'json',
    }).then(data => {
      this.props.form.setFieldsValue({
        title_en: data.results.title_en,
        title_kh: data.results.title_kh,
        description_en: data.results.description_en,
        description_kh: data.results.description_kh,
        merchant_description_en: data.results.merchant_description_en,
        merchant_description_kh: data.results.merchant_description_kh
      });
	  document.body.style.overflow = null;
      this.setState({
        loading: false,
      });
	  this.props.pageLoading(false);
    });
  };

  onSave = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
		  this.props.pageLoading(true);
        reqwest({
          url: process.env.REACT_APP_API + '/promotion/save',
          method: 'post',
          data: values,
          type: 'json',
        }).then(() => {
          this.setState({ visible: false });
          this.fetch();
        });
      }
    });
  };

  render() {
    const { detail } = this.state;
    const { getFieldDecorator } = this.props.form;


    return (
      <Form onSubmit={this.onSave} layout={'vertical'}>
        <div id="promotion-component">
          <Row>
            <Col span={12}>
              <Title level={1}>Promotion</Title>
            </Col>
            <Col span={12} className="text-right">
              <Button type="primary" htmlType="submit" style={{ minWidth: 140, marginTop: 13 }}>Save</Button>
            </Col>
          </Row>
          <div className="paper-panel">

            <Row>
              <Col xl={18}>
                <Form.Item label="Title EN*">
                  {getFieldDecorator('title_en', {
                    initialValue: '',
                    rules: [{ required: true, message: 'Please input your title!' }],
                  })(
                    <Input placeholder="input placeholder" />
                  )}
                </Form.Item>
                <Form.Item label="Title KH*">
                  {getFieldDecorator('title_kh', {
                    initialValue: '',
                    rules: [{ required: true, message: 'Please input your title!' }],
                  })(
                    <Input placeholder="input placeholder" />
                  )}
                </Form.Item>
                <Form.Item label="Description *">
                  {getFieldDecorator('description_en', {
                    initialValue: detail.description,
                    rules: [{ required: true, message: 'Please input your description!' }],
                  })(
                    <ReactQuill id="promotion-desc-en" modules={this.modules} height={300} />
                  )}
                </Form.Item>

                <Form.Item label="Description KH *">
                  {getFieldDecorator('description_kh', {
                    initialValue: '',
                    rules: [{ required: true, message: 'Please input your description!' }],
                  })(
                    <ReactQuill id="promotion-desc-kh" modules={this.modules} height={300} />
                  )}
                </Form.Item>


                <Text strong={true}>PARTICIPATING MERCHANTS</Text>
                <hr className="divider" />

                <Form.Item label="Description EN *">
                  {getFieldDecorator('merchant_description_en', {
                    initialValue: '',
                    rules: [{ required: true, message: 'Please input your description!' }],
                  })(
                    <ReactQuill id="merchant-desc-en" modules={this.modules} height={300} />
                  )}
                </Form.Item>

                <Form.Item label="Description KH *">
                  {getFieldDecorator('merchant_description_kh', {
                    initialValue: '',
                    rules: [{ required: true, message: 'Please input your description!' }],
                  })(
                    <ReactQuill id="merchant-desc-kh" modules={this.modules} height={300} />
                  )}
                </Form.Item>
              </Col>
            </Row>

          </div>
        </div>
      </Form>
    );
  }
}

export default Form.create({ name: 'promotion_form' })(App);
